import { Box } from '@mui/material';
import { FormProvider } from 'react-hook-form';

import { CheckboxesGroup } from '@/components/form-elements/CheckboxesGroup';
import { FormSection } from '@/components/form-elements/FormSection';
import { RadioButtonsYesNoDescribe } from '@/components/form-elements/RadioButtonsYesNoDescribe';
import { RadioButtonsYesNoEnum } from '@/components/form-elements/RadioButtonsYesNoEnum';
import { SurveyRadioButtonLabels } from '@/components/form-elements/SurveyRadioButtonLabels';
import { SurveyRadioButtons } from '@/components/form-elements/SurveyRadioButtons';
import { useInitialFormValues } from '@/hooks/useInitialFormValues';
import { useLocationProfileForm } from '@/hooks/useLocationProfileForm';
import {
  BicycleIncentiveType,
  CarFinancialFundingType,
  CarSharingPromotionType,
  ManagementInstrumentType,
  PublicTransportFundingType,
  PublicTransportInformationType,
  SafeAndEcoDrivingIncentive,
  UpdateLocationProfileDto,
} from '@/services/api';

export type FormDataGoalsAndIncentives = Pick<
  UpdateLocationProfileDto,
  | 'improvementReachability'
  | 'improvementReachabilityCustomers'
  | 'reduceParkingPressure'
  | 'reduceCostsParking'
  | 'reduceCostsBusinessTrips'
  | 'reduceTrafficJams'
  | 'improvementEmployeeSatisfaction'
  | 'improvementEmployeeProductivity'
  | 'reduceJourneyAccidents'
  | 'improvementHealthPromotion'
  | 'improvementEnvironmentalBalance'
  | 'introductionEmobility'
  | 'expansionBicycle'
  | 'improvementPublicImage'
  | 'reduceJourneyWithHousing'
  | 'increaseEmployeeEmobility'
  | 'improvementQualityOfLiving'
  | 'managementInstrumentType'
  | 'managementInstrumentOther'
  | 'eMobilityOptimizationMeasures'
  | 'eMobilityOptimizationBarriers'
  | 'publicTransportInformationBool'
  | 'publicTransportInformationType'
  | 'publicTransportInformationOther'
  | 'fundingPublicTransportBool'
  | 'fundingPublicTransportType'
  | 'fundingPublicTransportOther'
  | 'carFinancialFundingBool'
  | 'carFinancialFundingType'
  | 'carFinancialFundingOther'
  | 'alternativeDriveFunding'
  | 'safeAndEcoDrivingIncentive'
  | 'bicycleIncentiveBool'
  | 'bicycleIncentiveType'
  | 'bicycleIncentiveOther'
  | 'carSharingPromotionBool'
  | 'carSharingPromotionType'
  | 'carSharingPromotionOther'
  | 'furtherMobilityManagementMeasures'
>;

const fields: { name: keyof FormDataGoalsAndIncentives; label: string }[] = [
  { label: 'Verbesserung der Erreichbarkeit des Betriebs für Beschäftigte', name: 'improvementReachability' },
  {
    label: 'Verbesserung der Erreichbarkeit des Betriebs für Kunden/- innen, Besucher/-innen und Lieferanten',
    name: 'improvementReachabilityCustomers',
  },
  { label: 'Verminderung von Parkdruck', name: 'reduceParkingPressure' },
  { label: 'Reduzierung von Kosten für Parkraum', name: 'reduceCostsParking' },
  { label: 'Reduzierung von Kosten für Geschäftsreisen', name: 'reduceCostsBusinessTrips' },
  { label: 'Beitrag des Betriebs zur Reduzierung von Staus', name: 'reduceTrafficJams' },
  { label: 'Erhöhung der Mitarbeiterzufriedenheit', name: 'improvementEmployeeSatisfaction' },
  { label: 'Erhöhung der Produktivität der Mitarbeiter/-innen', name: 'improvementEmployeeProductivity' },
  { label: 'Verminderung von Wegeunfällen', name: 'reduceJourneyAccidents' },
  { label: 'Stärkung der betrieblichen Gesundheitsförderung', name: 'improvementHealthPromotion' },
  { label: 'Verbesserung der Umweltbilanz des Betriebs', name: 'improvementEnvironmentalBalance' },
  { label: 'Einführung von Elektromobilität in den Fuhrpark', name: 'introductionEmobility' },
  { label: 'Ausweitung des Fahrzeugpools um Zweiräder', name: 'expansionBicycle' },
  { label: 'Verbesserung der Öffentlichkeitsarbeit und Imagesteigerung des Betriebs', name: 'improvementPublicImage' },
  {
    label:
      'Verkürzung der Arbeitswege der Beschäftigten durch arbeitsplatznahe Wohnangebote (Kooperationen mit Wohnungsunternehmen)',
    name: 'reduceJourneyWithHousing',
  },
  { label: 'Erhöhung der E-Pkw-Nutzung durch die Beschäftigten auf dem Arbeitsweg', name: 'increaseEmployeeEmobility' },
  {
    label: 'Beitrag des Betriebs zur Verbesserung der Lebens- und Wohnqualität am Standort',
    name: 'improvementQualityOfLiving',
  },
];

type GoalsAndIncentivesFormProps = { print?: boolean };

export function GoalsAndIncentivesForm({ print }: GoalsAndIncentivesFormProps) {
  const { locationProfile, isLoading, disabled, handleSubmit, methods } = useLocationProfileForm();

  useInitialFormValues<FormDataGoalsAndIncentives>({
    entity: locationProfile,
    useFormReturn: methods,
    fields: [
      'improvementReachability',
      'improvementReachabilityCustomers',
      'reduceParkingPressure',
      'reduceCostsParking',
      'reduceCostsBusinessTrips',
      'reduceTrafficJams',
      'improvementEmployeeSatisfaction',
      'improvementEmployeeProductivity',
      'reduceJourneyAccidents',
      'improvementHealthPromotion',
      'improvementEnvironmentalBalance',
      'introductionEmobility',
      'expansionBicycle',
      'improvementPublicImage',
      'reduceJourneyWithHousing',
      'increaseEmployeeEmobility',
      'improvementQualityOfLiving',
      'managementInstrumentType',
      'managementInstrumentOther',
      'eMobilityOptimizationMeasures',
      'eMobilityOptimizationBarriers',
      'publicTransportInformationBool',
      'publicTransportInformationType',
      'publicTransportInformationOther',
      'fundingPublicTransportBool',
      'fundingPublicTransportType',
      'fundingPublicTransportOther',
      'carFinancialFundingBool',
      'carFinancialFundingType',
      'carFinancialFundingOther',
      'alternativeDriveFunding',
      'safeAndEcoDrivingIncentive',
      'bicycleIncentiveBool',
      'bicycleIncentiveType',
      'bicycleIncentiveOther',
      'carSharingPromotionBool',
      'carSharingPromotionType',
      'carSharingPromotionOther',
      'furtherMobilityManagementMeasures',
    ],
  });

  return (
    <form id="main-form" onSubmit={handleSubmit}>
      <FormProvider {...methods}>
        <FormSection large title="Ziele sowie Förderungen und Anreize" print={print} />

        <FormSection title="Für wie wichtig halten Sie die nachfolgenden Themen für Ihren Betrieb?">
          <SurveyRadioButtonLabels />

          {fields.map(({ label, name }) => (
            <SurveyRadioButtons key={name} label={label} name={name} isLoading={isLoading} disabled={disabled} />
          ))}
        </FormSection>

        <Box sx={{ flexGrow: 1 }}>
          <CheckboxesGroup<ManagementInstrumentType, FormDataGoalsAndIncentives>
            label="Welche der folgenden Maßnahmen und Managementinstrumente sind bei Ihnen im Einsatz oder geplant?"
            name="managementInstrumentType"
            otherName="managementInstrumentOther"
            i18nKey="ManagementInstrumentType"
            options={Object.values(ManagementInstrumentType)}
            isLoading={isLoading}
            disabled={disabled}
          />
        </Box>

        <RadioButtonsYesNoDescribe<FormDataGoalsAndIncentives>
          label="Wurden Maßnahmen zur Optimierung der (E-)Mobilität früher (auch vergeblich)
unternommen?"
          name="eMobilityOptimizationMeasures.bool"
          whichName="eMobilityOptimizationMeasures.description"
          isLoading={isLoading}
          disabled={disabled}
        />

        <RadioButtonsYesNoDescribe<FormDataGoalsAndIncentives>
          label="Sehen Sie Hindernisse bei der Optimierung der (E-)Mobilität am Standort bzw. unter den
        Beschäftigten?"
          name="eMobilityOptimizationBarriers.bool"
          whichName="eMobilityOptimizationBarriers.description"
          isLoading={isLoading}
          disabled={disabled}
        />

        <RadioButtonsYesNoEnum<PublicTransportInformationType, FormDataGoalsAndIncentives>
          label="Stehen Ihren Beschäftigten am Arbeitsplatz Informationen zum öffentlichen Verkehr zur
        Verfügung? (ausgehängte Fahrpläne, Zugang zu Internet-Fahrplan-Angeboten)"
          bool="publicTransportInformationBool"
          type="publicTransportInformationType"
          options={Object.values(PublicTransportInformationType)}
          i18nKey="PublicTransportInformationType"
          otherName="publicTransportInformationOther"
          isLoading={isLoading}
          disabled={disabled}
        />

        <RadioButtonsYesNoEnum<PublicTransportFundingType, FormDataGoalsAndIncentives>
          label="Fördern Sie die Nutzung von öffentlichen Verkehrsmitteln durch Ihre Mitarbeiter/innen?"
          bool="fundingPublicTransportBool"
          type="fundingPublicTransportType"
          options={Object.values(PublicTransportFundingType)}
          i18nKey="PublicTransportFundingType"
          otherName="fundingPublicTransportOther"
          isLoading={isLoading}
          disabled={disabled}
        />

        <RadioButtonsYesNoEnum<CarFinancialFundingType, FormDataGoalsAndIncentives>
          label="Fördern Sie die Nutzung des Pkw durch Ihre Mitarbeiter/innen finanziell?"
          bool="carFinancialFundingBool"
          type="carFinancialFundingType"
          options={Object.values(CarFinancialFundingType)}
          i18nKey="CarFinancialFundingType"
          otherName="carFinancialFundingOther"
          isLoading={isLoading}
          disabled={disabled}
        />

        <RadioButtonsYesNoDescribe<FormDataGoalsAndIncentives>
          label="Fördern Sie speziell die Nutzung von alternativen Antriebsarten?"
          name="alternativeDriveFunding.bool"
          whichName="alternativeDriveFunding.description"
          isLoading={isLoading}
          disabled={disabled}
        />

        <CheckboxesGroup<SafeAndEcoDrivingIncentive, FormDataGoalsAndIncentives>
          label="Bestehen Anreize und Informationen zum energiesparenden und sicheren Autofahren?"
          name="safeAndEcoDrivingIncentive"
          i18nKey="SafeAndEcoDrivingIncentive"
          options={Object.values(SafeAndEcoDrivingIncentive)}
          isLoading={isLoading}
          disabled={disabled}
        />

        <RadioButtonsYesNoEnum<BicycleIncentiveType, FormDataGoalsAndIncentives>
          label="Fördern Sie die Fahrradnutzung durch Ihre Mitarbeiter/innen?"
          bool="bicycleIncentiveBool"
          type="bicycleIncentiveType"
          options={Object.values(BicycleIncentiveType)}
          i18nKey="BicycleIncentiveType"
          otherName="bicycleIncentiveOther"
          isLoading={isLoading}
          disabled={disabled}
        />

        <RadioButtonsYesNoEnum<CarSharingPromotionType, FormDataGoalsAndIncentives>
          label="Verfügt Ihr Betrieb über ein Förderprogramm zum gemeinsamen Autofahren (Fahrgemeinschaft)?"
          bool="carSharingPromotionBool"
          type="carSharingPromotionType"
          options={Object.values(CarSharingPromotionType)}
          i18nKey="CarSharingPromotionType"
          otherName="carSharingPromotionOther"
          isLoading={isLoading}
          disabled={disabled}
        />

        <RadioButtonsYesNoDescribe<FormDataGoalsAndIncentives>
          label="Wurden weitere Maßnahmen des Mobilitätsmanagements umgesetzt?"
          name="furtherMobilityManagementMeasures.bool"
          whichName="furtherMobilityManagementMeasures.description"
          isLoading={isLoading}
          disabled={disabled}
        />
      </FormProvider>
    </form>
  );
}
