import { css, LinearProgress, styled } from '@mui/material';
import { useIsFetching, useIsMutating } from '@tanstack/react-query';

import { useThrottle } from '@/hooks/useThrottle';

const StyledLinearProgress = styled(LinearProgress)(css`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
`);

export function Loading() {
  const isFetching = useIsFetching();
  const isMutating = useIsMutating();
  const throttledIsFetching = useThrottle(isFetching + isMutating, 750);

  return throttledIsFetching !== 0 ? <StyledLinearProgress /> : null;
}
