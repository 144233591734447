import { TextField } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { DeleteDialog } from '@/components/form-elements/DeleteDialog';
import { FormSection } from '@/components/form-elements/FormSection';
import { LastSaved } from '@/components/form-elements/LastSaved';
import { TextFieldLoading } from '@/components/form-elements/TextFieldLoading';
import { SidebarLayout } from '@/components/layouts/SidebarLayout';
import { useAppAbility } from '@/hooks/useAppAbility';
import { useInitialFormValues } from '@/hooks/useInitialFormValues';
import { QueryKeys } from '@/services/QueryKeys';
import { Action, NetworkCategory, NetworksService, User } from '@/services/api';
import { useGlobalStore } from '@/stores/useGlobalStore';

type FormData = { name: string };

export function NetworkPage() {
  const { networkId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const ability = useAppAbility();
  const user = useGlobalStore((state) => state.user);

  const methods = useForm<FormData>();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const [lastSaved, setLastSaved] = useState<Dayjs>();

  const queryClient = useQueryClient();
  const { data: network } = useQuery({
    queryKey: QueryKeys.networks.id(networkId as string),
    queryFn: () => NetworksService.findOne({ id: networkId as string }),
    enabled: !!networkId,
  });

  useInitialFormValues<Omit<FormData, 'networks'>>({
    entity: network,
    useFormReturn: methods,
    fields: ['name'],
  });

  const { mutate } = useMutation({
    mutationFn: (formData: FormData) => NetworksService.update({ id: networkId as string, requestBody: formData }),
    onMutate: () => setLastSaved(undefined),

    onSuccess: () => {
      setLastSaved(dayjs());

      return queryClient.invalidateQueries({ queryKey: QueryKeys.networks.id(networkId as string) });
    },
  });
  const { mutate: mutateRemove } = useMutation({
    mutationFn: () => NetworksService.remove({ id: networkId as string }),

    onSuccess: async () => {
      navigate(
        `/beratung/kategorien?tab=${Object.values(NetworkCategory).indexOf(network?.category as NetworkCategory)}`,
      );
      await queryClient.invalidateQueries({ queryKey: QueryKeys.networks.all });
    },
  });

  const isLoading = !network;
  const entityLabel = t(`NetworkCategory.${network?.category}`);

  return (
    <SidebarLayout buttonLabel="Speichern" actionArea={<LastSaved lastSaved={lastSaved} />}>
      <form
        id="main-form"
        onSubmit={handleSubmit((data) => {
          mutate(data);
        })}
      >
        <FormSection title={entityLabel}>
          <TextFieldLoading isLoading={isLoading}>
            <TextField label="Name" {...register('name', { required: true })} error={!!errors.name} />
          </TextFieldLoading>
        </FormSection>
      </form>

      <DeleteDialog
        onDelete={mutateRemove}
        entityLabel={entityLabel}
        entityName={network?.name as string}
        can={ability.can(Action.DELETE, user as User)}
      >
        Durch das Löschen werden auch etwaige Zuordnungen zu Arbeitgebern entfernt.
      </DeleteDialog>
    </SidebarLayout>
  );
}
