import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { LastSaved } from '@/components/form-elements/LastSaved';
import { SidebarLayout } from '@/components/layouts/SidebarLayout';
import { useAppAbility } from '@/hooks/useAppAbility';
import { useCurrentTab } from '@/hooks/useCurrentTab';
import { BusinessTripsForm } from '@/location/location-profile/BusinessTripsForm';
import { GeneralInformationForm } from '@/location/location-profile/GeneralInformationForm';
import { GoalsAndIncentivesForm } from '@/location/location-profile/GoalsAndIncentivesForm';
import { LocationProfileFooter } from '@/location/location-profile/LocationProfileFooter';
import { LocationProfileStatusForm } from '@/location/location-profile/LocationProfileStatusForm';
import { LocationReflectionForm } from '@/location/location-profile/LocationReflectionForm';
import { ParkingBicycles } from '@/location/location-profile/ParkingBicycles';
import { ParkingCars } from '@/location/location-profile/ParkingCars';
import { StatusAlert } from '@/location/location-profile/StatusAlert';
import { QueryKeys } from '@/services/QueryKeys';
import { Action, Location, LocationProfilesService } from '@/services/api';
import { useGlobalStore } from '@/stores/useGlobalStore';

export const navigationItems = [
  'Allgemeine Angaben',
  'Dienst-/Geschäftsreisen',
  'Parken PKW',
  'Parken Fahrrad',
  'Ziele sowie Förderungen und Anreize',
  'Standort-/Umfeldsituation',
  'Freigabe',
];

export function LocationProfilePage() {
  const { locationId } = useParams();

  const ability = useAppAbility();
  const { currentTab } = useCurrentTab();

  const lastSaved = useGlobalStore((state) => state.lastSaved);

  const { data: locationProfile } = useQuery({
    queryKey: QueryKeys.locationProfiles.id(locationId as string),
    queryFn: () => LocationProfilesService.findOne({ locationId: locationId as string }),
    enabled: !!locationId,
  });

  return (
    <SidebarLayout
      type="tabs"
      navigationItems={
        ability.can(Action.UPDATE, locationProfile?.location as Location, 'profile')
          ? navigationItems
          : navigationItems.slice(0, -1)
      }
      buttonLabel={
        ability.can(Action.UPDATE, locationProfile?.location as Location, 'profile') &&
        currentTab !== navigationItems.length - 1
          ? 'Speichern'
          : undefined
      }
      printLabel="Zur Druckversion"
      actionArea={<LastSaved lastSaved={lastSaved} />}
    >
      {(tab: number) => (
        <>
          {tab !== 6 && <StatusAlert location={locationProfile?.location} />}

          {tab === 0 && <GeneralInformationForm />}
          {tab === 1 && <BusinessTripsForm />}
          {tab === 2 && <ParkingCars />}
          {tab === 3 && <ParkingBicycles />}
          {tab === 4 && <GoalsAndIncentivesForm />}
          {tab === 5 && <LocationReflectionForm />}
          {tab === 6 && ability.can(Action.UPDATE, locationProfile?.location as Location, 'profile') && (
            <LocationProfileStatusForm />
          )}

          <LocationProfileFooter tab={tab} navigationItems={navigationItems} />
        </>
      )}
    </SidebarLayout>
  );
}
