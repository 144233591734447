import { Box, Button, css, styled, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import { ContentLayoutPage } from '@/components/layouts/ContentLayoutPage';

const StyledBox = styled(Box)(css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`);

export function NotFoundPage() {
  return (
    <ContentLayoutPage>
      <StyledBox>
        <Typography variant="h1" sx={{ marginBottom: 5 }}>
          Seite nicht gefunden
        </Typography>
        <Button variant="contained" component={Link} to="/">
          Zur Startseite
        </Button>
      </StyledBox>
    </ContentLayoutPage>
  );
}
