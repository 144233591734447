import { Button, Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { parse } from 'papaparse';
import { ChangeEvent, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { FormSection } from '@/components/form-elements/FormSection';
import { useIsFieldDisabled } from '@/hooks/useIsFieldDisabled';
import { CreateIndicatorRelationDto, IndicatorProcess, IndicatorProcessStatus } from '@/services/api';

type RelationsUploadProps = {
  indicatorProcess: IndicatorProcess;
  file?: File;
  fileRelations?: CreateIndicatorRelationDto[];
  onFileChange: (file: File | undefined) => void;
  onFileRelationsChange: (fileRelations: CreateIndicatorRelationDto[]) => void;
};

const csvFields = [
  'ivmId',
  'description',
  'originAddress',
  'originLongitude',
  'originLatitude',
  'destinationAddress',
  'destinationLongitude',
  'destinationLatitude',
  'consultingCaseLocationId',
];

export function RelationsUpload({
  indicatorProcess,
  file,
  fileRelations,
  onFileChange,
  onFileRelationsChange,
}: RelationsUploadProps) {
  const isFieldDisabled = useIsFieldDisabled(indicatorProcess);
  const { setValue } = useFormContext();
  const fileInput = useRef<HTMLInputElement>(null);
  const [errorMsg, setErrorMsg] = useState('');

  const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const newFile = event.target.files?.[0];
    onFileChange(newFile);
    onFileRelationsChange([]);
    setValue('dateOfRelations', dayjs());
    setErrorMsg('');

    if (newFile) {
      parse<CreateIndicatorRelationDto>(newFile, {
        header: true,
        skipEmptyLines: true,
        transformHeader(header: string, index: number): string {
          return csvFields[index];
        },
        transform(value, field: string) {
          if (field === 'ivmId') {
            return parseInt(value, 10);
          }

          if (field === 'consultingCaseLocationId') {
            return value ? value.slice(-2).padStart(2, '0') : null;
          }

          if (['originLongitude', 'originLatitude', 'destinationLongitude', 'destinationLatitude'].includes(field)) {
            return value ? parseFloat(value) : null;
          }

          return value;
        },
        complete(results) {
          if (results.meta.fields && results.meta.fields?.length > csvFields.length)
            setErrorMsg(`Ungültiges Format (maximal ${csvFields.length} Felder erwartet)`);
          else if (results.meta.fields && results.meta.fields?.length < 6)
            setErrorMsg(`Ungültiges Format (mindestens 6 Felder erwartet)`);
          else onFileRelationsChange(results.data);
        },
        error(error) {
          setErrorMsg(error.message);
        },
      });
    }
  };

  return (
    <FormSection title="CSV Datei mit Relationen importieren">
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <Button
            component="label"
            variant="contained"
            size="large"
            color="secondary"
            disabled={isFieldDisabled('relations')}
          >
            {file || indicatorProcess?.status !== IndicatorProcessStatus.EMPTY ? 'Neue ' : ''}CSV importieren
            <input style={{ display: 'none' }} type="file" accept=".csv" onChange={handleFileSelect} ref={fileInput} />
          </Button>
        </Grid>
        {file && fileRelations && (
          <Grid item>
            <Typography marginBottom={1}>
              {errorMsg ? (
                <span>
                  <strong>Fehler: </strong>
                  {errorMsg}
                </span>
              ) : (
                <span>
                  <strong>{file.name}</strong> {fileRelations.length} Relationen gefunden
                </span>
              )}
            </Typography>
          </Grid>
        )}
      </Grid>
    </FormSection>
  );
}
