import { Alert, AlertTitle, Box, Chip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Can } from '@/components/casl/Can';
import { Action, Location, LocationProfileStatus } from '@/services/api';

type StatusAlertProps = { location?: Location };

export function StatusAlert({ location }: StatusAlertProps) {
  const { t } = useTranslation();

  return !location || location?.profileStatus === LocationProfileStatus.EDITABLE ? null : (
    <Alert severity="info" sx={{ marginBottom: 2 }}>
      <AlertTitle sx={{ fontWeight: 'bold', marginBottom: 0 }}>
        Aktueller Status
        <Chip
          sx={{ fontWeight: 'normal', marginLeft: 1 }}
          label={t(`LocationProfileStatus.${location?.profileStatus}`)}
        />
      </AlertTitle>

      <Can I={Action.UPDATE} this={location} field="profile">
        <Box sx={{ marginTop: 2 }}>
          {location?.profileStatus === LocationProfileStatus.SUBMITTED && (
            <>
              Der Standort-Steckbrief wurde zur Prüfung für die ivm freigegeben. Wenn Sie Änderungen vornehmen wollen,
              können Sie die{' '}
              <Typography component={Link} to="?tab=6" sx={{ color: 'text.primary' }}>
                Freigabe widerrufen
              </Typography>
              .
            </>
          )}
          {location?.profileStatus === LocationProfileStatus.CONFIRMED &&
            'Der Standort-Steckbrief kann im geprüften Zustand nicht mehr bearbeitet werden.'}
        </Box>
      </Can>
    </Alert>
  );
}
