import { Box, Button, css, Grid, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useQueryLocation } from '@/hooks/queries/useQueryLocation';
import { useQueryOrganization } from '@/hooks/queries/useQueryOrganization';
import { navigationItems } from '@/location/LocationProfilePage';
import { PrintFormSection } from '@/location/PrintFormSection';
import { BusinessTripsForm } from '@/location/location-profile/BusinessTripsForm';
import { GeneralInformationForm } from '@/location/location-profile/GeneralInformationForm';
import { GoalsAndIncentivesForm } from '@/location/location-profile/GoalsAndIncentivesForm';
import { LocationReflectionForm } from '@/location/location-profile/LocationReflectionForm';
import { ParkingBicycles } from '@/location/location-profile/ParkingBicycles';
import { ParkingCars } from '@/location/location-profile/ParkingCars';

const StyledTypography = styled(Typography)(css`
  @media print {
    position: absolute;
    right: 0;
  }
`);

export function LocationProfilePrint() {
  const { t } = useTranslation();
  const current = new Date();
  const date = `${current.getDate()}.${current.getMonth() + 1}.${current.getFullYear()}`;

  const { data: location } = useQueryLocation();

  const { data: organization } = useQueryOrganization();

  const coreData = [
    { label: 'Arbeitgeber', value: organization?.name },
    { label: 'Standortname', value: location?.name },
    { label: 'Standort-Nummerierung', value: location?.consultingCaseLocationId },
    { label: 'Anzahl der Beschäftigten', value: location?.size },
    { label: 'Adresse', value: location?.address },
    { label: 'Räumliche Ebene', value: location?.adminLevel?.name },
  ];

  const formSections = [
    <GeneralInformationForm print key="GeneralInformationForm" />,

    <BusinessTripsForm print key="BusinessTripsForm" />,

    <ParkingCars print key="ParkingCars" />,

    <ParkingBicycles print key="ParkingBicycles" />,

    <GoalsAndIncentivesForm print key="GoalsAndIncentivesForm" />,

    <LocationReflectionForm print key="LocationReflectionForm" />,
  ];

  return (
    <Box sx={{ maxWidth: '800px', paddingLeft: '20px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box>
          <Box sx={{ display: 'flex' }}>
            <Typography mr={2}>ivm GmbH - BMM Beratungsplattform - Standortsteckbrief</Typography>
            <StyledTypography>{date}</StyledTypography>
          </Box>
        </Box>
        <Button variant="contained" size="large" onClick={() => window.print()} sx={{ displayPrint: 'none' }}>
          Drucken
        </Button>
      </Box>
      <Grid container spacing={2} alignItems="center">
        {coreData.map((core) => (
          <>
            <Grid item xs={4}>
              {core.label}:
            </Grid>
            <Grid item xs={8}>
              {core.value}
            </Grid>
          </>
        ))}
        {location?.networks?.map((network) => (
          <>
            <Grid item xs={4}>
              {t(`NetworkCategory.${network.category}`)}:
            </Grid>
            <Grid item xs={8}>
              {network.name}
            </Grid>
          </>
        ))}
      </Grid>
      <Box sx={{ pointerEvents: 'none' }}>
        {formSections.map((formSection, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <PrintFormSection key={index} title={navigationItems[index]} formSection={formSection} />
        ))}
      </Box>
    </Box>
  );
}
