import { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { NotFoundPage } from '@/public/NotFoundPage';
import { UserRole } from '@/services/api';
import { useGlobalStore } from '@/stores/useGlobalStore';

type RequireAuthProps = { allowedRoles: UserRole[]; children: ReactNode };

export function Authenticated({ allowedRoles, children }: RequireAuthProps) {
  const user = useGlobalStore((state) => state.user);
  const location = useLocation();

  if (!user) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  if (user && !allowedRoles.includes(user.role)) {
    return <NotFoundPage />;
  }

  return children as JSX.Element;
}
