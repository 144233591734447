import { FormProvider } from 'react-hook-form';

import { CheckboxesGroup } from '@/components/form-elements/CheckboxesGroup';
import { FormSection } from '@/components/form-elements/FormSection';
import { RadioButtonsEnum } from '@/components/form-elements/RadioButtonsEnum';
import { RadioButtonsYesNo } from '@/components/form-elements/RadioButtonsYesNo';
import { TextFieldNumber } from '@/components/form-elements/TextFieldNumber';
import { useInitialFormValues } from '@/hooks/useInitialFormValues';
import { useLocationProfileForm } from '@/hooks/useLocationProfileForm';
import { OrganizationCars } from '@/location/location-profile/fields/OrganizationCars';
import { OrganizationTwoWheelers } from '@/location/location-profile/fields/OrganizationTwoWheelers';
import { BusinessTripOrganizationType, BusinessTripTransportationType, UpdateLocationProfileDto } from '@/services/api';

export type FormDataBusinessTrips = Pick<
  UpdateLocationProfileDto,
  | 'organizationCars'
  | 'carMileageMonth'
  | 'organizationTwoWheelers'
  | 'businessTripOrganization'
  | 'businessTripTransportationType'
  | 'businessTripTransportationOther'
  | 'dispositionSoftwareForPoolCars'
  | 'rulesForChoiceOfTransportModeOnBusinessTrips'
>;

type BusinessTripsFormProps = { print?: boolean };

export function BusinessTripsForm({ print }: BusinessTripsFormProps) {
  const { locationProfile, isLoading, disabled, handleSubmit, methods } = useLocationProfileForm();

  useInitialFormValues<FormDataBusinessTrips>({
    entity: locationProfile,
    useFormReturn: methods,
    fields: [
      'organizationCars',
      'carMileageMonth',
      'organizationTwoWheelers',
      'businessTripOrganization',
      'businessTripTransportationType',
      'businessTripTransportationOther',
      'dispositionSoftwareForPoolCars',
      'rulesForChoiceOfTransportModeOnBusinessTrips',
    ],
  });

  return (
    <form id="main-form" onSubmit={handleSubmit}>
      <FormProvider {...methods}>
        <FormSection large title="Dienst-/Geschäftsreisen" print={print} />

        <OrganizationCars isLoading={isLoading} disabled={disabled} />

        <FormSection title="PKW-Fahrleistung im Dienst-/ und Geschäftsreiseverkehr">
          <TextFieldNumber
            label="PKW-km/Monat"
            isLoading={isLoading}
            name="carMileageMonth"
            rules={{ min: 0 }}
            disabled={disabled}
          />
        </FormSection>

        <OrganizationTwoWheelers isLoading={isLoading} disabled={disabled} />

        <RadioButtonsEnum<BusinessTripOrganizationType, FormDataBusinessTrips>
          label="Wie werden Dienstreisen organisiert?"
          name="businessTripOrganization.type"
          otherName="businessTripOrganization.other"
          i18nKey="BusinessTripOrganizationType"
          options={Object.values(BusinessTripOrganizationType)}
          isLoading={isLoading}
          disabled={disabled}
        />

        <CheckboxesGroup<BusinessTripTransportationType, FormDataBusinessTrips>
          label="Mit welchem Verkehrsmittel werden Dienst-/Geschäftsreisen i.d.R. unternommen?"
          name="businessTripTransportationType"
          otherName="businessTripTransportationOther"
          i18nKey="BusinessTripTransportationType"
          options={Object.values(BusinessTripTransportationType)}
          isLoading={isLoading}
          disabled={disabled}
        />

        <RadioButtonsYesNo<FormDataBusinessTrips>
          label="Wird für den Einsatz der firmeneigenen Poolfahrzeuge eine Dispositionssoftware genutzt?"
          name="dispositionSoftwareForPoolCars"
          isLoading={isLoading}
          disabled={disabled}
        />

        <RadioButtonsYesNo<FormDataBusinessTrips>
          label="Gibt es Regelungen für die Verkehrsmittelwahl bei Dienst-/Geschäftsreisen?"
          name="rulesForChoiceOfTransportModeOnBusinessTrips"
          isLoading={isLoading}
          disabled={disabled}
        />
      </FormProvider>
    </form>
  );
}
