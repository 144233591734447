import dayjs from 'dayjs';

export class DataTransformer {
  static toApi<T extends Record<string, any>>(formData: T, can: boolean | ((field: keyof T) => boolean)) {
    let transformed = {};

    Object.keys(formData).forEach((key: keyof T) => {
      if (can === undefined || (typeof can === 'boolean' && can) || (typeof can === 'function' && can(key))) {
        transformed = { ...transformed, [key]: DataTransformer.toApiPrimitives(formData[key]) };
      }
    });

    return transformed as T;
  }

  private static toApiPrimitives(value: any): any {
    if (Array.isArray(value)) {
      return value.map((item) => DataTransformer.toApiPrimitives(item));
    }

    if (typeof value === 'object' && value !== null) {
      return Object.keys(value).reduce(
        (previousValue, key) => ({ ...previousValue, [key]: DataTransformer.toApiPrimitives(value[key]) }),
        {},
      );
    }

    if (value === '') {
      return null;
    }

    return value;
  }

  static toForm<T extends Record<string, any>>(formData: T) {
    let transformed = {};

    Object.keys(formData).forEach((key: keyof T) => {
      if (
        typeof formData[key] === 'object' &&
        !dayjs.isDayjs(formData[key]) &&
        !Array.isArray(formData[key]) &&
        formData[key] !== null
      ) {
        transformed = { ...transformed, [key]: DataTransformer.toForm(formData[key]) };
      } else if (formData[key] === null) {
        transformed = { ...transformed, [key]: '' };
      } else {
        transformed = { ...transformed, [key]: formData[key] };
      }
    });

    return transformed as T;
  }
}
