import { css, FormControl, RadioGroup, styled } from '@mui/material';
import { ReactNode } from 'react';
import { Controller } from 'react-hook-form';

import { FormHeading } from '@/components/form-elements/FormHeading';
import { FormIndent } from '@/components/form-elements/FormIndent';

const StyledFormControl = styled(FormControl)(
  () => css`
    @media print {
      page-break-inside: avoid;
    }
  `,
) as typeof FormControl;

type RadioGroupProps = {
  label: string;
  name: string;
  isLoading: boolean;
  children: ReactNode;
  setValueAs?: (value: string) => any;
  disabled?: boolean;
};

export function RadioButtonsGroup({ label, name, isLoading, children, setValueAs, disabled }: RadioGroupProps) {
  return isLoading ? null : (
    <StyledFormControl component="fieldset" sx={{ display: 'block', marginBottom: 2 }} disabled={disabled}>
      <FormHeading>{label}</FormHeading>
      <FormIndent>
        <Controller
          name={name}
          defaultValue={null}
          render={({ field }) => (
            <RadioGroup
              row
              aria-label={label}
              {...field}
              {...(setValueAs && { onChange: (event, value) => field.onChange(setValueAs(value)) })}
            >
              {children}
            </RadioGroup>
          )}
        />
      </FormIndent>
    </StyledFormControl>
  );
}
