import { Button, css, styled, TextField, Typography, Link as MuiLink } from '@mui/material';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import CompanyIcon from '@/assets/icons/public/company.svg?react';
import { LoginUserDto } from '@/services/api';

const StyledTextField = styled(TextField)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing(1)};
    background-color: ${theme.palette.common.white};
  `,
);

const StyledCompanyIcon = styled(CompanyIcon)(
  ({ theme }) => css`
    display: block;
    margin-bottom: ${theme.spacing(5)};
  `,
);

type LoginFormProps = {
  error: string | null;
  onSubmit: (data: LoginUserDto) => void;
};

export function LoginForm({ error, onSubmit }: LoginFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginUserDto & { privacy: boolean }>();

  return (
    <form id="login" onSubmit={handleSubmit(onSubmit)}>
      <StyledCompanyIcon />
      <Typography variant="h1" sx={{ marginBottom: 5 }}>
        Anmeldung auf der Beratungsplattform &bdquo;Besser zur Arbeit&ldquo;
      </Typography>

      <StyledTextField
        label="E-Mail"
        type="email"
        fullWidth
        error={!!errors.email || !!error}
        {...register('email', { required: true })}
      />

      <StyledTextField
        label="Passwort"
        type="password"
        fullWidth
        error={!!errors.password || !!error}
        helperText={error}
        {...register('password', { required: true })}
      />

      <Button type="submit" variant="contained" size="large" sx={{ marginTop: 4, marginBottom: 2 }}>
        Login
      </Button>
      <br />
      <Button sx={{ padding: 0, marginBottom: 2 }} component={Link} to="/passwort/vergessen">
        Passwort vergessen
      </Button>
      <br />
      <Typography sx={{ marginBottom: 1 }}>
        Die Beratungsplattform steht den teilnehmenden Arbeitgebern am Beratungsprogramm „Besser zur Arbeit“ zur
        Verfügung. Melden Sie sich gerne bei uns, wenn Sie Interesse an weiteren Informationen über unser
        Beratungsangebot haben.
      </Typography>
      <MuiLink sx={{ padding: 0, textDecoration: 'none', fontWeight: 'bold' }} href="interesse">
        Hier gehts zum Kontaktformular
      </MuiLink>
    </form>
  );
}
