import public1x1 from '@/assets/images/public-1@1x.jpg';
import public1x2 from '@/assets/images/public-1@2x.jpg';
import public1x3 from '@/assets/images/public-1@3x.jpg';
import { Login } from '@/authentication/login/Login';
import { SplitLayout } from '@/components/layouts/SplitLayout';
import { Navigation } from '@/components/navigations/Navigation';

export function PublicPage() {
  return (
    <>
      <Navigation />

      <SplitLayout images={[public1x1, public1x2, public1x3]} childrenSide="right">
        <Login />
      </SplitLayout>
    </>
  );
}
