import { Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CheckboxesGroup } from '@/components/form-elements/CheckboxesGroup';
import { FilterHeader } from '@/components/form-elements/FilterHeader';
import { HoverActions } from '@/components/form-elements/HoverActions';
import { FullHeightLayout } from '@/components/layouts/FullHeightLayout';
import { QueryKeys } from '@/services/QueryKeys';
import { UserRole, UsersService, UserStatus } from '@/services/api';

type FiltersFormData = { search: string; role: UserRole[]; status: UserStatus[] };
const filtersDefaultValues: FiltersFormData = { search: '', role: [], status: [] };

export function UsersPage() {
  const [filters, setFilters] = useState<FiltersFormData>(filtersDefaultValues);
  const { t } = useTranslation();

  const { data = [] } = useQuery({
    queryKey: QueryKeys.users.allFilters(filters),
    queryFn: () => UsersService.findAll(filters),
    placeholderData: keepPreviousData,
  });

  return (
    <FullHeightLayout>
      <Container>
        <FilterHeader<FiltersFormData>
          addButton={{ label: 'Erstellen', to: 'erstellen' }}
          defaultValues={filtersDefaultValues}
          onChange={(formData) => setFilters(formData)}
        >
          <CheckboxesGroup
            label="Rolle"
            name="role"
            i18nKey="UserRole"
            options={Object.values(UserRole)}
            isLoading={false}
          />
          <CheckboxesGroup
            label="Status"
            name="status"
            i18nKey="UserStatus"
            options={Object.values(UserStatus)}
            isLoading={false}
          />
        </FilterHeader>

        <TableContainer>
          <Table aria-label="Benutzer/innen">
            <TableHead>
              <TableRow>
                <TableCell>E-Mail</TableCell>
                <TableCell>Rolle</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map(({ id, email, role, organizations, status }) => (
                <TableRow key={id}>
                  <TableCell component="th" scope="row">
                    <HoverActions
                      title={email}
                      actions={[{ label: 'Bearbeiten', onClick: `/beratung/benutzerinnen/${id}` }]}
                    />
                  </TableCell>
                  <TableCell>
                    {t(`UserRole.${role}`)}
                    {(role === UserRole.IVM_USER || role === UserRole.ORGANIZATION_USER) &&
                      ` (${organizations?.length || 0} Arbeitgeber)`}
                  </TableCell>
                  <TableCell>{t(`UserStatus.${status}`)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </FullHeightLayout>
  );
}
