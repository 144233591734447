import { UserRole } from '@/services/api';
import { useGlobalStore } from '@/stores/useGlobalStore';

export const useDefaultRoute = () => {
  const user = useGlobalStore((state) => state.user);
  let defaultRoute = '/';

  if (user) {
    if ([UserRole.TRAFFICON_ADMIN, UserRole.IVM_ADMIN, UserRole.IVM_USER].includes(user.role)) {
      defaultRoute = '/beratung/arbeitgeber';
    } else if (user.role === UserRole.ORGANIZATION_USER && user.organizations?.length === 1) {
      defaultRoute = `/arbeitgeber/${user.organizations[0].organizationId}`;
    } else {
      defaultRoute = `/arbeitgeber`;
    }
  }

  return { defaultRoute };
};
