import { Button, Divider, Grid } from '@mui/material';

import Plus from '@/assets/icons/plus-circle.svg?react';

type FieldArrayRemoveProps = { onClick: () => void; print?: boolean; disabled?: boolean };

export function FieldArrayRemove({ onClick, print, disabled }: FieldArrayRemoveProps) {
  return (
    <>
      {!disabled && (
        <Grid container justifyContent="flex-end" mb={2}>
          <Grid item>
            <Button
              startIcon={<Plus style={{ transform: 'rotate(45deg)' }} />}
              variant="contained"
              onClick={onClick}
              color="tertiary"
              sx={{ ...(print && { display: 'none' }) }}
            >
              Stellplatzanlage entfernen
            </Button>
          </Grid>
        </Grid>
      )}

      <Divider />
    </>
  );
}
