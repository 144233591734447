import { FormProvider } from 'react-hook-form';

import { CheckboxesGroup } from '@/components/form-elements/CheckboxesGroup';
import { FormSection } from '@/components/form-elements/FormSection';
import { RadioButtonYesNoPartly } from '@/components/form-elements/RadioButtonYesNoPartly';
import { RadioButtonsEnum } from '@/components/form-elements/RadioButtonsEnum';
import { RadioButtonsEnumWithDesc } from '@/components/form-elements/RadioButtonsEnumWithDesc';
import { RadioButtonsYesNo } from '@/components/form-elements/RadioButtonsYesNo';
import { TextFieldNumber } from '@/components/form-elements/TextFieldNumber';
import { TextFieldText } from '@/components/form-elements/TextFieldText';
import { useInitialFormValues } from '@/hooks/useInitialFormValues';
import { useLocationProfileForm } from '@/hooks/useLocationProfileForm';
import {
  ArrivalCount,
  DistanceType,
  DurationType,
  GoodMediumBad,
  LoadFactor,
  LocationAreaType,
  NeighbourhoodBicycleInfrastructure,
  NeighbourhoodUsage,
  PedestrianPathType,
  UpdateLocationProfileDto,
} from '@/services/api';

export type FormDataLocationReflection = Pick<
  UpdateLocationProfileDto,
  | 'roadConnectionQualityAndFeatures'
  | 'roadConnectionHighwayDistance'
  | 'trainStationName'
  | 'trainStationDistance'
  | 'trainStationLineNames'
  | 'trainStationBicycleParking'
  | 'locationAreaType'
  | 'neighbourhoodUsage'
  | 'distanceToCityCenter'
  | 'accessRoadQuality'
  | 'publicSpaceParking'
  | 'publicSpaceParkingLoad'
  | 'neighbourhoodBicycleInfrastructure'
  | 'bicycleInfrastructureNeighbourhood'
  | 'bicycleInfrastructureCityCenter'
  | 'bicycleInfrastructureTrainStation'
  | 'sufficientAccessForPedestrian'
  | 'pedestrianPathType'
  | 'pedestrianPathOther'
  | 'publicTransportTrain'
  | 'publicTransportMetro'
  | 'publicTransportStop'
  | 'publicTransportStopDistance'
  | 'publicTransportCityDuration'
  | 'publicTransportTrainDuration'
  | 'publicTransportArrivalCount'
  | 'publicTransportBusQuality'
>;

type LocationReflectionFormProps = { print?: boolean };

export function LocationReflectionForm({ print }: LocationReflectionFormProps) {
  const { locationProfile, isLoading, disabled, handleSubmit, methods } = useLocationProfileForm();

  useInitialFormValues<FormDataLocationReflection>({
    entity: locationProfile,
    useFormReturn: methods,

    fields: [
      'roadConnectionQualityAndFeatures',
      'roadConnectionHighwayDistance',
      'trainStationName',
      'trainStationDistance',
      'trainStationLineNames',
      'trainStationBicycleParking',
      'locationAreaType',
      'neighbourhoodUsage',
      'distanceToCityCenter',
      'accessRoadQuality',
      'publicSpaceParking',
      'publicSpaceParkingLoad',
      'neighbourhoodBicycleInfrastructure',
      'bicycleInfrastructureNeighbourhood',
      'bicycleInfrastructureCityCenter',
      'bicycleInfrastructureTrainStation',
      'sufficientAccessForPedestrian',
      'pedestrianPathType',
      'pedestrianPathOther',
      'publicTransportTrain',
      'publicTransportMetro',
      'publicTransportStop',
      'publicTransportStopDistance',
      'publicTransportCityDuration',
      'publicTransportTrainDuration',
      'publicTransportArrivalCount',
      'publicTransportBusQuality',
    ],
  });

  return (
    <form id="main-form" onSubmit={handleSubmit}>
      <FormProvider {...methods}>
        <FormSection large title="Standort-/Umfeldsituation" print={print} />

        <FormSection title="Großräumliche Betrachtung">
          <TextFieldText
            label="Ausstattung und Qualität der Straßenanbindung"
            name="roadConnectionQualityAndFeatures"
            disabled={disabled}
          />

          <TextFieldNumber
            label="Nähe des nächsten Autobahnanschlusses"
            endAdornment="km"
            isLoading={isLoading}
            name="roadConnectionHighwayDistance"
            rules={{ min: 0 }}
            disabled={disabled}
          />

          <TextFieldText label="Name des nächstgelegenen Bahnhofs" name="trainStationName" disabled={disabled} />
        </FormSection>

        <RadioButtonsEnum<DistanceType, FormDataLocationReflection>
          label="Entfernung zum nächstgelegenen Bahnhof"
          name="trainStationDistance"
          i18nKey="DistanceType"
          options={Object.values(DistanceType)}
          isLoading={isLoading}
          disabled={disabled}
        />

        <FormSection title="Bahnlinien am nächsten Bahnhof">
          <TextFieldText label="Bahnlinien" name="trainStationLineNames" disabled={disabled} />
        </FormSection>

        <RadioButtonsEnum<GoodMediumBad, FormDataLocationReflection>
          label="Qualität der Fahrradabstellanlagen am Bahnhof"
          name="trainStationBicycleParking"
          i18nKey="GoodMediumBad"
          options={Object.values(GoodMediumBad)}
          isLoading={isLoading}
          disabled={disabled}
        />

        <RadioButtonsEnum<LocationAreaType, FormDataLocationReflection>
          label="Lage des Standortes"
          name="locationAreaType"
          i18nKey="LocationAreaType"
          options={Object.values(LocationAreaType)}
          isLoading={isLoading}
          disabled={disabled}
        />

        <RadioButtonsEnum<NeighbourhoodUsage, FormDataLocationReflection>
          label="Welche Nutzung befindet sich im Umfeld des Betriebes?"
          name="neighbourhoodUsage"
          i18nKey="NeighbourhoodUsage"
          options={Object.values(NeighbourhoodUsage)}
          isLoading={isLoading}
          disabled={disabled}
        />

        <FormSection title="Nähe zum nächsten Stadtzentrum">
          <TextFieldNumber
            label="Entfernung"
            endAdornment="km"
            isLoading={isLoading}
            name="distanceToCityCenter"
            rules={{ min: 0 }}
            disabled={disabled}
          />
        </FormSection>

        <RadioButtonsEnum<GoodMediumBad, FormDataLocationReflection>
          label="Qualität der Zufahrtstraße(n) (Radwege, Verkehrsbehinderung durch Stau, usw.)"
          name="accessRoadQuality"
          i18nKey="GoodMediumBad"
          options={Object.values(GoodMediumBad)}
          isLoading={isLoading}
          disabled={disabled}
        />

        <RadioButtonYesNoPartly<FormDataLocationReflection>
          label="Wird der Parkraum im öffentlichen Raum bewirtschaftet?"
          name="publicSpaceParking"
          isLoading={isLoading}
          disabled={disabled}
        />

        <RadioButtonsEnum<LoadFactor, FormDataLocationReflection>
          label="Parkplatzsituation im öffentlichen Raum"
          name="publicSpaceParkingLoad"
          i18nKey="LoadFactor"
          options={Object.values(LoadFactor)}
          isLoading={isLoading}
          disabled={disabled}
        />

        <RadioButtonsEnum<NeighbourhoodBicycleInfrastructure, FormDataLocationReflection>
          label="Radinfrastruktur im Umfeld des Standortes"
          name="neighbourhoodBicycleInfrastructure"
          i18nKey="NeighbourhoodBicycleInfrastructure"
          options={Object.values(NeighbourhoodBicycleInfrastructure)}
          isLoading={isLoading}
          disabled={disabled}
        />

        <RadioButtonsEnumWithDesc<GoodMediumBad, FormDataLocationReflection>
          label="Qualität der Radinfrastruktur im Umfeld des Standortes (Erste Einschätzung - Informationen über Radroutenplaner)"
          name="bicycleInfrastructureNeighbourhood.quality"
          descName="bicycleInfrastructureNeighbourhood.description"
          i18nKey="GoodMediumBad"
          options={Object.values(GoodMediumBad)}
          isLoading={isLoading}
          disabled={disabled}
        />

        <RadioButtonsEnumWithDesc<GoodMediumBad, FormDataLocationReflection>
          label="Qualität der Radinfrastruktur in das nächstgelegene Zentrum (Erste Einschätzung - Informationen über Radroutenplaner)"
          name="bicycleInfrastructureCityCenter.quality"
          descName="bicycleInfrastructureCityCenter.description"
          i18nKey="GoodMediumBad"
          options={Object.values(GoodMediumBad)}
          isLoading={isLoading}
          disabled={disabled}
        />

        <RadioButtonsEnumWithDesc<GoodMediumBad, FormDataLocationReflection>
          label="Qualität der Radinfrastruktur vom und zum nächsten Bahnhof (Erste Einschätzung - Informationen über Radroutenplaner)"
          name="bicycleInfrastructureTrainStation.quality"
          descName="bicycleInfrastructureTrainStation.description"
          i18nKey="GoodMediumBad"
          options={Object.values(GoodMediumBad)}
          isLoading={isLoading}
          disabled={disabled}
        />

        <RadioButtonsYesNo
          label="Gibt es ausreichend Zugänge für Fußgänger zum Betriebsgelände (besonders im Bereich der Haltestellen von Bus und Bahn)?"
          name="sufficientAccessForPedestrian"
          isLoading={isLoading}
          disabled={disabled}
        />

        <CheckboxesGroup<PedestrianPathType, FormDataLocationReflection>
          label="Zugangsqualität für Fußgänger"
          name="pedestrianPathType"
          otherName="pedestrianPathOther"
          i18nKey="PedestrianPathType"
          options={Object.values(PedestrianPathType)}
          isLoading={isLoading}
          disabled={disabled}
        />

        <RadioButtonYesNoPartly<FormDataLocationReflection>
          label="Gibt es ein S-Bahn-Netz?"
          name="publicTransportTrain"
          isLoading={isLoading}
          disabled={disabled}
        />

        <RadioButtonYesNoPartly<FormDataLocationReflection>
          label="Gibt es ein U-Bahn-Netz/Straßenbahnnetz?"
          name="publicTransportMetro"
          isLoading={isLoading}
          disabled={disabled}
        />

        <FormSection title="Name der nächstgelegenen Haltestelle">
          <TextFieldText label="Haltestelle" name="publicTransportStop" disabled={disabled} />
        </FormSection>

        <RadioButtonsEnum<DistanceType, FormDataLocationReflection>
          label="Entfernung zur nächstgelegenen Haltestelle"
          name="publicTransportStopDistance"
          i18nKey="DistanceType"
          options={Object.values(DistanceType)}
          isLoading={isLoading}
          disabled={disabled}
        />

        <RadioButtonsEnum<DurationType, FormDataLocationReflection>
          label="Fahrzeit (ÖV) vom Betrieb in die Innenstadt des nächsten Mittelzentrums (bzw. Oberzentrums) (sollten mehrere Haltestellen im Umkreis von 500 m am Betrieb liegen, wählen Sie die aus, die am besten bedient wird)"
          name="publicTransportCityDuration"
          i18nKey="DurationType"
          options={Object.values(DurationType)}
          isLoading={isLoading}
          disabled={disabled}
        />

        <RadioButtonsEnum<DurationType, FormDataLocationReflection>
          label="Fahrtzeit (ÖV) vom Betrieb zur nächsten Haltestelle mit Übergang zum Schienenverkehr (sollten mehrere Haltestellen im Umkreis von 500 m am Betrieb liegen, wählen Sie die aus, die am besten bedient wird)"
          name="publicTransportTrainDuration"
          i18nKey="DurationType"
          options={Object.values(DurationType)}
          isLoading={isLoading}
          disabled={disabled}
        />

        <RadioButtonsEnum<ArrivalCount, FormDataLocationReflection>
          label="Anzahl der Ankünfte der öffentlichen Verkehrsmittel an der Haltestelle werktags zwischen 7-9 Uhr (bzw. zu den für die Beschäftigten relevanten Zeiten) (sollten mehrere Haltestellen im Umkreis von 500 m am Betrieb liegen, wählen Sie die aus, die am besten bedient wird)"
          name="publicTransportArrivalCount"
          i18nKey="ArrivalCount"
          options={Object.values(ArrivalCount)}
          isLoading={isLoading}
          disabled={disabled}
        />

        <RadioButtonsEnum<GoodMediumBad, FormDataLocationReflection>
          label="Beurteilung des Busliniennetzes"
          name="publicTransportBusQuality"
          i18nKey="GoodMediumBad"
          options={Object.values(GoodMediumBad)}
          isLoading={isLoading}
          disabled={disabled}
        />
      </FormProvider>
    </form>
  );
}
