import { FormControlLabel, Radio, Box, Typography } from '@mui/material';
import { FieldPath, FieldValues, useFormContext } from 'react-hook-form';

import { CheckboxesGroup } from '@/components/form-elements/CheckboxesGroup';
import { RadioButtonsGroup } from '@/components/form-elements/RadioButtonsGroup';

type RadioButtonsYesNoEnumProps<T extends string, U extends FieldValues> = {
  label: string;
  bool: FieldPath<U>;
  type: FieldPath<U>;
  options: T[];
  i18nKey: string;
  otherName: FieldPath<U>;
  isLoading: boolean;
  disabled?: boolean;
};

export function RadioButtonsYesNoEnum<T extends string, U extends FieldValues>({
  label,
  bool,
  type,
  options,
  i18nKey,
  otherName,
  isLoading,
  disabled,
}: RadioButtonsYesNoEnumProps<T, U>) {
  const { watch } = useFormContext<U>();
  const fieldValue = watch(bool);

  return (
    <RadioButtonsGroup
      label={label}
      name={bool}
      isLoading={isLoading}
      setValueAs={(value: string) => value === 'true'}
      disabled={disabled}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, verticalAlign: 'top' }}>
        <Box sx={{ display: 'flex', flexGrow: 1 }}>
          <FormControlLabel
            sx={{ alignItems: 'start' }}
            value
            control={<Radio />}
            label={<Typography sx={{ paddingTop: '10px' }}>Ja</Typography>}
          />
          {fieldValue && (
            <CheckboxesGroup<T, U>
              label=""
              name={type}
              otherName={otherName}
              i18nKey={i18nKey}
              options={options}
              isLoading={isLoading}
              disabled={disabled}
            />
          )}
        </Box>

        <FormControlLabel value={false} control={<Radio />} label="Nein" />
      </Box>
    </RadioButtonsGroup>
  );
}
