import { Grid, TextField } from '@mui/material';
import { forwardRef } from 'react';
import { useFormContext } from 'react-hook-form';

import { FormSection } from '@/components/form-elements/FormSection';
import { TextFieldLoading } from '@/components/form-elements/TextFieldLoading';
import { useIsFieldDisabled } from '@/hooks/useIsFieldDisabled';
import { Organization, UpdateOrganizationDto } from '@/services/api';

export type FormPartOrganizationContact = Pick<
  UpdateOrganizationDto,
  'contactFirstName' | 'contactLastName' | 'contactMail' | 'contactTel'
>;

type ContactFormProps = {
  organization?: Organization;
  isLoading: boolean;
};

export const ContactForm = forwardRef<HTMLElement, ContactFormProps>(
  ({ organization, isLoading }: ContactFormProps, ref) => {
    const { register } = useFormContext<FormPartOrganizationContact>();
    const isFieldDisabled = useIsFieldDisabled(organization);

    return (
      <FormSection ref={ref} title="Kontakt">
        <Grid container spacing={1} className="form-section-item">
          <Grid item xs={6}>
            <TextFieldLoading isLoading={isLoading}>
              <TextField
                label="Vorname"
                {...register('contactFirstName')}
                disabled={isFieldDisabled('contactFirstName')}
              />
            </TextFieldLoading>
          </Grid>
          <Grid item xs={6}>
            <TextFieldLoading isLoading={isLoading}>
              <TextField
                label="Nachname"
                {...register('contactLastName')}
                disabled={isFieldDisabled('contactLastName')}
              />
            </TextFieldLoading>
          </Grid>
        </Grid>

        <TextFieldLoading isLoading={isLoading}>
          <TextField
            label="E-Mail"
            type="email"
            {...register('contactMail')}
            disabled={isFieldDisabled('contactMail')}
          />
        </TextFieldLoading>

        <TextFieldLoading isLoading={isLoading}>
          <TextField label="Telefon" type="tel" {...register('contactTel')} disabled={isFieldDisabled('contactTel')} />
        </TextFieldLoading>
      </FormSection>
    );
  },
);
