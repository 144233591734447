import { Box, Button, Container, css, Grid, styled } from '@mui/material';
import { ReactNode, RefObject, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { FullHeightLayout } from '@/components/layouts/FullHeightLayout';
import { VerticalNavigation } from '@/components/navigations/VerticalNavigation';
import { useCurrentTab } from '@/hooks/useCurrentTab';

const StyledBox = styled(Box)(
  ({ theme }) => css`
    position: sticky;
    top: 0;
    z-index: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: ${theme.spacing(4)};
  `,
);

type SidebarLayoutProps = {
  buttonLabel?: string;
  printLabel?: string;
  actionArea?: ReactNode;
  navigations?: number;
} & (
  | {
      type?: undefined;
      activeItem?: undefined;
      navigationItems?: undefined;
      navigationElements?: undefined;
      onChange?: undefined;
      children: ReactNode;
    }
  | {
      type: 'scroll';
      activeItem: number;
      navigationItems: string[];
      navigationElements: RefObject<HTMLElement>[];
      onChange?: undefined;
      children: ReactNode;
    }
  | {
      type: 'tabs';
      activeItem?: undefined;
      navigationItems: string[];
      navigationElements?: undefined;
      onChange?: (currentTab: number) => void;
      children: (value: number) => ReactNode;
    }
);

export function SidebarLayout({
  children,
  navigationItems,
  navigationElements,
  onChange,
  activeItem,
  buttonLabel,
  printLabel,
  actionArea,
  navigations,
  type,
}: SidebarLayoutProps) {
  const { currentTab, setCurrentTab } = useCurrentTab();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [currentTab]);

  return (
    <FullHeightLayout navigations={navigations}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <StyledBox>
              {type && navigationItems && (
                <VerticalNavigation
                  active={activeItem || currentTab}
                  items={navigationItems}
                  onChange={
                    {
                      scroll: (value: number) => {
                        const element = navigationElements?.[value].current;
                        if (element) {
                          element.scrollIntoView({ behavior: 'smooth' });
                          element.classList.remove('focus-flash');
                          setTimeout(() => element.classList.add('focus-flash'));
                        }
                      },
                      tabs: (value: number) => {
                        setCurrentTab(value);

                        if (onChange) {
                          onChange(value);
                        }
                      },
                    }[type]
                  }
                />
              )}
              {buttonLabel && (
                <Button type="submit" form="main-form" variant="contained" size="large">
                  {buttonLabel}
                </Button>
              )}
              {actionArea && <Box mb={4}>{actionArea}</Box>}
              {printLabel && (
                <Button
                  sx={{ marginTop: (theme) => `${theme.spacing(2)}` }}
                  variant="contained"
                  color="secondary"
                  component={Link}
                  to="drucken"
                  target="_blank"
                >
                  {printLabel}
                </Button>
              )}
            </StyledBox>
          </Grid>
          <Grid item xs={9}>
            {typeof children === 'function' ? children(currentTab) : children}
          </Grid>
        </Grid>
      </Container>
    </FullHeightLayout>
  );
}
