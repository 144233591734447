import { TextField } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { DeleteDialog } from '@/components/form-elements/DeleteDialog';
import { FormSection } from '@/components/form-elements/FormSection';
import { LastSaved } from '@/components/form-elements/LastSaved';
import { TextFieldLoading } from '@/components/form-elements/TextFieldLoading';
import { TextFieldSelect } from '@/components/form-elements/TextFieldSelect';
import { SidebarLayout } from '@/components/layouts/SidebarLayout';
import { OrganizationList } from '@/consulting/users/OrganizationList';
import { useAppAbility } from '@/hooks/useAppAbility';
import { useInitialFormValues } from '@/hooks/useInitialFormValues';
import { useSetValidationErrors } from '@/hooks/useSetValidationErrors';
import { QueryKeys } from '@/services/QueryKeys';
import { Action, User, UserRole, UsersService, UserStatus } from '@/services/api';

type FormData = Pick<User, 'email' | 'role' | 'status'>;

export function UserPage() {
  const { userId } = useParams();
  const navigate = useNavigate();
  const ability = useAppAbility();

  const methods = useForm<FormData>();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;
  const { setValidationErrors } = useSetValidationErrors(methods.setError);

  const [lastSaved, setLastSaved] = useState<Dayjs>();

  const { data: user, isLoading } = useQuery({
    queryKey: QueryKeys.users.id(userId as string),

    queryFn: () => UsersService.findOne({ id: userId as string }),
  });
  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: ({ email, status }: FormData) =>
      UsersService.update({
        id: userId as string,
        requestBody: { email, ...(user?.status !== UserStatus.INVITED && status !== UserStatus.INVITED && { status }) },
      }),

    onMutate: () => setLastSaved(undefined),

    onSuccess: (updatedUser) => {
      setLastSaved(dayjs());

      queryClient.setQueryData<User>(QueryKeys.users.id(userId as string), { ...user, ...updatedUser });
    },

    onError: setValidationErrors,
  });
  const { mutate: mutateRemove } = useMutation({
    mutationFn: () => UsersService.remove({ id: userId as string }),

    onSuccess: async () => {
      navigate('/beratung/benutzerinnen');
      await queryClient.invalidateQueries({ queryKey: QueryKeys.users.all });
    },
  });

  useInitialFormValues<FormData>({
    entity: user,
    useFormReturn: methods,
    fields: ['email', 'role', 'status'],
  });

  return (
    <SidebarLayout buttonLabel="Speichern" actionArea={<LastSaved lastSaved={lastSaved} />}>
      <FormProvider {...methods}>
        <form
          id="main-form"
          onSubmit={handleSubmit((data) => {
            mutate(data);
          })}
        >
          <FormSection title="Stammdaten">
            <TextFieldLoading isLoading={isLoading}>
              <TextField
                label="E-Mail"
                type="email"
                {...register('email', { required: true })}
                error={!!errors.email}
                helperText={errors?.email?.message}
              />
            </TextFieldLoading>

            <TextFieldSelect
              label="Rolle"
              name="role"
              i18nKey="UserRole"
              options={Object.values(UserRole)}
              isLoading={isLoading}
              disabled
            />

            <TextFieldSelect
              label="Status"
              name="status"
              i18nKey="UserStatus"
              options={[
                UserStatus.ACTIVE,
                UserStatus.INACTIVE,
                ...(user?.status === UserStatus.INVITED ? [UserStatus.INVITED] : []),
              ]}
              isLoading={isLoading}
              disabled={user?.status === UserStatus.INVITED}
            />
          </FormSection>
        </form>
      </FormProvider>

      {(user?.role === UserRole.IVM_USER || user?.role === UserRole.ORGANIZATION_USER) && (
        <OrganizationList organizationUsers={user.organizations || []} />
      )}

      <DeleteDialog
        onDelete={mutateRemove}
        entityLabel="Benutzer/in"
        entityName={user?.email as string}
        can={ability.can(Action.DELETE, user as User)}
      >
        Durch das Löschen des/r Benutzer/in werden auch etwaige Zuordnungen zu Arbeitgebern entfernt. Arbeitgeber und
        Ihre Standorte werden nicht gelöscht.
      </DeleteDialog>
    </SidebarLayout>
  );
}
