import { css, styled } from '@mui/material';

import { transientOptions } from '@/utils/transientOptions';

export const FormHeading = styled(
  'legend',
  transientOptions,
)<{ $large?: boolean; $ml?: boolean; $mb?: boolean; $print?: boolean }>(
  ({ theme, $large = false, $ml = true, $mb = false, $print = false }) => css`
    font-size: ${$large ? 1.5 : 1}rem;
    font-weight: bold;
    line-height: 1.2;
    padding: 0;

    ${$ml &&
    css`
      margin-left: ${theme.spacing(2)};
    `};

    ${$mb &&
    css`
      margin-bottom: ${theme.spacing(2)};
    `};

    ${$print &&
    css`
      display: none;
    `};
  `,
);
