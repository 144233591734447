import { ReactNode, useMemo } from 'react';

import { AbilityContext, getAbilityContextDefault } from '@/setup/casl';

type AbilityProviderProps = { children: ReactNode };

export function AbilityProvider({ children }: AbilityProviderProps) {
  return (
    <AbilityContext.Provider value={useMemo(() => getAbilityContextDefault(), [])}>{children}</AbilityContext.Provider>
  );
}
