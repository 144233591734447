import { Box, FormHelperText, FormLabel, Input, Slider, Stack, useMediaQuery } from '@mui/material';
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';

import { theme } from '@/setup/theme';

type SliderDistanceProps<T extends FieldValues> = {
  name: FieldPath<T>;
  label: string;
  subLabel: string;
  maxValue: number;
  control: Control<T>;
};

const scales = [
  {
    max: 100,
    step: 1,
    marks: [0, 5, 10, 15, 20, 25, 50, 75, 100],
    marksMobile: [0, 25, 50, 75, 100],
  },
  {
    max: 250,
    step: 1,
    marks: [0, 25, 50, 75, 100, 150, 200, 250],
    marksMobile: [0, 50, 100, 150, 250],
  },
  {
    max: 500,
    step: 1,
    marks: [0, 50, 100, 150, 200, 300, 400, 500],
    marksMobile: [0, 125, 250, 375, 500],
  },
  {
    max: 1000,
    step: 1,
    marks: [0, 50, 100, 150, 200, 300, 400, 500, 750, 1000],
    marksMobile: [0, 250, 500, 750, 1000],
  },
];

export function SliderDistance<T extends FieldValues>({
  name,
  label,
  subLabel,
  control,
  maxValue,
}: SliderDistanceProps<T>) {
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const scale = scales.find((value) => value.max === maxValue) || scales[0];

  const valueLabelFormat = (value: number) => {
    if (value === 0) return 'unter 1 km';
    if (value === scale.max) return `mehr als ${scale.max} km`;

    return `${value} km`;
  };

  return (
    <Box>
      <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between">
        <Box>
          <FormLabel sx={{ fontSize: 20, fontWeight: 'bold' }}>{label}</FormLabel>
          <FormHelperText>{subLabel}</FormHelperText>
        </Box>

        <Controller
          name={name}
          control={control}
          render={({ field }) => {
            let startAdornment;
            if (field.value < 1) startAdornment = '<';
            if (field.value >= scale.max) startAdornment = '≥';

            return (
              <Input
                sx={{
                  fontSize: 20,
                  backgroundColor: 'common.white',
                  paddingX: 2,
                  paddingY: 1,
                  marginTop: { xs: 2, sm: 0 },
                  marginX: { xs: 'auto', sm: 'initial' },
                  minWidth: { xs: '50%', sm: 'initial' },
                }}
                {...field}
                value={field.value === 0 ? 1 : field.value}
                onChange={(event) => {
                  const newValue = parseInt(event.currentTarget.value, 10);
                  field.onChange(Math.min(newValue, scale.max));
                }}
                disableUnderline
                size="medium"
                startAdornment={startAdornment}
                endAdornment="km"
                inputProps={{
                  step: scale.step,
                  min: 0,
                  max: scale.max,
                  type: 'number',
                }}
              />
            );
          }}
        />
      </Stack>

      <Box
        sx={{
          paddingTop: 2,
          paddingLeft: 5,
          paddingRight: 5,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Slider
              {...field}
              min={0}
              max={scale.max}
              step={scale.step}
              marks={(matches ? scale.marks : scale.marksMobile).map((value) => {
                if (value === 0) return { value, label: '< 1 km' };
                if (value === scale.max) return { value, label: `≥ ${value} km` };

                return {
                  value,
                  label: `${value}`,
                };
              })}
              valueLabelFormat={valueLabelFormat}
            />
          )}
        />
      </Box>
    </Box>
  );
}
