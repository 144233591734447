import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Complete from '@/assets/icons/status/complete.svg?react';
import Empty from '@/assets/icons/status/empty.svg?react';
import Partial from '@/assets/icons/status/partial.svg?react';
import { LocationProfileStatus } from '@/services/api';

type LocationProfileStatusIconProps = { status: LocationProfileStatus };

export const LocationProfileStatusIcon = ({ status }: LocationProfileStatusIconProps) => {
  const { t } = useTranslation();

  return {
    [LocationProfileStatus.EDITABLE]: (
      <Tooltip title={t(`LocationProfileStatus.${LocationProfileStatus.EDITABLE}`)}>
        <span>
          <Empty />
        </span>
      </Tooltip>
    ),
    [LocationProfileStatus.SUBMITTED]: (
      <Tooltip title={t(`LocationProfileStatus.${LocationProfileStatus.SUBMITTED}`)}>
        <span>
          <Partial />
        </span>
      </Tooltip>
    ),
    [LocationProfileStatus.CONFIRMED]: (
      <Tooltip title={t(`LocationProfileStatus.${LocationProfileStatus.CONFIRMED}`)}>
        <span>
          <Complete />
        </span>
      </Tooltip>
    ),
  }[status];
};
