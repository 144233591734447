import { Box, css, Grid, styled, Typography } from '@mui/material';

import { HoverActions } from '@/components/form-elements/HoverActions';
import { Location } from '@/services/api';

const StyledBox = styled(Box)(
  ({ theme }) => css`
    border: 2px solid ${theme.palette.secondary.main};
    background-color: ${theme.palette.secondary.light};
    height: ${theme.spacing(7)};
    padding-left: ${theme.spacing(2)};
    padding-right: ${theme.spacing(2)};
    display: flex;
    align-items: center;
    max-width: min(50vw, ${theme.spacing(70)});

    & + & {
      margin-top: ${theme.spacing(1)};
    }
  `,
);

const StyledTypography = styled(Typography)(css`
  line-height: 1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
`);

type LeafProps = { location: Location };

export function Leaf({ location }: LeafProps) {
  return (
    <StyledBox>
      <Grid container columnSpacing={2} alignItems="center">
        <Grid item md={6}>
          <HoverActions
            title={location.name}
            actions={[
              { label: 'Bearbeiten', onClick: `/arbeitgeber/${location.organizationId}/standorte/${location.id}` },
            ]}
          />
        </Grid>
        <Grid item md={6}>
          <StyledTypography>{location.address}</StyledTypography>
        </Grid>
      </Grid>
    </StyledBox>
  );
}
