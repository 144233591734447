import { Grid } from '@mui/material';

import { FormSection } from '@/components/form-elements/FormSection';
import { TextFieldNumber } from '@/components/form-elements/TextFieldNumber';
import { AgeDistributionDto } from '@/services/api';

type AgeDistributionProps = {
  isLoading: boolean;
  disabled?: boolean;
};

export function AgeDistribution({ isLoading, disabled }: AgeDistributionProps) {
  const fields: { name: keyof AgeDistributionDto; label: string }[] = [
    { name: 'younger18', label: 'Unter 18 Jahre' },
    { name: 'from18to25', label: '18-25 Jahre' },
    { name: 'from26to35', label: '26-35 Jahre' },
    { name: 'from36to45', label: '36-45 Jahre' },
    { name: 'from46to55', label: '46-55 Jahre' },
    { name: 'older55', label: 'Über 55 Jahre' },
  ];

  return (
    <FormSection title="Altersverteilung der Beschäftigten (Anzahl)">
      <Grid container spacing={1} className="form-section-item">
        {fields.map(({ name, label }) => (
          <Grid item key={name} xs={4}>
            <TextFieldNumber
              label={label}
              isLoading={isLoading}
              name={`ageDistribution.${name}`}
              rules={{ min: 0 }}
              disabled={disabled}
            />
          </Grid>
        ))}
      </Grid>
    </FormSection>
  );
}
