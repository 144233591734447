import { Autocomplete, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';

import { TextFieldLoading } from '@/components/form-elements/TextFieldLoading';
import { AnalyseMonitoringDto } from '@/services/api';

type MonitoringFilterAutocompleteProps<T extends { id: string; name: string }> = {
  name: keyof AnalyseMonitoringDto;
  defaultValue: string | undefined;
  options: Array<T>;
  label: string;
  isLoading: boolean;
};

export function MonitoringFilterAutocomplete<T extends { id: string; name: string }>({
  name,
  defaultValue,
  options,
  label,
  isLoading,
}: MonitoringFilterAutocompleteProps<T>) {
  return (
    <TextFieldLoading isLoading={isLoading}>
      <Controller
        name={name}
        defaultValue={defaultValue}
        render={({ field }) => (
          <Autocomplete
            {...field}
            options={options}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.name}
              </li>
            )}
            getOptionLabel={(option) => {
              if (typeof option === 'string') {
                return options.find(({ id }) => id === option)?.name || '';
              }

              return option.name;
            }}
            isOptionEqualToValue={(option, value) => option.id === value}
            onChange={(event, value) => {
              field.onChange(value?.id);
            }}
            renderInput={(params) => <TextField {...params} label={label} />}
            disabled={defaultValue !== null}
          />
        )}
      />
    </TextFieldLoading>
  );
}
