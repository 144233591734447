import { Input, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled, css } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { FormSection } from '@/components/form-elements/FormSection';
import { CarDriveTypeDto, CarUsageDto, OrganizationCarsDto, UpdateLocationProfileDto } from '@/services/api';
import { setValuesAsOptionalInt } from '@/utils/setValuesAsOptionalInt';

const associations: { name: keyof OrganizationCarsDto; label: string }[] = [
  { name: 'pool', label: 'Poolfahrzeuge' },
  { name: 'department', label: 'Abteilungsfahrzeuge' },
  { name: 'personal', label: 'Personenbezogene Fahrzeuge' },
];
const privateUsages: { name: keyof CarUsageDto; label: string }[] = [
  { name: 'withPrivateUsage', label: 'Ja' },
  { name: 'withoutPrivateUsage', label: 'Nein' },
];
const driveTypes: { name: keyof CarDriveTypeDto; label: string }[] = [
  { name: 'diesel', label: 'Diesel' },
  { name: 'petrol', label: 'Benzin' },
  { name: 'naturalGas', label: 'Gas' },
  { name: 'hybrid', label: 'Hybrid' },
  { name: 'electric', label: 'Elektrisch' },
];

const StyledTableCell = styled(TableCell)(
  ({ theme }) => css`
    color: ${theme.palette.primary.main} !important;
    vertical-align: bottom;
  `,
);

type OrganizationCarsProps = { isLoading: boolean; disabled?: boolean };

export function OrganizationCars({ isLoading, disabled }: OrganizationCarsProps) {
  const { register, formState } = useFormContext<Pick<UpdateLocationProfileDto, 'organizationCars'>>();

  return (
    <FormSection title="Firmeneigene PKW">
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell rowSpan={2}>Art</TableCell>
              <StyledTableCell rowSpan={2}>Privatnutzung</StyledTableCell>
              <TableCell colSpan={5} align="center">
                Antrieb
              </TableCell>
            </TableRow>
            <TableRow>
              {driveTypes.map(({ label }) => (
                <TableCell key={label}>{label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {associations.map(({ name: association, label: associationLabel }, associationIndex) =>
              privateUsages.map(({ name: privateUsage, label }, privateUsageIndex) => (
                <TableRow key={association + privateUsage}>
                  {privateUsageIndex === 0 && (
                    <TableCell sx={{ ...(associationIndex % 2 && { backgroundColor: 'common.white' }) }} rowSpan={2}>
                      {associationLabel}
                    </TableCell>
                  )}
                  <TableCell sx={{ color: 'primary.main' }}>{label}</TableCell>
                  {driveTypes.map(({ name: driveType, label: driveTypeLabel }) => (
                    <TableCell key={driveType}>
                      {isLoading ? null : (
                        <Input
                          inputProps={{
                            min: 0,
                            'aria-label': `${associationLabel} ${
                              { withPrivateUsage: 'mit', withoutPrivateUsage: 'ohne' }[privateUsage]
                            } Privatnutzung ${driveTypeLabel}`,
                            style: {
                              textAlign: 'right',
                            },
                          }}
                          type="number"
                          disabled={disabled}
                          error={!!formState.errors.organizationCars}
                          {...register(`organizationCars.${association}.${privateUsage}.${driveType}`, {
                            setValueAs: setValuesAsOptionalInt,
                            min: 0,
                          })}
                        />
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              )),
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </FormSection>
  );
}
