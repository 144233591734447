import { Button, useTheme } from '@mui/material';
import { Layout } from 'plotly.js';
import { useTranslation } from 'react-i18next';

import { Plot } from '@/monitoring/plots/Plot';
import { CommuteUtils } from '@/monitoring/utils/CommuteUtils';
import { HandleDownload } from '@/services/DownloadHandler';
import { CommuteType, MonitoringAnalysisData } from '@/services/api';

type DistancePlotProps = {
  data: MonitoringAnalysisData[];
  showIcons: boolean;
  includeWayBack: boolean;
  showSubtitle?: boolean;
  layout?: Partial<Layout>;
  disableDownloadButton?: boolean;
  hideBoxPoints?: boolean;
  iconSizeFactor?: number;
};

export function DistancePlot({
  data,
  showIcons,
  includeWayBack,
  showSubtitle,
  layout,
  disableDownloadButton,
  hideBoxPoints,
  iconSizeFactor = 1,
}: DistancePlotProps) {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const plotData = data.filter((obj) => obj.count !== 0 && obj.commuteType !== CommuteType.HOME_OFFICE);
  const filterdData = plotData.map((obj) => ({
    x: obj.data.map((value) => (includeWayBack ? value.distance * 2 : value.distance)),
    type: 'box',
    name: t(`CommuteTypeShort.${obj.commuteType}`),
    marker: { color: CommuteUtils[obj.commuteType].color, size: 2 },
    line: { width: 1 },
    boxpoints: hideBoxPoints ? false : 'all',
  }));

  const header = ['Verkehrsmittel', 'Minimum', 'Maximal', 'Mittelwert', 'Median'];
  const csvData = filterdData.map((param) => {
    const total = param.x.reduce((acc, currentValue) => acc + currentValue, 0);
    const count = param.x.length;
    const average = total !== 0 ? total / count : 0;

    const sortedValues = param.x.slice().sort((a, b) => a - b);
    let median;
    if (count % 2 === 0) {
      median = (sortedValues[count / 2 - 1] + sortedValues[count / 2]) / 2;
    } else {
      median = sortedValues[Math.floor(count / 2)];
    }

    const formatValue = (value: number) => (value === 0 ? '0 km' : `${value.toFixed(1)} km`);

    return {
      name: param.name,
      markerMin: `${Math.min(...param.x)} km`,
      markerMax: `${Math.max(...param.x)} km`,
      average: formatValue(average),
      median: formatValue(median),
    };
  });

  return (
    <>
      {!disableDownloadButton && (
        <Button onClick={() => HandleDownload({ csvData, header, name: 'Streckenlängen' })}>Download CSV</Button>
      )}
      <Plot
        data={filterdData as any}
        layout={{
          title: `${t('MonitoringAnalysisDistancePlotTitle')}${
            showSubtitle ? `<br><sub>${t('MonitoringAnalysisDistancePlotSubtitle')}</sub>` : ''
          }`,
          xaxis: {
            zeroline: false,
          },
          yaxis: { fixedrange: true, showticklabels: !showIcons },
          showlegend: false,
          margin: { b: 40, l: 120 },
          images: showIcons
            ? plotData.map((obj, index) => ({
                source: `data:image/svg+xml;utf8,${encodeURIComponent(CommuteUtils[obj.commuteType].raw.replaceAll('currentColor', theme.palette.primary.main))}`,
                xref: 'paper',
                yref: 'paper',
                y: (index + 1 - 1 / plotData.length) / plotData.length,
                x: -0.02,
                sizex: 0.09 * iconSizeFactor,
                sizey: 0.09 * iconSizeFactor,
                layer: 'above',
                sizing: 'contain',
                xanchor: 'center',
                yanchor: 'top',
              }))
            : [],
          ...layout,
        }}
        config={{
          modeBarButtonsToRemove: ['lasso2d', 'zoom2d', 'pan2d', 'select2d', 'resetScale2d'],
          locale: i18n.language,
        }}
        style={{ width: '100%', height: '100%', minHeight: '600px' }}
      />
    </>
  );
}
