import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide, Typography, useTheme } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { forwardRef, ReactElement, Ref } from 'react';
import { useTranslation } from 'react-i18next';

const Transition = forwardRef(
  (
    props: TransitionProps & {
      children: ReactElement;
    },
    ref: Ref<unknown>,
  ) => <Slide direction="right" ref={ref} {...props} />,
);

type MonitoringDialogProps = {
  open: boolean;
  onClose: () => void;
};

export function MonitoringDialog({ open, onClose }: MonitoringDialogProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { position: 'fixed', left: 0, top: 0, bottom: 0, m: 0, backgroundColor: theme.palette.primary.light },
      }}
      TransitionComponent={Transition}
    >
      <DialogActions>
        <Button onClick={onClose} sx={{ color: 'black', fontSize: 30, fontWeight: 'normal' }}>
          X
        </Button>
      </DialogActions>
      <DialogTitle>{t('MonitoringInfoTitle')}</DialogTitle>
      <DialogContent>
        <Typography sx={{ marginBottom: 2 }}>{t('MonitoringInfoParagraphOne')}</Typography>
        <Typography>{t('MonitoringInfoParagraphTwo')}</Typography>
      </DialogContent>
    </Dialog>
  );
}
