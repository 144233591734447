import { Box, css, styled } from '@mui/material';

import { CommuteUtils } from '@/monitoring/utils/CommuteUtils';
import { CommuteType } from '@/services/api';
import { transientOptions } from '@/utils/transientOptions';

const Root = styled(
  Box,
  transientOptions,
)<{ $checked: boolean }>(
  ({ $checked, theme }) => css`
    border: 4px solid ${$checked ? theme.palette.primary.main : theme.palette.secondary.main};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${theme.spacing(12)};
    width: ${theme.spacing(12)};
    color: ${theme.palette.primary.main};

    svg {
      width: ${theme.spacing(8)};
      height: auto;
    }
  `,
);

type RadioCommuteIconProps = {
  commuteType: CommuteType;
  checked: boolean;
};

export function RadioCommuteIcon({ commuteType, checked }: RadioCommuteIconProps) {
  const { Icon } = CommuteUtils[commuteType];

  return (
    <Root $checked={checked}>
      <Icon />
    </Root>
  );
}
