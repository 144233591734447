import { useMutation, useQueryClient } from '@tanstack/react-query';
import dayjs, { Dayjs } from 'dayjs';
import { useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import useScrollSpy from 'react-use-scrollspy';

import { FormPartOrganizationStatus } from '@/components/form-elements/ConsultingStatusRegister';
import { LastSaved } from '@/components/form-elements/LastSaved';
import { SidebarLayout } from '@/components/layouts/SidebarLayout';
import { useQueryOrganization } from '@/hooks/queries/useQueryOrganization';
import { useAppAbility } from '@/hooks/useAppAbility';
import { useAutoSave } from '@/hooks/useAutoSave';
import { useInitialFormValues } from '@/hooks/useInitialFormValues';
import { useSetValidationErrors } from '@/hooks/useSetValidationErrors';
import { ConsultingStatusForm } from '@/organization/ConsultingStatusForm';
import { ContactForm, FormPartOrganizationContact } from '@/organization/ContactForm';
import { CoreDataForm, FormPartOrganizationCoreData } from '@/organization/CoreDataForm';
import { DataTransformer } from '@/services/DataTransformer';
import { QueryKeys } from '@/services/QueryKeys';
import { Action, Organization, OrganizationsService, UpdateOrganizationDto } from '@/services/api';

type FormData = FormPartOrganizationCoreData & FormPartOrganizationContact & FormPartOrganizationStatus;

export function OrganizationCoreDataPage() {
  const { organizationId } = useParams();
  const ability = useAppAbility();

  const methods = useForm<FormData>();
  const { setValidationErrors } = useSetValidationErrors(methods.setError);
  const [lastSaved, setLastSaved] = useState<Dayjs>();

  const queryClient = useQueryClient();
  const { data: organization } = useQueryOrganization();

  useInitialFormValues<FormData>({
    entity: organization && {
      ...organization,
      consultingCaseId: organization.consultingCaseId || '',
    },
    useFormReturn: methods,
    fields: [
      // CoreData
      'name',
      'participationDate',
      'consultingCaseId',
      'size',
      'sector',
      'fileStorage',
      // Status
      'status',
      // Contact
      'contactFirstName',
      'contactLastName',
      'contactMail',
      'contactTel',
    ],
  });

  const { mutate } = useMutation({
    mutationFn: (requestBody: UpdateOrganizationDto) =>
      OrganizationsService.update({ id: organizationId as string, requestBody }),
    onMutate: () => setLastSaved(undefined),
    onSuccess: () => {
      setLastSaved(dayjs());

      return queryClient.invalidateQueries({ queryKey: QueryKeys.organizations.id(organizationId as string) });
    },

    onError: setValidationErrors,
  });

  const handleSubmit = methods.handleSubmit(
    (data) => {
      const transformedData = DataTransformer.toApi(data, (field) =>
        ability.can(Action.UPDATE, organization as Organization, field),
      );

      mutate(transformedData);
    },
    () => setLastSaved(undefined),
  );
  useAutoSave(handleSubmit, methods.watch);

  const sectionRefs = [useRef<HTMLElement>(null), useRef<HTMLElement>(null), useRef<HTMLElement>(null)];

  const activeItem = useScrollSpy({
    sectionElementRefs: sectionRefs,
    offsetPx: -32,
    activeSectionDefault: 0,
  });

  return (
    <SidebarLayout
      type="scroll"
      activeItem={activeItem || 0}
      navigationItems={['Stammdaten', 'Beratungsprozess Status', 'Kontakt']}
      navigationElements={sectionRefs}
      buttonLabel={ability.can(Action.UPDATE, organization as Organization) ? 'Speichern' : undefined}
      actionArea={<LastSaved lastSaved={lastSaved} />}
    >
      <FormProvider {...methods}>
        <form id="main-form" onSubmit={handleSubmit}>
          <CoreDataForm ref={sectionRefs[0]} organization={organization} isLoading={!organization} />
          <ConsultingStatusForm ref={sectionRefs[1]} organization={organization} />
          <ContactForm ref={sectionRefs[2]} organization={organization} isLoading={!organization} />
        </form>
      </FormProvider>
    </SidebarLayout>
  );
}
