import { Box, TableContainer } from '@mui/material';
import {
  ColumnDef,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Partial from '@/assets/icons/status/partial.svg?react';
import { CheckboxesGroup } from '@/components/form-elements/CheckboxesGroup';
import { FilterButton } from '@/components/form-elements/FilterButton';
import { StatusChip } from '@/components/icons/StatusChip';
import { TableFooter } from '@/components/table/TableFooter';
import { usePagination } from '@/hooks/usePagination';
import { CreateIndicatorRelationDto, IndicatorRelation, IndicatorRelationStatus } from '@/services/api';
import { getFilterCount } from '@/utils/getFilterCount';

type FormData = {
  filterGeocodingStatus: IndicatorRelationStatus[];
  filterIndicatorsStatus: IndicatorRelationStatus[];
};

type IndicatorRelationsTableProps = {
  relations?: (CreateIndicatorRelationDto | IndicatorRelation)[];
};

export function IndicatorRelationsTable({ relations = [] }: IndicatorRelationsTableProps) {
  const { t } = useTranslation();
  const methods = useForm<FormData>({ defaultValues: { filterGeocodingStatus: [], filterIndicatorsStatus: [] } });
  const { watch } = methods;
  const [filterCount, setFilterCount] = useState(0);
  const [filter, setFilter] = useState<FormData>({ filterGeocodingStatus: [], filterIndicatorsStatus: [] });

  const handleSubmit = methods.handleSubmit((formData) => {
    setFilterCount(getFilterCount(formData, { filterGeocodingStatus: [], filterIndicatorsStatus: [] }));
    setFilter(formData);
  });

  useEffect(() => {
    const subscription = watch(() => {
      handleSubmit();
    });

    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);

  const filteredRelations = useMemo(
    () =>
      relations.filter(
        (relation) =>
          filterCount === 0 ||
          ('geocodingStatus' in relation &&
            'indicatorsStatus' in relation &&
            (filter.filterGeocodingStatus.includes(relation.geocodingStatus) ||
              filter.filterIndicatorsStatus.includes(relation.indicatorsStatus))),
      ),
    [filter, filterCount, relations],
  );

  const columnHelper = createColumnHelper<IndicatorRelation>();

  const columns = [
    columnHelper.accessor('ivmId', { header: 'ID', enableColumnFilter: false, size: 50 }),
    columnHelper.accessor('originAddress', { header: 'Start', enableColumnFilter: false }),

    columnHelper.accessor('errors.geocoding.origin', {
      header: 'Koordinaten',
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({
        row: {
          original: { errors, originLongitude, originLatitude },
        },
      }) =>
        errors?.geocoding?.origin ? (
          <StatusChip icon={<Partial />} label={t(`IndicatorError.${errors?.geocoding?.origin}`)} />
        ) : (
          `${originLongitude && originLatitude ? `${originLongitude}, ${originLatitude}` : ''}`
        ),
    }),
    columnHelper.accessor('destinationAddress', { header: 'Ziel', enableColumnFilter: false }),
    columnHelper.accessor('errors.geocoding.destination', {
      header: 'Koordinaten',
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({
        row: {
          original: { errors, destinationLongitude, destinationLatitude },
        },
      }) =>
        errors?.geocoding?.destination ? (
          <StatusChip icon={<Partial />} label={t(`IndicatorError.${errors?.geocoding?.destination}`)} />
        ) : (
          `${destinationLongitude && destinationLatitude ? `${destinationLongitude}, ${destinationLatitude}` : ''}`
        ),
    }),
    columnHelper.accessor('consultingCaseLocationId', {
      header: 'Standort',
      enableColumnFilter: false,
      size: 50,
    }),
  ] as ColumnDef<IndicatorRelation | CreateIndicatorRelationDto>[];

  const { pagination, onPaginationChange } = usePagination();

  const table = useReactTable({
    data: filteredRelations,
    columns,
    state: {
      pagination,
    },
    onPaginationChange,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <>
      <Box mb={2} display="flex">
        <FormProvider {...methods}>
          <FilterButton filterCount={filterCount}>
            <CheckboxesGroup
              label="Geocoding"
              name="filterGeocodingStatus"
              i18nKey="IndicatorRelationStatus"
              options={Object.values(IndicatorRelationStatus)}
              isLoading={false}
            />
            <CheckboxesGroup
              label="Berechnung"
              name="filterIndicatorsStatus"
              i18nKey="IndicatorRelationStatus"
              options={Object.values(IndicatorRelationStatus)}
              isLoading={false}
            />
          </FilterButton>
        </FormProvider>
      </Box>

      <TableContainer>
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} style={{ width: `${header.getSize()}px` }}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                ))}
              </tr>
            ))}
          </tbody>
          <tfoot>
            {table.getFooterGroups().map((footerGroup) => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.footer, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </tfoot>
        </table>
        <TableFooter table={table} subject="Relationen" />
      </TableContainer>
    </>
  );
}
