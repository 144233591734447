import { InputAdornment, TextField } from '@mui/material';
import { Controller, FieldPath, FieldValues } from 'react-hook-form';

import { TextFieldLoading } from '@/components/form-elements/TextFieldLoading';
import { setValuesAsOptionalInt } from '@/utils/setValuesAsOptionalInt';

export type TextFieldNumberProps<U extends FieldValues> = {
  label: string;
  endAdornment?: string;
  isLoading?: boolean;
  name: FieldPath<U>;
  rules?: { min?: number; max?: number; validate?: (value: any) => string | boolean };
  disabled?: boolean;
};

export function TextFieldNumber<U extends FieldValues>({
  label,
  endAdornment,
  isLoading = false,
  name,
  rules,
  disabled,
}: TextFieldNumberProps<U>) {
  return (
    <TextFieldLoading isLoading={isLoading}>
      <Controller<U>
        name={name}
        defaultValue={'' as any}
        rules={rules}
        render={({ field, fieldState: { error } }) => (
          <TextField
            label={label}
            type="number"
            disabled={disabled}
            InputProps={{
              ...(endAdornment && { endAdornment: <InputAdornment position="end">{endAdornment}</InputAdornment> }),
              inputProps: { min: rules?.min, max: rules?.max },
            }}
            error={!!error}
            helperText={error?.message}
            {...field}
            onChange={(event) => field.onChange(setValuesAsOptionalInt(event.currentTarget.value))}
          />
        )}
      />
    </TextFieldLoading>
  );
}
