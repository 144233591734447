import dayjs from 'dayjs';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { GlobalState } from '@/types/GlobalState';

export const useGlobalStore = create<GlobalState>()(
  persist(
    (set) => ({
      lastSaved: undefined,
      monitoringThrottle: {},
      user: undefined,

      actions: {
        setLastSaved: (lastSaved) => set({ lastSaved }),

        addMonitoringThrottle: (id) =>
          set(({ monitoringThrottle }) => ({
            monitoringThrottle: { ...monitoringThrottle, [id]: dayjs().toISOString() },
          })),

        setUser: (user) => set({ user }),
      },
    }),
    {
      name: 'BMM/GLOBAL',

      partialize: ({ actions, lastSaved, ...state }) => state,
    },
  ),
);
