import { OnChangeFn, PaginationState } from '@tanstack/react-table';
import { useMemo, useState } from 'react';

import { useTablePageSizeStore } from '@/stores/useTablePageSizeStore';

export const usePagination = () => {
  const pageSize = useTablePageSizeStore((state) => state.pageSize);
  const { setPageSize } = useTablePageSizeStore((state) => state.actions);
  const [pageIndex, setPageIndex] = useState(0);

  const pagination = useMemo<PaginationState>(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const onPaginationChange: OnChangeFn<PaginationState> = (updaterOrValue) => {
    const newPagination = typeof updaterOrValue === 'function' ? updaterOrValue(pagination) : updaterOrValue;

    setPageIndex(newPagination.pageIndex);
    setPageSize(newPagination.pageSize);
  };

  return { pagination, onPaginationChange };
};
