import { Chip } from '@mui/material';
import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { FormIndent } from '@/components/form-elements/FormIndent';
import { FormSection } from '@/components/form-elements/FormSection';
import { useInitialFormValues } from '@/hooks/useInitialFormValues';
import { IndicatorRelationsTable } from '@/indicator-process/relations-form/IndicatorRelationsTable';
import { RelationsApproval } from '@/indicator-process/relations-form/RelationsApproval';
import { RelationsCalculation } from '@/indicator-process/relations-form/RelationsCalculation';
import { RelationsGeocoding } from '@/indicator-process/relations-form/RelationsGeocoding';
import { RelationsUpload } from '@/indicator-process/relations-form/RelationsUpload';
import { QueryKeys } from '@/services/QueryKeys';
import {
  CreateIndicatorRelationDto,
  IndicatorProcessService,
  IndicatorRelationStatus,
  UpdateIndicatorProcessDto,
} from '@/services/api';

type FormData = { dateOfRelations: Dayjs | null; arrivalSpanFrom: Dayjs | null; arrivalSpanTo: Dayjs | null };

export function RelationsForm() {
  const { indicatorProcessId } = useParams();
  const { t } = useTranslation();
  const [file, setFile] = useState<File>();
  const [fileRelations, setFileRelations] = useState<CreateIndicatorRelationDto[]>();
  const methods = useForm<FormData>();

  const queryClient = useQueryClient();
  const { data: indicatorProcess } = useQuery({
    queryKey: QueryKeys.indicatorProcesses.id(indicatorProcessId!),
    queryFn: () => IndicatorProcessService.findOne({ id: indicatorProcessId! }),
    placeholderData: keepPreviousData,
    enabled: !!indicatorProcessId,
    refetchInterval: ({ state: { data } }) =>
      !fileRelations &&
      !!data?.relations?.some(({ geocodingStatus }) => geocodingStatus === IndicatorRelationStatus.TODO)
        ? 5000
        : false,
  });

  useInitialFormValues<FormData>({
    entity: indicatorProcess && {
      ...indicatorProcess,
      dateOfRelations: dayjs(indicatorProcess.dateOfRelations),
      arrivalSpanFrom: indicatorProcess.arrivalSpanFrom
        ? dayjs(indicatorProcess.arrivalSpanFrom)
        : dayjs().startOf('day').hour(8),
      arrivalSpanTo: indicatorProcess.arrivalSpanTo
        ? dayjs(indicatorProcess.arrivalSpanTo)
        : dayjs().startOf('day').hour(9),
    },
    useFormReturn: methods,
    fields: ['dateOfRelations', 'arrivalSpanFrom', 'arrivalSpanTo'],
  });

  const { mutate } = useMutation({
    mutationFn: (requestBody: UpdateIndicatorProcessDto) =>
      IndicatorProcessService.update({
        id: indicatorProcessId!,
        requestBody,
      }),

    onSuccess: (data) => {
      queryClient.setQueryData(QueryKeys.indicatorProcesses.id(indicatorProcessId!), data);
    },
  });

  return (
    <>
      <FormProvider {...methods}>
        <form>
          {indicatorProcess && (
            <>
              <FormSection title="Aktueller Status">
                <FormIndent>
                  <Chip label={t(`IndicatorProcessStatus.${indicatorProcess.status}`)} size="medium" />
                </FormIndent>
              </FormSection>

              <RelationsUpload
                indicatorProcess={indicatorProcess}
                file={file}
                fileRelations={fileRelations}
                onFileChange={(newFile) => setFile(newFile)}
                onFileRelationsChange={(newFileRelations) => setFileRelations(newFileRelations)}
              />
              <RelationsGeocoding
                indicatorProcess={indicatorProcess}
                file={file}
                fileRelations={fileRelations}
                onFileChange={(newFile) => setFile(newFile)}
                onFileRelationsChange={(newFileRelations) => setFileRelations(newFileRelations)}
                mutate={mutate}
              />

              {!fileRelations && (
                <>
                  <RelationsCalculation indicatorProcess={indicatorProcess} mutate={mutate} />
                  <RelationsApproval indicatorProcess={indicatorProcess} mutate={mutate} />
                </>
              )}
            </>
          )}
        </form>
      </FormProvider>

      {(fileRelations || indicatorProcess?.relations) && (
        <IndicatorRelationsTable relations={fileRelations || indicatorProcess?.relations} />
      )}
    </>
  );
}
