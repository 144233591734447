import Complete from '@/assets/icons/status/complete.svg?react';
import Empty from '@/assets/icons/status/empty.svg?react';
import Partial from '@/assets/icons/status/partial.svg?react';
import { IndicatorProcessStatus } from '@/services/api';

type IndicatorProcessStatusIconProps = { status: IndicatorProcessStatus };

export const IndicatorProcessStatusIcon = ({ status }: IndicatorProcessStatusIconProps) =>
  ({
    [IndicatorProcessStatus.EMPTY]: <Empty />,
    [IndicatorProcessStatus.RELATIONS_UPLOADED]: <Partial />,
    [IndicatorProcessStatus.RELATIONS_GEOCODED]: <Partial />,
    [IndicatorProcessStatus.READY_FOR_CALCULATION]: <Partial />,
    [IndicatorProcessStatus.CALCULATION_DONE]: <Partial />,
    [IndicatorProcessStatus.INDICATORS_APPROVED]: <Complete />,
  })[status];
