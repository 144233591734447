import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ReactNode } from 'react';

type ConfirmDialogProps = {
  title: string;
  children?: ReactNode;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export function ConfirmDialog({ title, children, open, onClose, onConfirm }: ConfirmDialogProps) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      {children && <DialogContent>{children}</DialogContent>}
      <DialogActions>
        <Button onClick={onClose}>Nein</Button>
        <Button onClick={onConfirm}>Ja</Button>
      </DialogActions>
    </Dialog>
  );
}
