import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, useMediaQuery } from '@mui/material';
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { RadioCommuteIcon } from '@/components/icons/RadioCommuteIcon';
import { CommuteType } from '@/services/api';
import { theme } from '@/setup/theme';

type RadioButtonsCommuteProps<T extends FieldValues> = {
  name: FieldPath<T>;
  label: string;
  control: Control<T>;
  commuteTypes: CommuteType[];
};

export function RadioButtonsCommute<T extends FieldValues>({
  name,
  label,
  control,
  commuteTypes,
}: RadioButtonsCommuteProps<T>) {
  const { t } = useTranslation();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  let width = '150px';
  if (!matches) width = '100px';

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend" sx={{ fontSize: 20, fontWeight: 'bold', marginBottom: 2 }} focused={false}>
        {label}
      </FormLabel>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <RadioGroup row sx={{ justifyContent: 'space-between' }} value={value || null} onChange={onChange}>
            {commuteTypes.map((commuteType) => (
              <FormControlLabel
                sx={{
                  width,
                  textAlign: 'center',
                  display: 'inline-flex',
                }}
                key={commuteType}
                value={commuteType}
                labelPlacement="bottom"
                control={
                  <Radio
                    icon={<RadioCommuteIcon commuteType={commuteType} checked={false} />}
                    checkedIcon={<RadioCommuteIcon commuteType={commuteType} checked />}
                  />
                }
                label={t(`CommuteType.${commuteType}`)}
              />
            ))}
          </RadioGroup>
        )}
      />
    </FormControl>
  );
}
