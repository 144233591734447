import { unpackRules } from '@casl/ability/extra';
import { useEffect } from 'react';
import { Route, useLocation } from 'react-router-dom';

import { AcceptInvitationPage } from '@/authentication/accept-invitation/AcceptInvitationPage';
import { ForgotPasswordPage } from '@/authentication/password-reset/ForgotPasswordPage';
import { ResetPasswordPage } from '@/authentication/password-reset/ResetPasswordPage';
import { ProtectedLayout } from '@/components/layouts/ProtectedLayout';
import { ConsultingNavigation } from '@/components/navigations/ConsultingNavigation';
import { Footer } from '@/components/navigations/Footer';
import { LocationNavigation } from '@/components/navigations/LocationNavigation';
import { OrganizationNavigation } from '@/components/navigations/OrganizationNavigation';
import { AdminLevelsTreePage } from '@/consulting/AdminLevelsTreePage';
import { CreateNetworkPage } from '@/consulting/CreateNetworkPage';
import { CreateOrganizationPage } from '@/consulting/CreateOrganizationPage';
import { LocationsPage } from '@/consulting/LocationsPage';
import { NetworkPage } from '@/consulting/NetworkPage';
import { NetworksTreePage } from '@/consulting/NetworksTreePage';
import { OrganizationsPage } from '@/consulting/OrganizationsPage';
import { CreateOrganizationUserPage as CreateOrganizationUserPageUser } from '@/consulting/users/CreateOrganizationUserPage';
import { CreateUserPage } from '@/consulting/users/CreateUserPage';
import { UserPage } from '@/consulting/users/UserPage';
import { UsersPage } from '@/consulting/users/UsersPage';
import { ChangePasswordPage } from '@/general/ChangePasswordPage';
import { useAppAbility } from '@/hooks/useAppAbility';
import { CreateIndicatorProcessPage } from '@/indicator-process/CreateIndicatorProcessPage';
import { IndependentAnalysisPage } from '@/indicator-process/IndependentAnalysisPage';
import { IndicatorProcessPage } from '@/indicator-process/IndicatorProcessPage';
import { IndicatorProcessesPage } from '@/indicator-process/IndicatorProcessesPage';
import { IndicatorRoutingConfigurationPage } from '@/indicator-process/IndicatorRoutingConfigurationPage';
import { CreateLocationPage } from '@/location/CreateLocationPage';
import { LocationCoreDataPage } from '@/location/LocationCoreDataPage';
import { LocationProfilePage } from '@/location/LocationProfilePage';
import { LocationProfilePrint } from '@/location/LocationProfilePrint';
import { MonitoringAnalysisPage } from '@/monitoring/MonitoringAnalysisPage';
import { MonitoringConfigurationPage } from '@/monitoring/MonitoringConfigurationPage';
import { MonitoringDailyPage } from '@/monitoring/MonitoringDailyPage';
import { MonitoringPage } from '@/monitoring/MonitoringPage';
import { MonitoringThemeProvider } from '@/monitoring/MonitoringThemeProvider';
import { ShareAnalysisPage } from '@/monitoring/ShareAnalysisPage';
import { AnalysisPage } from '@/organization/AnalysisPage';
import { OrganizationCoreDataPage } from '@/organization/OrganizationCoreDataPage';
import { OverviewPage } from '@/organization/OverviewPage';
import { CreateOrganizationUserPage as CreateOrganizationUserPageOrganization } from '@/organization-users/CreateOrganizationUserPage';
import { OrganizationUsersPage } from '@/organization-users/OrganizationUsersPage';
import { ContactPage } from '@/public/ContactPage';
import { HelpPage } from '@/public/HelpPage';
import { ImprintPage } from '@/public/ImprintPage';
import { InterestPage } from '@/public/InterestPage';
import { NotFoundPage } from '@/public/NotFoundPage';
import { PrivacyPage } from '@/public/PrivacyPage';
import { PublicPage } from '@/public/PublicPage';
import { AuthenticationService, CaslService, UserRole } from '@/services/api';
import { SentryRoutes } from '@/setup/sentry';
import { useGlobalStore } from '@/stores/useGlobalStore';

export function App() {
  const { setUser } = useGlobalStore((state) => state.actions);
  const ability = useAppAbility();
  const { pathname } = useLocation();

  useEffect(() => {
    (async () => {
      try {
        setUser(await AuthenticationService.getProfile());
        ability.update(unpackRules((await CaslService.getRules()).rules as any));
      } catch {
        setUser(undefined);
        ability.update([]);
      }
    })();
  }, [ability, setUser]);

  return (
    <>
      <SentryRoutes>
        <Route path="/" element={<PublicPage />} />
        <Route path="/interesse" element={<InterestPage />} />
        <Route path="/kontakt" element={<ContactPage />} />
        <Route path="/help" element={<HelpPage />} />
        <Route path="/datenschutz" element={<PrivacyPage />} />
        <Route path="/impressum" element={<ImprintPage />} />
        <Route path="/einladung/:userId" element={<AcceptInvitationPage />} />

        <Route path="passwort">
          <Route path="vergessen" element={<ForgotPasswordPage />} />
          <Route path="zuruecksetzen" element={<ResetPasswordPage />} />
        </Route>

        <Route path="/passwort-aendern" element={<ProtectedLayout allowedRoles={Object.values(UserRole)} />}>
          <Route index element={<ChangePasswordPage />} />
        </Route>

        <Route
          path="beratung"
          element={
            <ProtectedLayout
              allowedRoles={[UserRole.TRAFFICON_ADMIN, UserRole.IVM_ADMIN, UserRole.IVM_USER]}
              navigation={<ConsultingNavigation />}
            />
          }
        >
          <Route path="arbeitgeber" element={<OrganizationsPage />} />
          <Route path="arbeitgeber/erstellen" element={<CreateOrganizationPage />} />

          <Route path="standorte" element={<LocationsPage />} />

          <Route path="benutzerinnen" element={<UsersPage />} />
          <Route path="benutzerinnen/erstellen" element={<CreateUserPage />} />
          <Route path="benutzerinnen/:userId" element={<UserPage />} />
          <Route path="benutzerinnen/:userId/zuordnen" element={<CreateOrganizationUserPageUser />} />

          <Route path="raeumliche-ebenen" element={<AdminLevelsTreePage />} />

          <Route path="kategorien" element={<NetworksTreePage />} />
          <Route path="kategorien/erstellen" element={<CreateNetworkPage />} />
          <Route path="kategorien/:networkId" element={<NetworkPage />} />

          <Route path="wsa-berechnung" element={<IndicatorProcessesPage />} />
          <Route path="wsa-berechnung/erstellen" element={<CreateIndicatorProcessPage />} />
          <Route path="wsa-berechnung/konfigurieren" element={<IndicatorRoutingConfigurationPage />} />
          <Route path="wsa-berechnung/:indicatorProcessId" element={<IndicatorProcessPage />} />

          <Route path="standortunabhaengige-wsa" element={<IndependentAnalysisPage />} />
          <Route path="standortunabhaengige-wsa/:indicatorProcessId" element={<IndependentAnalysisPage />} />
          <Route
            path="standortunabhaengige-wsa/:indicatorProcessId/:indicatorProcessId2"
            element={<IndependentAnalysisPage />}
          />

          <Route path="monitoring-analysis" element={<MonitoringAnalysisPage />} />
        </Route>

        <Route
          path="/arbeitgeber/:organizationId/standorte"
          element={<ProtectedLayout allowedRoles={Object.values(UserRole)} navigation={<LocationNavigation />} />}
        >
          <Route path="erstellen" element={<CreateLocationPage />} />
          <Route path=":locationId" element={<LocationCoreDataPage />} />
          <Route path=":locationId/steckbrief" element={<LocationProfilePage />} />
          <Route path=":locationId/monitoring-einstellung" element={<MonitoringConfigurationPage />} />
        </Route>

        <Route path="/monitoring/:monitoringId" element={<MonitoringThemeProvider />}>
          <Route path="" element={<MonitoringPage />} />
          <Route path="daily" element={<MonitoringDailyPage />} />
        </Route>

        <Route path="/monitoring-analysis/:sharedAnalysisId" element={<ShareAnalysisPage />} />

        <Route
          path="/arbeitgeber/:organizationId/standorte/:locationId/steckbrief/drucken"
          element={<LocationProfilePrint />}
        />

        <Route
          path="/arbeitgeber"
          element={<ProtectedLayout allowedRoles={Object.values(UserRole)} navigation={<OrganizationNavigation />} />}
        >
          <Route index element={<OrganizationsPage />} />
          <Route path=":organizationId" element={<OverviewPage />} />
          <Route path=":organizationId/stammdaten" element={<OrganizationCoreDataPage />} />
          <Route path=":organizationId/benutzerinnen" element={<OrganizationUsersPage />} />
          <Route path=":organizationId/benutzerinnen/zuordnen" element={<CreateOrganizationUserPageOrganization />} />
          <Route path=":organizationId/wohnstandortanalyse/" element={<AnalysisPage />} />
          <Route path=":organizationId/wohnstandortanalyse/:indicatorProcessId" element={<AnalysisPage />} />
          <Route path=":organizationId/monitoring-analysis" element={<MonitoringAnalysisPage />} />
        </Route>

        <Route path="*" element={<NotFoundPage />} />
      </SentryRoutes>

      {!pathname.includes('monitoring') && <Footer />}
    </>
  );
}
