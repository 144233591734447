import dayjs from 'dayjs';

const downloadCSV = (fileName: string, content: string) => {
  const a = document.createElement('a');
  const file = new Blob([`\ufeff${content}`], { type: 'text/csv;charset=UTF-8' });
  a.href = URL.createObjectURL(file);
  a.download = `${fileName}.csv`;
  a.click();
};

interface HandleDownloadParams {
  csvData: { [key: string]: any }[];
  header: string[];
  name: string;
}

export const HandleDownload = ({ csvData, header, name }: HandleDownloadParams) => {
  const fileName = `${name} ${dayjs().format('L')}`;
  const rowStrings = csvData.map((row) =>
    Object.values(row)
      .map((value) => value.toString())
      .join(';'),
  );
  const content = `${header.join(';')}\n${rowStrings.join('\n')}`;
  downloadCSV(fileName, content);
};
