import { Button, Grid, Typography } from '@mui/material';

import Plus from '@/assets/icons/plus-circle.svg?react';
import { FormHeading } from '@/components/form-elements/FormHeading';

type FieldArrayHeaderProps = {
  title: string;
  onClick: () => void;
  length: number;
  print?: boolean;
  disabled?: boolean;
};

export function FieldArrayHeader({ title, onClick, length, print, disabled }: FieldArrayHeaderProps) {
  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center" mb={2}>
        <Grid item>
          <FormHeading $mb>{title}</FormHeading>
        </Grid>
        <Grid item>
          <Button
            sx={{ ...((print || disabled) && { display: 'none' }) }}
            startIcon={<Plus />}
            variant="contained"
            onClick={onClick}
          >
            Stellplatzanlage hinzufügen
          </Button>
        </Grid>
      </Grid>

      {length === 0 && (
        <Typography textAlign="center" variant="h3" marginTop={2} fontSize="1.25rem" sx={{ color: 'text.secondary' }}>
          Keine Anlage angelegt
        </Typography>
      )}
    </>
  );
}
