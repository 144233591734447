import { FormControlLabel, Radio } from '@mui/material';
import { FieldPath, FieldValues } from 'react-hook-form';

import { RadioButtonsGroup } from '@/components/form-elements/RadioButtonsGroup';

type RadioButtonsYesNoProps<U extends FieldValues> = {
  label: string;
  name: FieldPath<U>;
  isLoading: boolean;
  disabled?: boolean;
};

export function RadioButtonsYesNo<U extends FieldValues>({
  label,
  name,
  isLoading,
  disabled,
}: RadioButtonsYesNoProps<U>) {
  return (
    <RadioButtonsGroup
      label={label}
      name={name}
      isLoading={isLoading}
      setValueAs={(value: string) => value === 'true'}
      disabled={disabled}
    >
      {[
        { value: true, label: 'Ja' },
        { value: false, label: 'Nein' },
      ].map(({ value, label: itemLabel }) => (
        <FormControlLabel key={itemLabel} value={value} control={<Radio />} label={itemLabel} />
      ))}
    </RadioButtonsGroup>
  );
}
