import { css } from '@emotion/react';
import { Box, Button, MenuItem, Select, Stack, styled, Typography } from '@mui/material';
import { RowData, Table } from '@tanstack/react-table';

const Root = styled(Box)(
  () => css`
    margin-top: auto;
    position: sticky;
    bottom: 0;
    z-index: 101;
    left: 0;
    right: 0;
  `,
);

const Inner = styled(Box)(
  ({ theme }) => css`
    align-items: center;
    padding: ${theme.spacing(2)};
    padding-bottom: ${theme.spacing(1.75)};

    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};

    ${theme.breakpoints.up('md')} {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }
  `,
);

const StyledButton = styled(Button)(
  () => css`
    line-height: 1;
  `,
);

type TableFooterProps<TData extends RowData> = {
  table: Table<TData>;
  subject: string;
};

export function TableFooter<TData extends RowData>({ table, subject }: TableFooterProps<TData>) {
  return (
    <Root>
      <Inner>
        <Stack direction="row" gap={1} alignItems="center">
          {table.getRowCount() > 0 && (
            <>
              <StyledButton onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()}>
                «
              </StyledButton>
              <StyledButton onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
                ‹
              </StyledButton>

              <Typography>Seite</Typography>

              <Typography whiteSpace="nowrap">
                {`${table.getState().pagination.pageIndex + 1} von ${table.getPageCount()}`}
              </Typography>

              <StyledButton onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
                ›
              </StyledButton>
              <StyledButton
                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                disabled={!table.getCanNextPage()}
              >
                »
              </StyledButton>
            </>
          )}
        </Stack>

        <Stack direction="row" justifyContent="center" alignItems="center" gap={2}>
          <Typography textAlign="center">
            <strong>{table.getRowCount()}</strong> {subject}{' '}
          </Typography>
        </Stack>

        <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={1}>
          {table.getRowCount() > 0 && (
            <>
              <Typography>Zeige</Typography>
              <Select
                size="small"
                value={table.getState().pagination.pageSize}
                onChange={(event) => {
                  if (event.target.value) {
                    table.setPageSize(event.target.value as number);
                  }
                }}
              >
                {[10, 25, 50, 100].map((pageSizeItem) => (
                  <MenuItem key={pageSizeItem} value={pageSizeItem}>
                    {pageSizeItem}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
        </Stack>
      </Inner>
    </Root>
  );
}
