import { TextField } from '@mui/material';
import { FormProvider, useFieldArray } from 'react-hook-form';

import { CheckboxesGroup } from '@/components/form-elements/CheckboxesGroup';
import { FormSection } from '@/components/form-elements/FormSection';
import { RadioButtonYesNoPartly } from '@/components/form-elements/RadioButtonYesNoPartly';
import { RadioButtonsEnum } from '@/components/form-elements/RadioButtonsEnum';
import { RadioButtonsYesNo } from '@/components/form-elements/RadioButtonsYesNo';
import { TextFieldLoading } from '@/components/form-elements/TextFieldLoading';
import { useInitialFormValues } from '@/hooks/useInitialFormValues';
import { useLocationProfileForm } from '@/hooks/useLocationProfileForm';
import { FieldArrayFooter } from '@/location/location-profile/fields/FieldArrayFooter';
import { FieldArrayHeader } from '@/location/location-profile/fields/FieldArrayHeader';
import { FieldArrayRemove } from '@/location/location-profile/fields/FieldArrayRemove';
import { BicycleChangingType, BicycleParkingLocationType, LoadFactor, UpdateLocationProfileDto } from '@/services/api';
import { setValuesAsOptionalInt } from '@/utils/setValuesAsOptionalInt';

export type FormDataParkingBicycles = Pick<UpdateLocationProfileDto, 'parkingBicycles' | 'bicycleChangingType'>;

type ParkingBicyclesProps = { print?: boolean };

export function ParkingBicycles({ print }: ParkingBicyclesProps) {
  const { locationProfile, isLoading, disabled, handleSubmit, methods } = useLocationProfileForm();
  const {
    register,
    formState: { errors },
  } = methods;
  const { fields, append, remove } = useFieldArray<FormDataParkingBicycles>({
    name: 'parkingBicycles',
    control: methods.control,
  });

  useInitialFormValues<FormDataParkingBicycles>({
    entity: locationProfile,
    useFormReturn: methods,
    fields: ['bicycleChangingType', 'parkingBicycles'],
    once: true,
  });

  return (
    <form id="main-form" onSubmit={handleSubmit}>
      <FormProvider {...methods}>
        <FormSection large title="Parken Fahrrad" print={print} />

        <CheckboxesGroup<BicycleChangingType, FormDataParkingBicycles>
          label="Gibt es beim Betrieb Duschen, Umkleidemöglichkeiten und/oder Spinde für Radfahrer/Innen?"
          name="bicycleChangingType"
          i18nKey="BicycleChangingType"
          options={Object.values(BicycleChangingType)}
          isLoading={isLoading}
          disabled={disabled}
        />

        <FieldArrayHeader
          title="Fahrrad Abstellanlagen"
          onClick={() => append({})}
          length={fields.length}
          print={print}
          disabled={disabled}
        />

        {fields.map((field, index) => (
          <FormSection key={field.id} title={`${index + 1}. Fahrrad Abstellanlage`}>
            <TextFieldLoading isLoading={isLoading}>
              <TextField label="Bezeichnung" disabled={disabled} {...register(`parkingBicycles.${index}.name`)} />
            </TextFieldLoading>

            <TextFieldLoading isLoading={isLoading}>
              <TextField
                label="Anzahl der Abstellplätze gesamt"
                type="number"
                inputProps={{ min: 0 }}
                disabled={disabled}
                error={!!errors.parkingBicycles?.[index]?.size}
                {...register(`parkingBicycles.${index}.size`, { min: 0, setValueAs: setValuesAsOptionalInt })}
              />
            </TextFieldLoading>

            <TextFieldLoading isLoading={isLoading}>
              <TextField
                label="Davon Felgenklemmer"
                type="number"
                inputProps={{ min: 0 }}
                disabled={disabled}
                error={!!errors.parkingBicycles?.[index]?.rimSqueezers}
                {...register(`parkingBicycles.${index}.rimSqueezers`, { min: 0, setValueAs: setValuesAsOptionalInt })}
              />
            </TextFieldLoading>

            <TextFieldLoading isLoading={isLoading}>
              <TextField
                label="Davon Bügel"
                type="number"
                inputProps={{ min: 0 }}
                disabled={disabled}
                error={!!errors.parkingBicycles?.[index]?.racks}
                {...register(`parkingBicycles.${index}.racks`, { min: 0, setValueAs: setValuesAsOptionalInt })}
              />
            </TextFieldLoading>

            <RadioButtonsEnum<BicycleParkingLocationType, FormDataParkingBicycles>
              label="Lage"
              name={`parkingBicycles.${index}.location.type`}
              otherName={`parkingBicycles.${index}.location.other`}
              i18nKey="BicycleParkingLocationType"
              options={Object.values(BicycleParkingLocationType)}
              isLoading={isLoading}
              disabled={disabled}
            />

            <RadioButtonYesNoPartly
              label="Eingangsnah"
              name={`parkingBicycles.${index}.closeToEntrance`}
              isLoading={isLoading}
              disabled={disabled}
            />

            <RadioButtonYesNoPartly
              label="Beleuchtet"
              name={`parkingBicycles.${index}.illuminated`}
              isLoading={isLoading}
              disabled={disabled}
            />

            <RadioButtonYesNoPartly
              label="Gut einsehbar"
              name={`parkingBicycles.${index}.goodVisible`}
              isLoading={isLoading}
              disabled={disabled}
            />

            <RadioButtonsEnum<LoadFactor, FormDataParkingBicycles>
              label="Auslastung"
              name={`parkingBicycles.${index}.loadFactor`}
              i18nKey="LoadFactor"
              options={Object.values(LoadFactor)}
              isLoading={isLoading}
              disabled={disabled}
            />

            <RadioButtonsYesNo
              label="Lademöglichkeiten für Pedelecs/ E-Bikes"
              name={`parkingBicycles.${index}.chargingPointAvailable`}
              isLoading={isLoading}
              disabled={disabled}
            />

            <RadioButtonsYesNo
              label="Dürfen Mitarbeiter/-innen Akkus am Arbeitsplatz laden?"
              name={`parkingBicycles.${index}.chargingAtWorkplace`}
              isLoading={isLoading}
              disabled={disabled}
            />

            <FieldArrayRemove onClick={() => remove(index)} print={print} disabled={disabled} />
          </FormSection>
        ))}

        <FieldArrayFooter onClick={() => append({})} length={fields.length} print={print} disabled={disabled} />
      </FormProvider>
    </form>
  );
}
