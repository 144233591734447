import { FieldValues, UseFormSetError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ApiValidationError } from '@/types/ApiValidationError';

export const useSetValidationErrors = <TFieldValues extends FieldValues>(setError: UseFormSetError<TFieldValues>) => {
  const { t } = useTranslation();

  return {
    setValidationErrors: (error: ApiValidationError<TFieldValues>) => {
      error?.body?.message?.forEach(({ property, constraints }) => {
        setError(property, {
          message: Object.keys(constraints)
            .map((constraint) => t(`validationErrors.${constraint}`))
            .join('\n'),
          type: 'backend',
        });
      });
    },
  };
};
