import { Box, ButtonBase, CircularProgress, Grid, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { CommuteBadge } from '@/components/icons/CommuteBadge';
import { CommuteBadgeTotal } from '@/components/icons/CommuteBadgeTotal';
import { MonitoringLinkType, MonitoringService } from '@/services/api';

const delaySeconds = 10;

export function MonitoringDailyPage() {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const { monitoringId } = useParams();
  const navigateBack = () => navigate(`/monitoring/${monitoringId}?language=${i18n.language}`);

  const { data, isLoading } = useQuery({
    queryKey: ['daily'],

    queryFn: () => MonitoringService.daily({ monitoringId: monitoringId as string }),
  });

  const tablet = data?.linkType === MonitoringLinkType.TABLET;

  useEffect(() => {
    if (tablet) {
      const timer = setTimeout(() => {
        navigateBack();
      }, delaySeconds * 1000);

      return () => {
        clearTimeout(timer);
      };
    }

    return () => {};
  });

  return data && data.summary ? (
    <Box sx={{ position: 'relative', padding: { xs: 2, md: 6 } }}>
      {tablet && (
        <ButtonBase sx={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }} onClick={navigateBack} />
      )}

      <Typography
        variant="h2"
        component="h1"
        sx={{
          marginBottom: { xs: 2, md: 6 },
          textAlign: 'center',
        }}
      >
        {t('MonitoringAcknowledgement')}
      </Typography>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Grid container spacing={2} sx={{ textAlign: 'center' }} alignItems="stretch">
          <CommuteBadgeTotal
            count={data.summary.reduce((accumulator, value) => accumulator + value.count, 0)}
            size={12}
          />
          {data.summary.map((value, index) =>
            index < 4 ? (
              <CommuteBadge key={value.commuteType} commuteType={value.commuteType} count={value.count} size={6} />
            ) : null,
          )}
        </Grid>
      )}

      {tablet && (
        <Box sx={{ marginTop: { xs: 2, md: 4 } }}>
          <Typography
            variant="button"
            display="block"
            textAlign="center"
            sx={{ textTransform: 'none', fontWeight: 'bold' }}
          >
            {t('MonitoringTouchToContinue')}
          </Typography>
          <Typography variant="caption" display="block" textAlign="center">
            {t('MonitoringAutoForward', { seconds: 10 })}
            <br />
            {t('MonitoringAutoForwardSecondLine')}
          </Typography>
        </Box>
      )}
    </Box>
  ) : (
    <Typography>{t('MonitoringError')}</Typography>
  );
}
