import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ConsultingStatusChip } from '@/components/form-elements/ConsultingStatusChip';
import { QueryKeys } from '@/services/QueryKeys';
import { ConsultingStatus, Organization, OrganizationsService } from '@/services/api';

type ConsultingStatusMutateProps = {
  organization: Organization;
};

export function ConsultingStatusMutate({ organization }: ConsultingStatusMutateProps) {
  const { id, status } = organization;

  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: (newStatus: ConsultingStatus) =>
      OrganizationsService.update({ id, requestBody: { status: newStatus } }),

    onSuccess: (updatedOrganization) => {
      queryClient.setQueryData<Organization>(
        QueryKeys.organizations.idIncludes(id, { includeLocations: true, includeOrganizationUsers: true }),
        (oldOrganization) => ({ ...oldOrganization, ...updatedOrganization }),
      );
      queryClient.setQueryData<Organization[]>(QueryKeys.organizations.all, (organizations = []) =>
        organizations.map((organizationItem) =>
          organizationItem.id === updatedOrganization.id ? updatedOrganization : organizationItem,
        ),
      );

      return queryClient.invalidateQueries({ queryKey: QueryKeys.organizations.allFilters() });
    },
  });

  return <ConsultingStatusChip organization={organization} value={status} onChange={mutate} />;
}
