import { Box, Button, css, Divider, Stack, styled, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const StyledRoot = styled(Box)(css`
  position: relative;

  *:hover > &,
  .hover-actions-trigger:hover & {
    .hover-actions-title {
      transform: translateY(-10px);
    }

    .hover-actions-stack {
      visibility: visible;
      opacity: 1;
    }
  }
`);

const StyledTypography = styled(Typography)(css`
  line-height: 1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  position: relative;
  transition: transform ease 300ms;
`);

const StyledButton = styled(Button)(css`
  text-transform: none;
  font-size: 10px;
  padding: 0;
  min-width: initial;
`) as typeof Button;

const StyledStack = styled(Stack)(css`
  position: absolute;
  bottom: -10px;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  transition: opacity ease 300ms;
  white-space: nowrap;
`);

export type HoverActionsProps = { title: string; actions: { label: string; onClick: string | (() => void) }[] };

export function HoverActions({ title, actions }: HoverActionsProps) {
  return (
    <StyledRoot>
      <StyledTypography className="hover-actions-title">{title}</StyledTypography>
      <StyledStack
        className="hover-actions-stack"
        spacing={1}
        direction="row"
        divider={
          <Divider
            sx={{
              borderColor: 'primary.main',
              borderRightWidth: 1,
              height: 10,
              alignSelf: 'center',
              top: -1,
              position: 'relative',
            }}
            orientation="vertical"
            flexItem
          />
        }
      >
        {actions.map(({ label, onClick }) => (
          <StyledButton
            key={label}
            variant="text"
            onClick={(event) => {
              event.stopPropagation();

              if (typeof onClick === 'function') {
                onClick();
              }
            }}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...(typeof onClick === 'string' && { component: Link, to: onClick })}
          >
            {label}
          </StyledButton>
        ))}
      </StyledStack>
    </StyledRoot>
  );
}
