import {
  Button,
  Checkbox,
  css,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  styled,
  Switch,
  SxProps,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useQuery } from '@tanstack/react-query';
import { Dayjs } from 'dayjs';
import { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import DownloadIcon from '@/assets/icons/form/download.svg?react';
import { FilterButton } from '@/components/form-elements/FilterButton';
import { MonitoringFilterAutocomplete } from '@/components/form-elements/MonitoringFilterAutocomplete';
import { QueryKeys } from '@/services/QueryKeys';
import {
  AdminLevelsService,
  AdminLevelType,
  AnalyseMonitoringDto,
  LocationsService,
  NetworksService,
  OrganizationsService,
} from '@/services/api';
import { theme } from '@/setup/theme';

const StyledSwitch = styled(Switch)(
  () => css`
    .MuiSwitch-thumb {
      color: ${theme.palette.secondary.main};
    }
    .Mui-checked .MuiSwitch-thumb {
      color: ${theme.palette.primary.main};
    }
  `,
);

const StyledDownloadIcon = styled(DownloadIcon)(css`
  transform: rotate(180deg);
`);

export type AnalyseMonitoringData = Omit<AnalyseMonitoringDto, 'start' | 'end'> & {
  start: Dayjs;
  end: Dayjs;
};

type MonitoringFilterProps = {
  defaultValues?: AnalyseMonitoringData;
  onFilter: (formData: AnalyseMonitoringData) => void;
  showIcons: boolean;
  setShowIcons: (newValue: boolean) => void;
  showSubtitle: boolean;
  setShowSubtitle: (newValue: boolean) => void;
  autoRefresh: boolean;
  setAutoRefresh: (newValue: boolean) => void;
  onDownload?: () => void;
  monitoringDataLength?: number;
  extrapolation: boolean;
  setExtrapolation: (newValue: boolean) => void;
  englishVersion: boolean;
  setEnglishVersion: (newValue: boolean) => void;
};

const infoTextStyle: SxProps = {
  textAlign: 'center',
};

export function MonitoringFilter({
  onFilter,
  defaultValues,
  showIcons,
  setShowIcons,
  showSubtitle,
  setShowSubtitle,
  autoRefresh,
  setAutoRefresh,
  onDownload,
  monitoringDataLength,
  extrapolation,
  setExtrapolation,
  englishVersion,
  setEnglishVersion,
}: MonitoringFilterProps) {
  const methods = useForm<AnalyseMonitoringData>({ defaultValues });
  const [filterCount] = useState(0);

  const { t } = useTranslation();

  const { data: organizations = [] } = useQuery({
    queryKey: QueryKeys.organizations.allFilters({ includeLocations: false }),
    queryFn: () => OrganizationsService.findAll({ includeLocations: false }),
    refetchOnWindowFocus: false,
  });

  const { data: locations = [] } = useQuery({
    queryKey: QueryKeys.locations.all,
    queryFn: () => LocationsService.findAll(),
    refetchOnWindowFocus: false,
  });

  const adminLevelfilter = {
    type: [AdminLevelType.MUNICIPALITY, AdminLevelType.CITY, AdminLevelType.MUNICIPALITY_FREE_AREA],
    depth: 0,
  };
  const { data: adminLevels = [] } = useQuery({
    queryKey: QueryKeys.adminLevels.allFilter(adminLevelfilter),
    queryFn: () => AdminLevelsService.findAll(adminLevelfilter),
    refetchOnWindowFocus: false,
  });

  const { data: networks = [] } = useQuery({
    queryKey: QueryKeys.networks.all,
    queryFn: () => NetworksService.findAll(),
    refetchOnWindowFocus: false,
  });

  const start = methods.watch('start');
  const end = methods.watch('end');

  return (
    <FormProvider {...methods}>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <MonitoringFilterAutocomplete
            name="organizationId"
            options={organizations}
            label="Arbeitgeber"
            isLoading={!organizations}
            defaultValue={defaultValues?.organizationId}
          />
        </Grid>
        <Grid item xs={4}>
          <MonitoringFilterAutocomplete
            name="locationId"
            options={
              defaultValues?.organizationId !== null
                ? locations.filter((a) => a.organizationId === defaultValues?.organizationId)
                : locations
            }
            label="Standort"
            isLoading={!locations}
            defaultValue={defaultValues?.locationId}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="start"
            defaultValue={defaultValues?.start}
            render={({ field: { ref, ...rest } }) => (
              <DatePicker label="Von" maxDate={end} format="DD.MM.YYYY" inputRef={ref} {...rest} />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <MonitoringFilterAutocomplete
            name="adminLevelId"
            options={adminLevels}
            label="Räumliche Ebene"
            isLoading={!adminLevels}
            defaultValue={defaultValues?.adminLevelId}
          />
        </Grid>
        <Grid item xs={4}>
          <MonitoringFilterAutocomplete
            name="networkId"
            options={networks}
            label="Kategorie"
            isLoading={!networks}
            defaultValue={defaultValues?.networkId}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="end"
            defaultValue={defaultValues?.end}
            render={({ field: { ref, ...rest } }) => (
              <DatePicker label="Bis" minDate={start} format="DD.MM.YYYY" inputRef={ref} {...rest} />
            )}
          />
        </Grid>
        <Grid item xs={12 / 5} sx={{ boxSizing: 'border-box' }}>
          <Button
            color="primary"
            size="large"
            variant="contained"
            onClick={() => onFilter(methods.getValues())}
            sx={{ boxSizing: 'border-box', width: '100%', height: '64px' }}
          >
            {t('MonitoringAnalysisFilter')}
          </Button>
        </Grid>
        <Grid item xs={12 / 5} sx={{ boxSizing: 'border-box' }}>
          <Button
            color="secondary"
            size="large"
            variant="contained"
            onClick={() => {
              methods.reset();
              onFilter(methods.getValues());
            }}
            sx={{ boxSizing: 'border-box', width: '100%', height: '64px' }}
          >
            {t('MonitoringAnalysisReset')}
          </Button>
        </Grid>

        <Grid
          item
          xs={12 / 5}
          sx={{
            boxSizing: 'border-box',
            width: '100%',
            paddingLeft: `unset`,
          }}
        >
          <Stack
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '64px',
            }}
          >
            <Typography sx={{ ...infoTextStyle, lineHeight: 1 }}>{t('MonitoringAnalysisSampleSize')}</Typography>
            <Typography sx={{ ...infoTextStyle, fontSize: 26, fontWeight: 'bold', lineHeight: 1 }}>
              {monitoringDataLength}
            </Typography>
          </Stack>
        </Grid>

        <Grid item xs={12 / 5} sx={{ boxSizing: 'border-box' }}>
          <Stack
            sx={{
              background: `${theme.palette.secondary.main}`,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '64px',
              '& > div': {
                width: '100%',
              },
              '& > div > button': {
                width: '100%',
              },
            }}
          >
            <FilterButton filterLabel={t('MonitoringAnalysisOptions')} filterCount={filterCount} excludeReset>
              <FormGroup sx={{ paddingX: theme.spacing(2), paddingY: theme.spacing(1) }}>
                <FormLabel>{t('MonitoringAnalysisCoTwoBalance')}</FormLabel>
                <RadioGroup
                  row
                  value={extrapolation}
                  onChange={(event) => setExtrapolation(event.target.value === 'true')}
                >
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label={t('MonitoringAnalysisCoTwoBalanceFilter')}
                  />
                  <FormControlLabel value control={<Radio />} label={t('MonitoringAnalysisCoTwoBalanceYearly')} />
                </RadioGroup>
              </FormGroup>
              <FormGroup sx={{ paddingX: theme.spacing(2), paddingY: theme.spacing(1) }}>
                <FormLabel>{t('MonitoringAnalysisViewSettings')}</FormLabel>
                <FormControlLabel
                  control={<Checkbox onChange={(event) => setShowIcons(event.target.checked)} checked={showIcons} />}
                  label={t('MonitoringAnalysisViewIcons')}
                />
                <Controller
                  name="includeWayBack"
                  defaultValue={defaultValues?.includeWayBack}
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={value}
                          onChange={(event) => {
                            onChange(event);
                            onFilter(methods.getValues());
                          }}
                        />
                      }
                      label={t('MonitoringAnalysisIncludeWayBack')}
                    />
                  )}
                />
                <FormControlLabel
                  control={
                    <Checkbox onChange={(event) => setShowSubtitle(event.target.checked)} checked={showSubtitle} />
                  }
                  label={t('MonitoringAnalysisIncludeSubtitle')}
                />
                <FormControlLabel
                  control={
                    <Checkbox onChange={(event) => setEnglishVersion(event.target.checked)} checked={englishVersion} />
                  }
                  label={t('MonitoringAnalysisEnglishVersion')}
                />
              </FormGroup>
              <FormGroup sx={{ paddingX: theme.spacing(2), paddingY: theme.spacing(1) }}>
                <FormControlLabel
                  label={t('MonitoringAnalysisAutoRefresh')}
                  control={
                    <StyledSwitch checked={autoRefresh} onChange={(event) => setAutoRefresh(event.target.checked)} />
                  }
                />
              </FormGroup>
            </FilterButton>
          </Stack>
        </Grid>
        <Grid item xs={12 / 5} sx={{ boxSizing: 'border-box' }}>
          <Button
            startIcon={<StyledDownloadIcon />}
            color="secondary"
            size="large"
            variant="contained"
            onClick={onDownload}
            sx={{ boxSizing: 'border-box', width: '100%', height: '64px' }}
          >
            Download CSV
          </Button>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
