import { OrganizationUserRole, UserRole } from '@/services/api';

export class UserRoleService {
  static getOrganizationUserRoleForUserRole(role?: UserRole) {
    if (role === UserRole.IVM_USER || role === UserRole.ORGANIZATION_USER) {
      return {
        [UserRole.IVM_USER]: [OrganizationUserRole.IVM_INTERNAL, OrganizationUserRole.IVM_CONSULTANT],
        [UserRole.ORGANIZATION_USER]: [
          OrganizationUserRole.ORGANIZATION_MOBILITY_AGENT_WRITE,
          OrganizationUserRole.ORGANIZATION_MOBILITY_AGENT_READ,
          OrganizationUserRole.ORGANIZATION_DECISION_MAKER,
        ],
      }[role];
    }

    return Object.values(OrganizationUserRole);
  }
}
