import { Container, css, Grid, styled } from '@mui/material';
import { ReactNode } from 'react';

import { FullHeightLayout } from '@/components/layouts/FullHeightLayout';

const StyledFullHeightLayout = styled(FullHeightLayout)<{ childrenSide: SplitLayoutProps['childrenSide'] }>(
  ({ theme, childrenSide }) => css`
    position: relative;
    z-index: 0;
    display: flex;

    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      bottom: 0;
      left: ${{ left: 0, right: '50%' }[childrenSide]};
      width: 50%;
      background-color: ${theme.palette.primary.light};
    }
  `,
);

const StyledContainer = styled(Container)(css`
  display: flex;
`);

const StyledImage = styled('img', {
  shouldForwardProp: (prop) => prop !== 'childrenSide',
})<{ childrenSide: SplitLayoutProps['childrenSide'] }>(
  ({ childrenSide }) => css`
    display: block;
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: ${{ left: '50%', right: 0 }[childrenSide]};
    width: 50%;
    height: 100%;
    object-fit: cover;
  `,
);

type SplitLayoutProps = { children: ReactNode; images: [string, string, string]; childrenSide: 'left' | 'right' };

export function SplitLayout({ children, images, childrenSide }: SplitLayoutProps) {
  return (
    <StyledFullHeightLayout navigations={1} childrenSide={childrenSide}>
      <StyledImage
        childrenSide={childrenSide}
        alt=""
        src={images[0]}
        width="900"
        height="600"
        loading="lazy"
        decoding="async"
        srcSet={`${images[0]} 900w, ${images[1]} 1800w, ${images[2]} 2700w`}
        sizes="50vw"
      />

      <StyledContainer>
        <Grid
          container
          columnSpacing={24}
          alignItems="center"
          justifyContent={{ left: 'flex-start', right: 'flex-end' }[childrenSide]}
        >
          <Grid item xs={6}>
            {children}
          </Grid>
        </Grid>
      </StyledContainer>
    </StyledFullHeightLayout>
  );
}
