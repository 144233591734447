import { Container, css, styled } from '@mui/material';
import { ReactNode } from 'react';

import { FullHeightLayout } from '@/components/layouts/FullHeightLayout';
import { Navigation } from '@/components/navigations/Navigation';

const StyledFullHeightLayout = styled(FullHeightLayout)(css`
  display: flex;
`);

type ContentLayoutPageProps = { children: ReactNode };

export function ContentLayoutPage({ children }: ContentLayoutPageProps) {
  return (
    <>
      <Navigation />
      <StyledFullHeightLayout navigations={1}>
        <Container sx={{ minHeight: '100%' }}>{children}</Container>
      </StyledFullHeightLayout>
    </>
  );
}
