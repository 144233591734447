import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { TablePageSizeState } from '@/types/TablePageSizeState';

export const useTablePageSizeStore = create<TablePageSizeState>()(
  persist(
    (set) => ({
      pageSize:
        typeof window !== 'undefined'
          ? [10, 25, 50, 100].findLast(
              (value) =>
                value <=
                (window.innerHeight -
                  // Navigation
                  80 -
                  // Padding
                  2 * 32 -
                  // Search
                  48 -
                  24 -
                  // Filter Presets
                  40 -
                  24 -
                  // Table Header
                  40 -
                  // Table Footer
                  64 -
                  // Footer
                  56) /
                  40,
            ) || 10
          : 10,

      actions: {
        setPageSize: (pageSize) => set({ pageSize }),
      },
    }),
    {
      name: 'bmm/table-page-size',
      version: 1,

      partialize: ({ actions, ...rest }) => rest,
    },
  ),
);
