import dayjs from 'dayjs';
import 'dayjs/locale/de';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';

dayjs.locale('de');
dayjs.extend(localizedFormat);
dayjs.extend(localeData);

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Europe/Vienna');

dayjs.extend(relativeTime);
dayjs.extend(isBetween);
dayjs.extend(weekday);

dayjs.extend(customParseFormat);
