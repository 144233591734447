import { Typography } from '@mui/material';

import { ContentLayoutPage } from '@/components/layouts/ContentLayoutPage';

export function PrivacyPage() {
  return (
    <ContentLayoutPage>
      <Typography variant="h1">Datenschutzerklärung</Typography>
      <Typography>
        <br />
        Mit dieser Datenschutzerklärung informiert die Gesellschaft für integriertes Verkehrs- und Mobilitätsmanagement
        der Region Frankfurt RheinMain (nachfolgend “ivm” genannt) als verantwortliche Person nach Art. 4 Nr. 7 DS-GVO
        Sie als betroffene Person (nachfolgend „Nutzer/in“ genannt) darüber, welche personenbezogenen Daten von Ihnen
        bei der Nutzung der BMM Beratungsplattform „bmm.ivm-rheinmain.de“ (nachfolgend nur „Internetportal“ genannt)
        verarbeitet werden und wie damit verfahren wird. Dieses Internetportal unterstützt den Beratungsprozess im
        Rahmen des Beratungsprogramms “Besser zur Arbeit” softwareseitig. Auf Basis der für die Beratung getroffenen
        Vereinbarung zwischen der ivm GmbH und hrem Unternehmen oder Ihrer Verwaltung sind Sie herzlich eingeladen,
        dieses zu verwenden. Damit Sie das Internetportal nutzen können, werden Ihre E-Mail-Adresse sowie Ihre
        Unternehmenszugehörigkeit (auf Basis der E-Mail-Zuordnung zu Ihrem Arbeitgeber), die als personenbezogene Daten
        gelten, verarbeitet.
        <br />
        Zur Überwachung und Steuerung unserer Systeme werden Systemdaten in Protokolldateien (Serverlogs) gespeichert.
        Wir verwenden ausschließlich technisch notwendige Cookies, z.B. um den Login-Status zu speichern. Darüber hinaus
        werden keine Cookies eingesetzt. Wir verwenden keine automatisierten Entscheidungsfindungen und führen kein
        Profiling nach Art. 22 DSGVO durch. Der verantwortungsbewusste Umgang mit personenbezogenen Daten hat für uns
        hohe Priorität. Wir möchten Sie darüber informieren, welche Daten erhoben werden und wie wir sie verwenden.
        <br />
        <br />
        <Typography variant="h2">Datenschutz</Typography>
        <b>a)</b> Das Internetportal wird von der ivm GmbH (Integriertes Verkehrs- und Mobilitätsmanagement Region
        Frankfurt RheinMain), Bessie-Coleman-Straße 7, 60549 Frankfurt am Main betrieben, nachfolgend als „ivm“, „wir“
        oder „uns“ bezeichnet. Das Angebot des Internetportals wird jedem am BMM Beratungsprozess teilnehmendem
        Arbeitgeber zur Verfügung gestellt. Mitarbeitende der teilnehmenden Arbeitgeber, die das Internetportal im
        Rahmen Ihrer Tätigkeit nutzen, werden nachfolgend „Nutzer/in“ genannt.
        <br />
        <b>b)</b> Wir nehmen den Schutz der persönlichen Daten der Nutzer/innen sehr ernst. Wir behandeln die Daten der
        Nutzenden vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
        Datenschutzerklärung. Im Rahmen der Registrierung ist die Angabe personenbezogener Daten erforderlich wie z. B.
        die E-Mail-Adresse der/des Nutzenden. Zudem wird von uns die IP-Adresse der/des Nutzenden zum Zeitpunkt der
        Nutzung gespeichert. Die Nutzung dieser gespeicherten Daten erfolgt von uns ausschließlich zu administrativen
        Zwecken. Eine Weitergabe dieser Daten an Dritte zu anderen Zwecken als der Verwaltung der digitalen, von der/vom
        Nutzer/in auf der Internetplattform erfassten Inhalte findet ohne die ausdrückliche Zustimmung der/des Nutzenden
        nicht statt, es sei denn, dass wir dazu infolge gesetzlicher Vorschriften verpflichtet sind.
        <br />
        <b>c)</b> Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail)
        Sicherheitslücken aufweisen kann. Ein lückenloser Schutz dieser Daten vor dem Zugriff durch Dritte kann schon
        aus technischen Gründen nicht garantiert werden.
        <br />
        <b>d)</b> Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur
        Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit ausdrücklich
        widersprochen. Wir behalten uns zudem ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von
        Werbeinformationen, etwa durch Spam-Mails, vor.
        <br />
        <br />
        <Typography variant="h2">Auftragsdatenverarbeiter</Typography>
        Das Internetportal wird im Auftrag der ivm GmbH durch die TraffiCon GmbH, Strubergasse 26, A-5020 Salzburg
        betrieben. Entsprechend hat die ivm mit der TraffiCon GmbH eine Auftragsverarbeitungsvereinbarung geschlossen.
        <br />
        <br />
        <Typography variant="h2">SSL-Verschlüsselung</Typography>
        <b>a)</b> Unser Internetportal verwendet aus Gründen der Sicherheit und zum Schutz der Übertragung vertraulicher
        Inhalte, wie zum Beispiel von Anfragen, die Nutzende über das Portal an uns senden, eine SSL-Verschlüsselung.
        Eine verschlüsselte Verbindung erkennt man daran, dass die Adresszeile des Browsers von „http://“ auf „https://“
        wechselt und die Browserzeile ein Vorhängeschloss-Symbol enthält.
        <br />
        <b>b)</b> Wenn die SSL Verschlüsselung aktiviert ist, können die Daten, die von den Nutzenden an uns übermittelt
        werden, im Regelfall nicht von Dritten mitgelesen werden.
        <br />
        <br />
        <Typography variant="h2">Umfang und Zweck der Verarbeitung personenbezogener Daten</Typography>
        <b>a)</b> Wir speichern Daten über jeden Zugriff einer/s Nutzenden auf das Angebot unseres Internetportals über
        die Server-Logfiles. Solche Zugriffsdaten sind:
        <ul style={{ marginTop: 0, marginBottom: 0 }}>
          <li>Der Name der abgerufenen Webseite</li>
          <li>Datum und Uhrzeit der Serveranfrage</li>
          <li>Die übertragene Datenmenge</li>
          <li>Browsertyp/ Browserversion</li>
          <li>Das von der/vom Nutzenden verwendete Betriebssystem</li>
          <li>Die IP-Adresse der/des Nutzenden</li>
        </ul>
        <b>b)</b> Diese Protokolldaten werden von uns nur für statistische Auswertungen zum Zweck des Betriebs, der
        Sicherheit und der Optimierung des Angebots auf dem Internetportal verwendet. Wir behalten uns jedoch vor, die
        Protokolldaten nachträglich zu prüfen, wenn wir aufgrund konkreter Anhaltspunkte Kenntnis über eine
        rechtswidrige Nutzung erhalten oder der berechtigte Verdacht einer rechtswidrigen Nutzung besteht.
        <br />
        <b>c)</b> Im Falle einer Registrierung auf dem Internetportal werden folgende personenbezogene Daten auf den
        Servern der TraffiCon GmbH gespeichert:
        <ul style={{ marginTop: 0, marginBottom: 0 }}>
          <li>E-Mail-Adresse</li>
          <li>Passwort</li>
          <li>Arbeitgeber-Zugehörigkeit</li>
        </ul>
        Diese Daten werden durch die TraffiCon GmbH gespeichert und im Rahmen des Nutzungsverhältnisses verarbeitet. Die
        E-Mail-Adresse und die Zuordnung zu einem Arbeitgeber werden für die Benutzerverwaltung in der BMM
        Beratungsplattform verwendet. Sofern Sie uns diese Daten nicht zur Verfügung stellen, können Sie die BMM
        Beratungsplattform nicht nutzen. Eine Nutzung für Werbung oder Newsletterversand durch die TraffiCon GmbH
        erfolgt nicht. Ebenfalls gibt die TraffiCon GmbH Ihre Daten nicht ohne Ihr Einverständnis an Dritte weiter. Im
        Übrigen verarbeitet die TraffiCon GmbH Ihre persönlichen Daten unter Beachtung der geltenden
        datenschutzrechtlichen Bestimmungen.
        <br />
        Die in diesem Abschnitt benannten Daten werden nur für den mit Ihrem Arbeitgeber vereinbarten Nutzungszeitraum
        der Beratungsplattform gespeichert. Sollten Sie die BMM-Beratungsplattform nicht mehr nutzen, dann löschen wir
        Ihre Zugriffsdaten und legen bei Bedarf eine/n neue/n Nutzer/in an. Hierfür ist eine Bestätigung durch Ihren
        Arbeitgeber erforderlich.
        <br />
        <br />
        <Typography variant="h2">Datensicherheitsmaßnahmen</Typography>
        Die Sicherheit Ihrer Daten ist uns ein großes Anliegen. Zum Schutz Ihrer Daten setzen wir daher auf technische
        und organisatorische Schutzmaßnahmen. Zu diesen zählen unter anderem:
        <ul style={{ marginTop: 0, marginBottom: 0 }}>
          <li>Technische und organisatorische Maßnahmen zum Schutz der datenverarbeitenden Systeme;</li>
          <li>Beschränkung der Zugriffe auf jene Personenkreise, welche die Daten zur Zweckerfüllung benötigen;</li>
          <li>
            Verpflichtung der mit der Datenverarbeitung betrauten Mitarbeiterinnen und Mitarbeiter zur Geheimhaltung;
          </li>
          <li>Vertragliche Vereinbarungen mit Dienstleistern (Auftragsverarbeiter).</li>
        </ul>
        <br />
        <Typography variant="h2">Links zu Webseiten Dritter</Typography>
        In der BMM Beratungsplattform sind Links zu Webseiten Dritter enthalten. Für deren Inhalte und Einhaltung der
        Datenschutzbestimmungen wird keine Gewährleistung übernommen.
        <br />
        <br />
        <Typography variant="h2">Auskunft, Löschung und Sperrung</Typography>
        Jede/r Nutzer/in hat grundsätzlich das Recht auf Auskunft über die gespeicherten Daten gemäß Art. 15 DS-GVO, auf
        Berichtigung unzutreffender Daten gemäß Art. 16 DS-GVO, auf Löschung von Daten gemäß Art. 17 DS-GVO, auf
        Einschränkung der Verarbeitung von Daten gemäß Art. 18 DS-GVO, auf Widerspruch gegen eine unzumutbare
        Datenverarbeitung gemäß Art. 21 DS-GVO sowie auf Datenübertragbarkeit gemäß Art. 20 DS-GVO. Sofern die
        Verarbeitung aufgrund einer Einwilligungserklärung erfolgt, hat der Betroffene die Möglichkeit, diese jederzeit
        zu widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten
        Verarbeitung berührt wird.
        <br />
        <br />
        <Typography variant="h2">Verantwortlicher und Anlaufstelle für Betroffene</Typography>
        Die ivm GmbH ist Datenschutzverantwortlicher im Sinne der DSGVO für die personenbezogenen Daten. Ihre Rechte
        können Sie mündlich am Unternehmenssitz oder telefonisch, per E-Mail oder per Post gegenüber dem
        Verantwortlichen ausüben:
        <br />
        <b>Verantwortliche Stelle im Sinne des Datenschutzrechtes:</b>
        <br />
        Firma:
        <br />
        ivm GmbH
        <br />
        Frau Heike Mühlhans (Geschäftsführerin)
        <br />
        Bessie-Coleman-Straße 7<br />
        60549 Frankfurt am Main
        <br />
        Tel: 0 69 / 660 759-0
        <br />
        Fax: 0 69 / 660 759 – 90
        <br />
        <br />
        <b>Datenschutzbeauftragter</b>
        <br />
        Den Datenschutzbeauftragten unseres Unternehmens erreichen Sie unter:
        <br />
        datenschutzbeauftragter@ivm-rheinmain.de
        <br />
      </Typography>
      <Typography>
        <br />
        <b>Quellenangabe</b>
        <br />
        Diese Datenschutzerklärung wurde erstellt mit Hilfe von kostenfreien Angeboten unter www.e-recht24.de,
        www.datenschutzbeauftragter-info.de sowie www.newsletter2go.com nebst Ergänzungen und Änderungen durch den
        Datenschutzbeauftragten der ivm.
      </Typography>
    </ContentLayoutPage>
  );
}
