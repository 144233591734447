import { Button, LinearProgress, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { FormSection } from '@/components/form-elements/FormSection';
import { useAppAbility } from '@/hooks/useAppAbility';
import { QueueStatus } from '@/indicator-process/relations-form/QueueStatus';
import { QueryKeys } from '@/services/QueryKeys';
import { Action, IndicatorRoutingQueueService, Subject } from '@/services/api';

export function IndicatorRoutingStatusForm() {
  const ability = useAppAbility();

  const { data: status, refetch } = useQuery({
    queryKey: QueryKeys.indicatorRelations.getStatus,
    queryFn: () => IndicatorRoutingQueueService.status(),
    refetchInterval: ({ state: { data } }) => (!(data && data.queueIsPaused) ? 10000 : false),
  });

  return (
    <>
      <FormSection title="Berechnungen Status">
        <>
          <QueueStatus />

          {!status?.queueIsPaused && (
            <>
              {(status?.queueActiveCount || 0) > 0 && (
                <Typography textAlign="center" mt={2}>
                  Es laufen gerade Berechnungen
                </Typography>
              )}
              <LinearProgress
                sx={{ marginTop: 2 }}
                variant={(status?.queueActiveCount || 0) > 0 ? 'indeterminate' : 'determinate'}
                value={0}
              />
            </>
          )}
          <Typography textAlign="center" mt={2}>
            {status?.queueWaitingCount} Relationen in der Warteschlange
          </Typography>
        </>
      </FormSection>

      <FormSection title="Zeitplan">
        <Typography mb={2}>
          Der Zeitplan lässt die Berechnungen zwischen den angegeben Uhrzeiten durchführen. Bei Aktivierung oder
          Änderung des Zeitplans übernimmt dieser die Steuerung erst mit dem nächsten Von- bzw. Bis-Zeitpunkt.
        </Typography>

        <Button
          size="large"
          color={status?.cronJobsScheduled ? 'secondary' : 'primary'}
          variant="contained"
          onClick={async () => {
            if (status?.cronJobsScheduled) {
              await IndicatorRoutingQueueService.deleteCronJobs();
            } else {
              await IndicatorRoutingQueueService.scheduleCronJobs();
            }

            refetch();
          }}
          disabled={ability.cannot(Action.READ_ALL, Subject.INDICATOR_PROCESS)}
        >
          Zeitplan {status?.cronJobsScheduled ? 'deaktivieren' : 'aktivieren'}
        </Button>
      </FormSection>

      <FormSection title="Berechnungen manuell steuern">
        <Typography mb={2}>
          Mit Klick auf &quot;Berechnungen starten&quot; wird die Berechnung unmittelbar gestartet und läuft bei
          aktivierten Zeitplan bis zum Ende des eingestellten Zeitraums. Mit dieser Funktion kann im Einzelfall
          außerhalb des vereinbarten nächtlichen Zeitraums in die Berechnung eingegriffen werden. Im Vorfeld ist eine
          Abstimmung mit dem Betreiber der ÖV-Schnittstelle (z.B. RMV) erforderlich.
        </Typography>

        <Button
          size="large"
          color={status?.queueIsPaused ? 'primary' : 'secondary'}
          variant="contained"
          onClick={async () => {
            if (status?.queueIsPaused) {
              await IndicatorRoutingQueueService.resumeQueue();
            } else {
              await IndicatorRoutingQueueService.pauseQueue();
            }

            refetch();
          }}
          disabled={ability.cannot(Action.READ_ALL, Subject.INDICATOR_PROCESS)}
        >
          Berechnungen {status?.queueIsPaused ? 'starten' : 'pausieren'}
        </Button>
      </FormSection>
    </>
  );
}
