import { FormControl, FormLabel, Grid, Radio, RadioGroup, styled, css } from '@mui/material';
import { Controller, FieldPath, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SurveyImportance } from '@/services/api';

const StyledRadio = styled(Radio)(css`
  @media print {
    margin-right: 0;
  }
`);

const StyledGrid = styled(Grid)(css`
  @media print {
    page-break-inside: avoid;
  }
`);

type SurveyRadioButtonsProps<U extends FieldValues> = {
  label: string;
  name: FieldPath<U>;
  isLoading: boolean;
  disabled?: boolean;
};

export function SurveyRadioButtons<T extends string, U extends FieldValues>({
  label,
  name,
  isLoading,
  disabled,
}: SurveyRadioButtonsProps<U>) {
  const { t } = useTranslation();

  const labelId = `SurveyRadioButtons-${name}`;

  return isLoading ? null : (
    <StyledGrid container spacing={0} className="form-section-item" alignItems="center">
      <Grid item xs={8}>
        <FormLabel id={labelId} sx={{ color: 'text.primary' }}>
          {label}
        </FormLabel>
      </Grid>
      <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <FormControl component="fieldset" disabled={disabled}>
          <Controller<FieldValues>
            name={name}
            defaultValue={null}
            render={({ field }) => (
              <RadioGroup row {...field}>
                {Object.values(SurveyImportance).map((value, index) => (
                  <StyledRadio
                    inputProps={{
                      'aria-label': t(`SurveyImportance.${value}`) as string,
                      'aria-labelledby': labelId,
                    }}
                    key={value}
                    value={value as T}
                    sx={{ marginRight: index === 3 ? 4 : 0 }}
                  />
                ))}
              </RadioGroup>
            )}
          />
        </FormControl>
      </Grid>
    </StyledGrid>
  );
}
