import { Autocomplete, Box, Button, TextField, Typography } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { FormSection } from '@/components/form-elements/FormSection';
import { TextFieldSelect } from '@/components/form-elements/TextFieldSelect';
import { SidebarLayout } from '@/components/layouts/SidebarLayout';
import { useSetValidationErrors } from '@/hooks/useSetValidationErrors';
import { QueryKeys } from '@/services/QueryKeys';
import { UserRoleService } from '@/services/UserRoleService';
import { OrganizationUserRole, OrganizationUsersService, UserRole, UsersService } from '@/services/api';

type FormData = { userEmail: string; role: OrganizationUserRole };

export function CreateOrganizationUserPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { organizationId } = useParams();
  const [organizationUserRoles, setOrganizationUserRoles] = useState<OrganizationUserRole[]>([]);

  const methods = useForm<FormData>();
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = methods;
  const userEmailValue = watch('userEmail');
  const { setValidationErrors } = useSetValidationErrors(methods.setError);

  const { data: users = [] } = useQuery({
    queryKey: QueryKeys.users.all,

    queryFn: () => UsersService.findAll({ role: [UserRole.IVM_USER, UserRole.ORGANIZATION_USER] }),
  });

  const filters = { organizationId, includeUsers: true };
  const { data: organizationUsers = [] } = useQuery({
    queryKey: QueryKeys.organizationUsers.allFilters(filters),

    queryFn: () => OrganizationUsersService.findAll(filters),
  });

  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: ({ userEmail, role }: FormData) =>
      OrganizationUsersService.create({ requestBody: { organizationId: organizationId as string, userEmail, role } }),

    onSuccess: () => {
      navigate(`/arbeitgeber/${organizationId}/benutzerinnen`);

      return queryClient.invalidateQueries({
        queryKey: QueryKeys.organizationUsers.allFilters({
          organizationId: organizationId as string,
          includeUsers: true,
        }),
      });
    },

    onError: setValidationErrors,
  });

  const organizationUserIds = organizationUsers.map(({ userId }) => userId);

  useEffect(() => {
    const userValue = users.find(({ email }) => email === userEmailValue);

    const newOrganizationUserRoles = UserRoleService.getOrganizationUserRoleForUserRole(userValue?.role);

    if (newOrganizationUserRoles.length !== organizationUserRoles.length) {
      setOrganizationUserRoles(newOrganizationUserRoles);
    }
  }, [organizationUserRoles.length, userEmailValue, users]);

  useEffect(() => {
    setValue('role', organizationUserRoles[0]);
  }, [organizationUserRoles, setValue]);

  return (
    <SidebarLayout>
      <FormProvider {...methods}>
        <form
          id="main-form"
          onSubmit={handleSubmit((data) => {
            mutate(data);
          })}
        >
          <FormSection title="Benutzer/in dem Arbeitgeber zuordnen">
            <Controller
              control={control}
              name="userEmail"
              defaultValue=""
              rules={{ required: true }}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  options={users}
                  freeSolo
                  getOptionLabel={(value) => {
                    if (typeof value === 'string') {
                      return value;
                    }

                    return value.email;
                  }}
                  getOptionDisabled={({ id }) => organizationUserIds.includes(id)}
                  onInputChange={(event, value) => {
                    field.onChange(value);
                  }}
                  onChange={(event, value) => {
                    if (typeof value !== 'string') {
                      field.onChange(value?.email);
                    }
                  }}
                  renderOption={(props, user) => (
                    <li {...props}>
                      <Box>
                        <Box>{user.email}</Box>

                        <Typography component="span" color="text.secondary" fontSize="0.75rem" display="block">
                          {t(`UserRole.${user.role}`)}
                          {organizationUserIds.includes(user.id) && ' – Bereits zugeordnet'}
                        </Typography>
                      </Box>
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      type="email"
                      label="Benutzer/in E-Mail"
                      error={!!errors.userEmail}
                      helperText={errors?.userEmail?.message}
                    />
                  )}
                />
              )}
            />

            <TextFieldSelect
              label="Rolle"
              name="role"
              i18nKey="OrganizationUserRole"
              options={organizationUserRoles}
              disabled={!userEmailValue}
            />
          </FormSection>

          <Button type="submit" variant="contained" size="large">
            Zuordnen
          </Button>
        </form>
      </FormProvider>
    </SidebarLayout>
  );
}
