import { Button, Container, InputAdornment, TextField, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { FormSection } from '@/components/form-elements/FormSection';
import { FullHeightLayout } from '@/components/layouts/FullHeightLayout';
import { Navigation } from '@/components/navigations/Navigation';
import { AuthenticationService, ResetPasswordDto } from '@/services/api';

export function ResetPasswordPage() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showPassword, setShowPassword] = useState(false);

  const { handleSubmit, setValue, control } = useForm<ResetPasswordDto>();

  const { mutate, error } = useMutation({
    mutationFn: (requestBody: ResetPasswordDto) => AuthenticationService.resetPassword({ requestBody }),

    onSuccess: () => {
      navigate('/');
    },
  });

  useEffect(() => {
    const token = searchParams.get('token');

    if (token) {
      setValue('passwordResetToken', token);
    }
  }, [searchParams, setSearchParams, setValue]);

  return (
    <>
      <Navigation />

      <FullHeightLayout navigations={1}>
        <Container>
          <form onSubmit={handleSubmit((requestBody) => mutate(requestBody))}>
            <FormSection title="Passwort zurücksetzen">
              <Typography gutterBottom>Bitte vergeben Sie ein neues Passwort.</Typography>

              <Controller
                defaultValue=""
                name="newPassword"
                rules={{ required: true, minLength: 8 }}
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    label="Neues Passwort"
                    type={showPassword ? 'text' : 'password'}
                    autoComplete="new-password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword((prevState) => !prevState)}
                          >
                            {showPassword ? 'Verstecken' : 'Anzeigen'}
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                    error={!!fieldState.error || !!error}
                    helperText={
                      error ? 'Es ist ein Fehler aufgetreten' : (field.value.length || 0) < 8 && 'Mindestens 8 Zeichen'
                    }
                    {...field}
                  />
                )}
              />

              <Button type="submit" color="primary" size="large" variant="contained" sx={{ marginTop: 5 }}>
                Passwort ändern
              </Button>
            </FormSection>
          </form>
        </Container>
      </FullHeightLayout>
    </>
  );
}
