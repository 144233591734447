import { Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { QueryKeys } from '@/services/QueryKeys';
import { IndicatorRoutingQueueService } from '@/services/api';

export function QueueStatus() {
  const { data: routingConfiguration } = useQuery({
    queryKey: QueryKeys.routingConfiguration.getConfig,

    queryFn: () => IndicatorRoutingQueueService.getConfiguration(),
  });
  const { data: status } = useQuery({
    queryKey: QueryKeys.indicatorRelations.getStatus,

    queryFn: () => IndicatorRoutingQueueService.status(),
  });

  let display;

  if (routingConfiguration && status) {
    const getTimeSpanForDay = (day: number) => ({
      from: dayjs(day < 5 ? routingConfiguration.weekdaysFrom : routingConfiguration.weekendsFrom, 'HH:mm:ss'),
      to: dayjs(day < 5 ? routingConfiguration.weekdaysTo : routingConfiguration.weekendsTo, 'HH:mm:ss'),
    });

    if (!status.cronJobsScheduled) {
      if (status.queueIsPaused) {
        display = 'Berechnungen sind pausiert und der Zeitplan deaktiviert';
      } else {
        display = 'Berechnungen laufen ohne Zeitplan';
      }
    } else {
      const day = dayjs().weekday();
      const { from, to } = getTimeSpanForDay(day);

      if (status.queueIsPaused) {
        if (dayjs().isBefore(from)) {
          display = `Berechnungen pausiert (bis ${from.format('LT')})`;
        } else {
          display = `Berechnungen pausiert (bis morgen ${getTimeSpanForDay(day + 1).from.format('LT')})`;
        }
      } else if (dayjs().isBefore(to)) {
        display = `Berechnungen laufen (bis ${to.format('LT')})`;
      } else {
        display = `Berechnungen laufen (bis morgen ${getTimeSpanForDay(day + 1).to.format('LT')})`;
      }
    }
  }

  return display ? (
    <Typography fontWeight="bold" textAlign="center">
      {display}
    </Typography>
  ) : null;
}
