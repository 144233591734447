import { useEffect } from 'react';
import { UseFormWatch } from 'react-hook-form';
import { useDebouncedCallback } from 'use-debounce';

export const useAutoSave = (handleSubmit: () => Promise<void>, watch: UseFormWatch<any>) => {
  const debouncedSubmit = useDebouncedCallback(handleSubmit, 500);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name) {
        debouncedSubmit();
      }
    });

    return () => subscription.unsubscribe();
  }, [debouncedSubmit, watch]);
};
