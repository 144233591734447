import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { QueryKeys } from '@/services/QueryKeys';
import { LocationsService } from '@/services/api';

export const useQueryLocation = () => {
  const { locationId } = useParams();

  return useQuery({
    queryKey: QueryKeys.locations.id(locationId as string),
    queryFn: () => LocationsService.findOne({ id: locationId as string }),
    enabled: !!locationId,
  });
};