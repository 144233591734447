import { Container } from '@mui/material';

import { AcceptInvitation } from '@/authentication/accept-invitation/AcceptInvitation';
import { SidebarLayout } from '@/components/layouts/SidebarLayout';
import { Navigation } from '@/components/navigations/Navigation';

export function AcceptInvitationPage() {
  return (
    <>
      <Navigation />

      <SidebarLayout navigations={1}>
        <Container>
          <AcceptInvitation />
        </Container>
      </SidebarLayout>
    </>
  );
}
