import { useQuery } from '@tanstack/react-query';

import { Leaf } from '@/components/tree-map/Leaf';
import { QueryKeys } from '@/services/QueryKeys';
import { AdminLevel, AdminLevelsService, Network, NetworksService } from '@/services/api';

type LocationLeafsProps = {
  id: string;
  type: 'AdminLevel' | 'Network';
  queryFn: typeof AdminLevelsService.findOne | typeof NetworksService.findOne;
};

export function LocationLeafs({ id, type, queryFn }: LocationLeafsProps) {
  const filter = { includeLocations: true };
  const { data } = useQuery<AdminLevel | Network>({
    queryKey: {
      AdminLevel: QueryKeys.adminLevels.idIncludes(id, filter),
      Network: QueryKeys.networks.idIncludes(id, filter),
    }[type],

    queryFn: () => queryFn({ id, ...filter }),
  });

  return data?.locations ? (
    <>
      {data.locations.map((location) => (
        <Leaf key={location.id} location={location} />
      ))}
    </>
  ) : null;
}
