import { Box, Container, Grid, MenuItem, TextField } from '@mui/material';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { TextFieldLoading } from '@/components/form-elements/TextFieldLoading';
import { FullHeightLayout } from '@/components/layouts/FullHeightLayout';
import { useQueryOrganization } from '@/hooks/queries/useQueryOrganization';
import { IndicatorProcessStatus } from '@/services/api';

export function AnalysisPage() {
  const [consultingCaseLocationId, setConsultingCaseLocationId] = useState('');
  const navigate = useNavigate();
  const { organizationId, indicatorProcessId } = useParams();

  const { data: organization, isLoading } = useQueryOrganization();

  return (
    <FullHeightLayout>
      <Container>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <TextFieldLoading isLoading={isLoading}>
              <TextField
                value={indicatorProcessId || ''}
                label="Prozessname"
                select
                SelectProps={{ native: false }}
                onChange={(event) => {
                  navigate(`/arbeitgeber/${organizationId}/wohnstandortanalyse/${event.target.value}` as string);
                }}
              >
                {organization?.processes?.map((process) => (
                  <MenuItem
                    key={process.id}
                    value={process.id}
                    disabled={
                      !(
                        process.status === IndicatorProcessStatus.INDICATORS_APPROVED ||
                        process.status === IndicatorProcessStatus.CALCULATION_DONE
                      )
                    }
                  >
                    {process.name}
                  </MenuItem>
                ))}
              </TextField>
            </TextFieldLoading>
          </Grid>
          <Grid item xs={4}>
            <TextFieldLoading isLoading={isLoading}>
              <TextField
                defaultValue="10"
                label="Standort"
                select
                SelectProps={{ native: false }}
                onChange={(event) => setConsultingCaseLocationId(event.target.value)}
              >
                <MenuItem value="10">Alle Standorte</MenuItem>
                {organization?.locations?.map(
                  (location) =>
                    location.consultingCaseLocationId && (
                      <MenuItem key={location.id} value={location.consultingCaseLocationId}>
                        {location.name}
                      </MenuItem>
                    ),
                )}
              </TextField>
            </TextFieldLoading>
          </Grid>
        </Grid>
      </Container>
      {indicatorProcessId && (
        <Box sx={{ border: 'none', position: 'fixed', top: '240px', left: 0, right: 0, bottom: '56px' }}>
          <Box sx={{ display: 'flex', width: '100%', height: '100%', flexDirection: 'column' }}>
            <iframe
              src={`https://${
                window.location.hostname === 'localhost' ? 'bmmdev.trafficon.eu' : window.location.host
              }/standortanalyse/?process_id=${indicatorProcessId}&locId=${consultingCaseLocationId}&orgName=${
                organization?.name
              }`}
              title="Wohnstandortanalyse"
              style={{ flexGrow: 1, border: 'none', margin: 0, padding: 0 }}
            />
          </Box>
        </Box>
      )}
    </FullHeightLayout>
  );
}
