import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, Routes, useLocation, useNavigationType } from 'react-router-dom';

Sentry.init({
  dsn: 'https://be827f2543cc46e7a884e06c7733ea41@sentry.trafficon.eu/2',
  enabled: import.meta.env.MODE !== 'development',
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  release: import.meta.env.VITE_SENTRY_RELEASE,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: import.meta.env.MODE === 'development' ? 1.0 : 0.1,
});

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
