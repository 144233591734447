import { TextField } from '@mui/material';
import { FieldPath, FieldValues, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextFieldLoading } from '@/components/form-elements/TextFieldLoading';

export type TextFieldSelectProps<T extends string, U extends FieldValues> = {
  label: string;
  name: FieldPath<U>;
  i18nKey: string;
  options: T[];
  isLoading?: boolean;
  disabled?: boolean;
};

export function TextFieldSelect<T extends string, U extends FieldValues>({
  label,
  name,
  i18nKey,
  options,
  isLoading = false,
  disabled,
}: TextFieldSelectProps<T, U>) {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <TextFieldLoading isLoading={isLoading}>
      <TextField label={label} select disabled={disabled} {...register(name)} error={!!errors[name]}>
        {options.map((value) => (
          <option key={value} value={value}>
            {t(`${i18nKey}.${value}`)}
          </option>
        ))}
      </TextField>
    </TextFieldLoading>
  );
}
