import { css, styled } from '@mui/material';
import { ReactNode } from 'react';

const StyledLink = styled('a')(
  ({ theme }) => css`
    color: ${theme.palette.text.primary};
    text-decoration: none;

    &.active {
      font-weight: bold;
    }
  `,
);

type NavigationLinkProps = { children: ReactNode; href: string };

export function NavigationExternalLink({ children, href }: NavigationLinkProps) {
  return (
    <StyledLink href={href} target="_blank" rel="noopener">
      {children}
    </StyledLink>
  );
}
