import { useParams } from 'react-router-dom';

import { SecondaryNavigation } from '@/components/navigations/SecondaryNavigation';
import { useQueryOrganization } from '@/hooks/queries/useQueryOrganization';
import { useAppAbility } from '@/hooks/useAppAbility';
import { Action, Subject } from '@/services/api';

export function OrganizationNavigation() {
  const { organizationId } = useParams();
  const ability = useAppAbility();

  const { data: organization } = useQueryOrganization();

  return (
    <SecondaryNavigation
      organizationSelect={!!organization}
      items={
        organization
          ? [
              { label: 'Übersicht', to: `/arbeitgeber/${organizationId}`, end: true },
              { label: 'Stammdaten', to: `/arbeitgeber/${organizationId}/stammdaten` },
              ...(organization?.fileStorage ? [{ label: 'Dokumente', href: organization.fileStorage }] : []),
              ...(ability.can(Action.MANAGE, Subject.ORGANIZATION_USER)
                ? [
                    {
                      label: 'Benutzer/innen',
                      to: `/arbeitgeber/${organizationId}/benutzerinnen`,
                    },
                  ]
                : []),
              ...(organization.processes && organization?.processes.length > 0
                ? [
                    {
                      label: 'Wohnstandortanalyse',
                      to: `/arbeitgeber/${organizationId}/wohnstandortanalyse`,
                    },
                  ]
                : []),
              { label: 'BESSER Monitor', to: `/arbeitgeber/${organizationId}/monitoring-analysis` },
            ]
          : [{ label: 'Arbeitgeber', to: '/arbeitgeber' }]
      }
    />
  );
}
