import { AppBar, Box, Container, css, Stack, styled, Toolbar } from '@mui/material';

import { NavigationLink } from '@/components/navigations/NavigationLink';

const PrimaryAppBar = styled(AppBar)(
  ({ theme }) => css`
    background-color: ${theme.palette.text.primary};

    a {
      color: ${theme.palette.common.white};
    }
  `,
) as typeof AppBar;

export function Footer() {
  return (
    <PrimaryAppBar position="static" component="footer">
      <Container>
        <Toolbar disableGutters>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              displayPrint: 'none',
            }}
          >
            <Stack direction="row" spacing={4}>
              {[
                { label: 'Hilfe', to: '/help' },
                { label: 'Kontakt', to: '/kontakt' },
                { label: 'Datenschutz', to: `/datenschutz` },
                { label: 'Impressum', to: `/impressum` },
              ].map(({ label, to }) => (
                <NavigationLink key={to} to={to}>
                  {label}
                </NavigationLink>
              ))}
            </Stack>
          </Box>
        </Toolbar>
      </Container>
    </PrimaryAppBar>
  );
}
