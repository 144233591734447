import { Tooltip } from '@mui/material';

import Complete from '@/assets/icons/status/complete.svg?react';
import Empty from '@/assets/icons/status/empty.svg?react';

type MonitoringStatusIconProps = { status: boolean };

export function MonitoringStatusIcon({ status }: MonitoringStatusIconProps) {
  return status ? (
    <Tooltip title="aktiviert">
      <span>
        <Complete />
      </span>
    </Tooltip>
  ) : (
    <Tooltip title="nicht aktiviert">
      <span>
        <Empty />
      </span>
    </Tooltip>
  );
}
