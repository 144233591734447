import { Autocomplete, Box, Button, TextField, Typography } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { FormSection } from '@/components/form-elements/FormSection';
import { TextFieldSelect } from '@/components/form-elements/TextFieldSelect';
import { SidebarLayout } from '@/components/layouts/SidebarLayout';
import { QueryKeys } from '@/services/QueryKeys';
import { UserRoleService } from '@/services/UserRoleService';
import {
  OrganizationUserRole,
  OrganizationUsersService,
  UsersService,
  OrganizationsService,
  Organization,
} from '@/services/api';

type FormData = { organization: Pick<Organization, 'id' | 'name'>; role: OrganizationUserRole };

export function CreateOrganizationUserPage() {
  const navigate = useNavigate();
  const { userId } = useParams();

  const methods = useForm<FormData>();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = methods;

  const { data: user } = useQuery({
    queryKey: QueryKeys.users.id(userId as string),

    queryFn: () => UsersService.findOne({ id: userId as string }),
  });
  const { data: organizations = [] } = useQuery({
    queryKey: QueryKeys.organizations.all,
    queryFn: () => OrganizationsService.findAll(),
  });

  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: ({ organization, role }: FormData) =>
      OrganizationUsersService.create({
        requestBody: { organizationId: organization.id, userEmail: user?.email as string, role },
      }),

    onSuccess: () => {
      navigate(`/beratung/benutzerinnen/${userId}`);

      return queryClient.invalidateQueries({ queryKey: QueryKeys.users.all });
    },
  });

  const organizationUserIds = user?.organizations?.map(({ organizationId }) => organizationId);
  const organizationUserRoles = useMemo(
    () => UserRoleService.getOrganizationUserRoleForUserRole(user?.role),
    [user?.role],
  );

  return (
    <SidebarLayout>
      <FormProvider {...methods}>
        <form
          id="main-form"
          onSubmit={handleSubmit((data) => {
            mutate(data);
          })}
        >
          <FormSection title={`Arbeitgeber der Benutzer/in ${user?.email} zuordnen`}>
            <Controller
              control={control}
              name="organization"
              rules={{ required: true }}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  options={organizations}
                  getOptionLabel={({ name }) => name}
                  getOptionDisabled={({ id }) => organizationUserIds?.includes(id) || false}
                  onChange={(event, value) => {
                    field.onChange(value);
                  }}
                  renderOption={(props, organization) => (
                    <li {...props}>
                      <Box>
                        <Box>{organization.name}</Box>

                        {organizationUserIds?.includes(organization.id) && (
                          <Typography component="span" color="text.secondary" fontSize="0.75rem" display="block">
                            Bereits zugeordnet
                          </Typography>
                        )}
                      </Box>
                    </li>
                  )}
                  renderInput={(params) => <TextField {...params} label="Arbeitgeber" error={!!errors.organization} />}
                />
              )}
            />

            <TextFieldSelect label="Rolle" name="role" i18nKey="OrganizationUserRole" options={organizationUserRoles} />
          </FormSection>

          <Button type="submit" variant="contained" size="large">
            Zuordnen
          </Button>
        </form>
      </FormProvider>
    </SidebarLayout>
  );
}
