import { Box, Stack } from '@mui/material';

import { NavigationLink } from '@/components/navigations/NavigationLink';
import { User, UserRole } from '@/services/api';
import { theme } from '@/setup/theme';
import { useGlobalStore } from '@/stores/useGlobalStore';

export function ConsultingNavigation() {
  const user = useGlobalStore((state) => state.user);

  return (
    <Stack direction="row" spacing={4}>
      {[
        { label: 'Arbeitgeber', to: 'arbeitgeber' },
        ...([UserRole.TRAFFICON_ADMIN, UserRole.IVM_ADMIN].includes((user as User).role)
          ? [
              { label: 'Standorte', to: 'standorte' },
              { label: 'Benutzer/innen', to: 'benutzerinnen' },
              { label: 'Räumliche Ebenen', to: 'raeumliche-ebenen' },
              { label: 'Kategorien', to: 'kategorien' },
            ]
          : []),
        { label: 'WSA Berechnung', to: 'wsa-berechnung' },
        { label: 'BESSER Monitor', to: 'monitoring-analysis' },
        { label: '|', to: '' },
        { label: 'Wohnstandortanalyse SMM', to: 'standortunabhaengige-wsa' },
      ].map(({ label, to }) =>
        label === '|' ? (
          <Box
            key={to}
            sx={{ width: '1px', height: '25px', borderLeft: `1px solid ${theme.palette.secondary.contrastText}` }}
          />
        ) : (
          <NavigationLink key={to} to={to} end={false}>
            {label}
          </NavigationLink>
        ),
      )}
    </Stack>
  );
}
