import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { de } from '@/i18n/de';
import { en } from '@/i18n/en';

i18n.use(initReactI18next).init({
  resources: {
    de: {
      translation: de,
    },
    en: {
      translation: en,
    },
  },
  lng: 'de',
  fallbackLng: 'de',

  interpolation: {
    escapeValue: false,
  },
});
