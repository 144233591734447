import { css, styled, TextField } from '@mui/material';
import { UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useIsFieldDisabled } from '@/hooks/useIsFieldDisabled';
import { ConsultingStatus, Organization, UpdateOrganizationDto } from '@/services/api';

const StyledTextField = styled(TextField)(
  ({ theme }) => css`
    .MuiNativeSelect-select {
      padding-left: ${theme.spacing(2)};
      padding-right: ${theme.spacing(4)} !important;
      border-radius: ${theme.spacing(4.5)} !important;
      background-color: ${theme.palette.secondary.main};
      height: ${theme.spacing(4.5)};
    }

    .MuiNativeSelect-icon {
      right: ${theme.spacing(2)};
    }

    .MuiInput-root {
      &::before,
      &::after {
        content: none;
      }
    }
  `,
);

export type ConsultingStatusChipProps =
  | {
      value?: ConsultingStatus;
      onChange?: (consultingStats: ConsultingStatus) => void;
      register?: undefined;
      organization?: Organization;
    }
  | {
      value?: undefined;
      onChange?: undefined;
      register?: UseFormRegister<Pick<UpdateOrganizationDto, 'status'>>;
      organization?: Organization;
    };

export function ConsultingStatusChip({ onChange, value, register, organization }: ConsultingStatusChipProps) {
  const { t } = useTranslation();
  const isFieldDisabled = useIsFieldDisabled(organization);

  return (
    <StyledTextField
      {...(value && { value })}
      variant="standard"
      select
      fullWidth={false}
      inputProps={{ 'aria-label': 'Beratungsprozess Status' }}
      disabled={isFieldDisabled('status')}
      {...(onChange && {
        onChange: ({ currentTarget: { value: newValue } }) => onChange(newValue as ConsultingStatus),
      })}
      {...(register && register('status'))}
    >
      {Object.values(ConsultingStatus)
        .map((consultingStatus) => ({
          label: t(`ConsultingStatus.${consultingStatus}`),
          consultingStatus,
        }))
        .map(({ consultingStatus, label }) => (
          <option key={consultingStatus} value={consultingStatus}>
            {label}
          </option>
        ))}
    </StyledTextField>
  );
}
