import { CircularProgress, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { SidebarLayout } from '@/components/layouts/SidebarLayout';
import { useAppAbility } from '@/hooks/useAppAbility';
import { useCurrentTab } from '@/hooks/useCurrentTab';
import { CoreDataForm } from '@/indicator-process/CoreDataForm';
import { RelationsForm } from '@/indicator-process/RelationsForm';
import { QueryKeys } from '@/services/QueryKeys';
import { Action, IndicatorProcess, IndicatorProcessService } from '@/services/api';

const navigationItems = ['Allgemeine Angaben', 'Relationen'];

export function IndicatorProcessPage() {
  const { indicatorProcessId } = useParams();
  const ability = useAppAbility();
  const { currentTab } = useCurrentTab();

  const { data: indicatorProcess, isLoading } = useQuery({
    queryKey: QueryKeys.indicatorProcesses.id(indicatorProcessId!),
    queryFn: () => IndicatorProcessService.findOne({ id: indicatorProcessId! }),
    enabled: !!indicatorProcessId,
  });

  return (
    <SidebarLayout
      type="tabs"
      navigationItems={navigationItems}
      buttonLabel={
        ability.can(Action.UPDATE, indicatorProcess as IndicatorProcess) && currentTab === 0 ? 'Speichern' : undefined
      }
    >
      {(tab: number) => {
        if (isLoading) {
          return (
            <Stack alignItems="center" py={8}>
              <CircularProgress />
            </Stack>
          );
        }

        return (
          <>
            {tab === 0 && <CoreDataForm />}
            {tab === 1 && <RelationsForm />}
          </>
        );
      }}
    </SidebarLayout>
  );
}
