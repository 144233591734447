import { Box, css, IconButton, Stack, styled, SxProps, Tooltip, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Arrows from '@/assets/icons/commute/arrows.svg?react';
import CarIcon from '@/assets/icons/commute/car.svg?react';
import Co2Icon from '@/assets/icons/commute/co2.svg?react';
import PtIcon from '@/assets/icons/commute/pt.svg?react';
import Info from '@/assets/icons/info-icon-plotly.svg?react';
import { CommuteType, MonitoringAnalysisData } from '@/services/api';

const ANNUAL_WORKING_DAYS = 207;
const COST_PER_T_CO2 = 80;

const StyledInfo = styled(Info)(
  () => css`
    width: 16px;
    height: auto;
  `,
);

const StyledBox = styled(Box)(
  ({ theme }) => css`
    background: ${theme.palette.secondary.main};
    position: relative;
  `,
);

const StyledContent = styled(Box)(
  ({ theme }) => css`
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: ${theme.spacing(1.5)} 0;
  `,
);

const infoTextStyle: SxProps = {
  textAlign: 'center',
  fontSize: 20,
};

type CarbonDioxidBadgeProps = {
  data: MonitoringAnalysisData[];
  reduced: boolean;
  extrapolation: boolean;
};

export function CarbonDioxidBadge({ data, reduced, extrapolation }: CarbonDioxidBadgeProps) {
  const { t } = useTranslation();

  const days = [
    ...new Set(
      data
        .map((commuteTypeData) => commuteTypeData.data)
        .flat()
        .map((obj) => obj.createdAt.split('T')[0]),
    ),
  ].length;
  const theme = useTheme();

  const carbonDioxide =
    data
      .filter((obj) => obj.commuteType === CommuteType.CAR_MOTORCYCLE || obj.commuteType === CommuteType.CAR_ELECTRIC)
      .reduce((total, current) => total + (reduced ? current.reducedCarbonDioxide : current.carbonDioxide), 0) *
    (extrapolation ? ANNUAL_WORKING_DAYS / days : 1);

  const carbonDioxideCosts = (carbonDioxide / 1000) * COST_PER_T_CO2;

  const [style, setStyle] = useState({ display: 'none' });

  let tooltipText;
  if (reduced) {
    tooltipText = t('MonitoringAnalysisCarbonDioxidBadgeTooltipReduced');
  } else if (!extrapolation) {
    tooltipText = t('MonitoringAnalysisCarbonDioxidBadgeTooltipFiltered');
  } else {
    tooltipText = t('MonitoringAnalysisCarbonDioxidBadgeTooltipAnnual');
  }

  let resultText;
  if (reduced) {
    resultText = t('MonitoringAnalysisCarbonDioxidBadgeEcoFriendly');
  } else if (!extrapolation) {
    resultText = t('MonitoringAnalysisCarbonDioxidBadgeEcoSum');
  } else {
    resultText = t('MonitoringAnalysisCarbonDioxidBadgeEcoExtrapolation');
  }

  return (
    <StyledBox onMouseEnter={() => setStyle({ display: 'block' })} onMouseLeave={() => setStyle({ display: 'none' })}>
      <Tooltip
        slotProps={{
          tooltip: {
            sx: { backgroundColor: 'rgb(105, 115, 138)', borderRadius: '2px', whiteSpace: 'pre', marginTop: 0 },
          },
          arrow: { sx: { color: 'rgb(105, 115, 138)' } },
        }}
        title={tooltipText}
        arrow
      >
        <IconButton size="small" sx={{ position: 'absolute', top: 0, right: 0 }} style={style}>
          <StyledInfo />
        </IconButton>
      </Tooltip>
      <StyledContent>
        <Typography sx={{ textAlign: 'center', marginTop: 1 }}>{resultText}</Typography>
        <Stack direction="row" spacing={3} justifyContent="center" alignItems="center">
          <Co2Icon width={50} height={50} color={theme.palette.primary.main} />
          <CarIcon width={50} height={50} color={theme.palette.primary.main} />
          {reduced && <Arrows style={{ height: 'auto', width: 30 }} color={theme.palette.primary.main} />}
          {reduced && <PtIcon style={{ width: 50, height: 'auto' }} color={theme.palette.primary.main} />}
          <Typography sx={infoTextStyle}>
            <b>
              {(carbonDioxide < 1000 ? carbonDioxide : carbonDioxide / 1000).toLocaleString('de', {
                minimumFractionDigits: carbonDioxide < 1000 ? 0 : 1,
                maximumFractionDigits: carbonDioxide < 1000 ? 0 : 1,
              })}
            </b>
            {` ${carbonDioxide < 1000 ? 'kg' : 't'} CO2`}
          </Typography>
          <Typography sx={infoTextStyle} gutterBottom>
            <b>
              {carbonDioxideCosts.toLocaleString('de-DE', {
                style: 'decimal',
                currency: 'EUR',
                minimumFractionDigits: carbonDioxideCosts < 10 ? 2 : 0,
                maximumFractionDigits: carbonDioxideCosts < 10 ? 2 : 0,
              })}
            </b>{' '}
            €{extrapolation && ' p.a.'}
          </Typography>
        </Stack>
      </StyledContent>
    </StyledBox>
  );
}
