import { Button, useTheme } from '@mui/material';
import { Layout } from 'plotly.js';
import { useTranslation } from 'react-i18next';

import { Plot } from '@/monitoring/plots/Plot';
import { CommuteUtils } from '@/monitoring/utils/CommuteUtils';
import { HandleDownload } from '@/services/DownloadHandler';
import { MonitoringAnalysisData } from '@/services/api';

type CarbonDioxidePlotProps = {
  data: MonitoringAnalysisData[];
  showIcons: boolean;
  showSubtitle?: boolean;
  layout?: Partial<Layout>;
  disableDownloadButton?: boolean;
  iconSizeFactor?: number;
};

export function CarbonDioxidePlot({
  data,
  showIcons,
  showSubtitle,
  layout,
  disableDownloadButton,
  iconSizeFactor = 1,
}: CarbonDioxidePlotProps) {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const plotData = data.filter((obj) => obj.carbonDioxide !== 0);
  const filterData = [
    {
      x: plotData.map((value) => t(`CommuteTypeShort.${value.commuteType}`)),
      y: plotData.map((value) => Number(value.carbonDioxide.toFixed(1))),
      type: 'bar',
      text: plotData.map((value) => value.carbonDioxide.toLocaleString(undefined, { maximumFractionDigits: 1 })),
      textposition: 'outside',
      hovertemplate: `%{x}<br>CO2: %{y} kg<br>${t('MonitoringAnalysisCarbonDioxidPlotNumOfRoutes')}: %{customdata}<extra></extra>`,
      customdata: plotData.map((value) => value.count),
      orientation: 'v',
      marker: {
        color: plotData.map((obj) => CommuteUtils[obj.commuteType].color),
      },
    },
  ];

  const header = ['Verkehrsmittel', 'CO2 pro Weg'];
  const csvData = filterData.flatMap((param) =>
    param.x.map((value, index) => ({
      value,
      number: param.y[index].toLocaleString('de-DE'),
    })),
  );

  return (
    <>
      {!disableDownloadButton && (
        <Button onClick={() => HandleDownload({ csvData, header, name: 'CO2 Bilanz' })}>Download CSV</Button>
      )}
      <Plot
        data={filterData as any}
        layout={{
          ...layout,
          title: `${t('MonitoringAnalysisCarbonDioxidPlotTitle')}${
            showSubtitle ? `<br><sub>${t('MonitoringAnalysisCarbonDioxidPlotSubtitle')}</sub>` : ''
          }`,
          yaxis: { title: 'kg CO2', fixedrange: true },
          xaxis: { fixedrange: true, showticklabels: !showIcons },
          images: showIcons
            ? plotData.map((obj, index) => ({
                source: `data:image/svg+xml;utf8,${encodeURIComponent(CommuteUtils[obj.commuteType].raw.replaceAll('currentColor', theme.palette.primary.main))}`,
                xref: 'paper',
                yref: 'paper',
                x: (index + 0.5) / plotData.length,
                y: -0.02,
                sizex: 0.15 * iconSizeFactor,
                sizey: 0.15 * iconSizeFactor,
                layer: 'above',
                sizing: 'contain',
                xanchor: 'center',
                yanchor: 'top',
              }))
            : [],
        }}
        useResizeHandler
        style={{ width: '100%', height: '100%' }}
        config={{ locale: i18n.language }}
      />
    </>
  );
}
