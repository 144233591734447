import { Autocomplete, Box, Container, Grid, TextField } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { FullHeightLayout } from '@/components/layouts/FullHeightLayout';
import { QueryKeys } from '@/services/QueryKeys';
import { IndicatorProcessService, IndicatorProcessStatus } from '@/services/api';

export function IndependentAnalysisPage() {
  const navigate = useNavigate();
  const { indicatorProcessId, indicatorProcessId2 } = useParams();

  const filters = { search: '', independent: true };
  const { data: indicatorProcesses = [] } = useQuery({
    queryKey: QueryKeys.indicatorProcesses.allFilters(filters),
    queryFn: () => IndicatorProcessService.findAll(filters),
  });

  return (
    <FullHeightLayout>
      <Container>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Autocomplete
              options={indicatorProcesses.filter(
                (process) =>
                  process.status === IndicatorProcessStatus.INDICATORS_APPROVED ||
                  process.status === IndicatorProcessStatus.CALCULATION_DONE,
              )}
              getOptionLabel={(option) => option.name}
              onChange={(event, value) => {
                navigate(`/beratung/standortunabhaengige-wsa/${value ? `${value.id}` : ''}` as string);
              }}
              renderInput={(params) => <TextField {...params} label="Prozessname" />}
            />
          </Grid>
          {indicatorProcessId && (
            <Grid item xs={4}>
              <Autocomplete
                options={indicatorProcesses.filter(
                  (process) =>
                    (process.status === IndicatorProcessStatus.INDICATORS_APPROVED ||
                      process.status === IndicatorProcessStatus.CALCULATION_DONE) &&
                    process.id !== indicatorProcessId,
                )}
                getOptionLabel={(option) => option.name}
                onChange={(event, value) => {
                  navigate(
                    `/beratung/standortunabhaengige-wsa/${indicatorProcessId}${value ? `/${value.id}` : ''}` as string,
                  );
                }}
                renderInput={(params) => <TextField {...params} label="Prozessname 2" />}
              />
            </Grid>
          )}
        </Grid>
      </Container>
      {indicatorProcessId && (
        <Box sx={{ border: 'none', position: 'fixed', top: '240px', left: 0, right: 0, bottom: '56px' }}>
          <Box sx={{ display: 'flex', width: '100%', height: '100%', flexDirection: 'column' }}>
            <iframe
              src={`https://${
                window.location.hostname === 'localhost' ? 'bmm-test.trafficon.eu' : window.location.host
              }/standortanalyse_smm/?process_id=${indicatorProcessId}${
                indicatorProcessId2 ? `&process_id2=${indicatorProcessId2}` : ''
              }`}
              title="Wohnstandortanalyse"
              style={{ flexGrow: 1, border: 'none', margin: 0, padding: 0 }}
            />
          </Box>
        </Box>
      )}
    </FullHeightLayout>
  );
}
