import { Button, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { FilterHeader } from '@/components/form-elements/FilterHeader';
import { HoverActions } from '@/components/form-elements/HoverActions';
import { RadioButtonsWsaType } from '@/components/form-elements/RadioButtonsWsaType';
import { IndicatorProcessStatusIcon } from '@/components/icons/IndicatorProcessStatusIcon';
import { StatusChip } from '@/components/icons/StatusChip';
import { FullHeightLayout } from '@/components/layouts/FullHeightLayout';
import { useAppAbility } from '@/hooks/useAppAbility';
import { QueryKeys } from '@/services/QueryKeys';
import { Action, IndicatorProcessService, IndicatorProcessStatus, Subject } from '@/services/api';

type FiltersFormData = { search: string; independent: boolean };
const filtersDefaultValues: FiltersFormData = { search: '', independent: false };

export function IndicatorProcessesPage() {
  const [filters, setFilters] = useState<FiltersFormData>(filtersDefaultValues);
  const { t } = useTranslation();
  const ability = useAppAbility();

  const { data: indicatorProcesses = [], isLoading } = useQuery({
    queryKey: QueryKeys.indicatorProcesses.allFilters(filters),
    queryFn: () => IndicatorProcessService.findAll(filters),
  });

  return (
    <FullHeightLayout>
      <Container>
        <FilterHeader<FiltersFormData>
          addButton={
            ability.can(Action.CREATE, Subject.INDICATOR_PROCESS) ? { label: 'Erstellen', to: 'erstellen' } : undefined
          }
          defaultValues={filtersDefaultValues}
          onChange={(formData) => setFilters(formData)}
          endChildren={
            <Button variant="contained" size="large" color="secondary" component={Link} to="konfigurieren">
              Berechnungen
            </Button>
          }
        >
          <RadioButtonsWsaType label="WSA Typauswahl" name="independent" isLoading={false} />
        </FilterHeader>

        <TableContainer>
          <Table aria-label="Standorte">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Arbeitgeber</TableCell>
                <TableCell>Ankunftszeit von</TableCell>
                <TableCell>Ankunftszeit bis</TableCell>
                <TableCell align="right">Relationen</TableCell>
                <TableCell align="right">Geocodiert</TableCell>
                <TableCell align="right">Berechnet</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {indicatorProcesses.map(
                ({
                  name,
                  id,
                  organization,
                  arrivalSpanFrom,
                  arrivalSpanTo,
                  relationsTotalCount,
                  relationsGeocodingDoneCount,
                  relationsIndicatorsDoneCount,
                  status,
                }) => (
                  <TableRow key={id}>
                    <TableCell component="th" scope="row">
                      <HoverActions
                        title={name}
                        actions={[
                          { label: 'Bearbeiten', onClick: `/beratung/wsa-berechnung/${id}` },
                          ...([
                            IndicatorProcessStatus.CALCULATION_DONE,
                            IndicatorProcessStatus.INDICATORS_APPROVED,
                          ].includes(status)
                            ? [
                                {
                                  label: 'Analyse',
                                  onClick: organization
                                    ? `/arbeitgeber/${organization?.id}/wohnstandortanalyse/${id}`
                                    : `/beratung/standortunabhaengige-wsa/${id}`,
                                },
                              ]
                            : []),
                        ]}
                      />
                    </TableCell>
                    <TableCell>{organization?.name}</TableCell>
                    <TableCell>{arrivalSpanFrom && dayjs(arrivalSpanFrom).format('L LT')}</TableCell>
                    <TableCell>{arrivalSpanTo && dayjs(arrivalSpanTo).format('L LT')}</TableCell>
                    <TableCell align="right">{relationsTotalCount}</TableCell>
                    <TableCell align="right">{relationsGeocodingDoneCount}</TableCell>
                    <TableCell align="right">{relationsIndicatorsDoneCount}</TableCell>
                    <TableCell>
                      <StatusChip
                        icon={<IndicatorProcessStatusIcon status={status} />}
                        label={t(`IndicatorProcessStatus.${status}`)}
                      />
                    </TableCell>
                  </TableRow>
                ),
              )}

              {!isLoading && indicatorProcesses.length === 0 && (
                <TableRow>
                  <TableCell align="center" colSpan={8}>
                    Keine WSA Berechnungen gefunden
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </FullHeightLayout>
  );
}
