import { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';

import { Authenticated } from '@/authentication/Authenticated';
import { Navigation } from '@/components/navigations/Navigation';
import { UserRole } from '@/services/api';

type ProtectedLayoutProps = {
  navigation?: ReactNode;
  allowedRoles: UserRole[];
};

export function ProtectedLayout({ navigation, allowedRoles }: ProtectedLayoutProps) {
  return (
    <Authenticated allowedRoles={allowedRoles}>
      <Navigation>{navigation}</Navigation>
      <Outlet />
    </Authenticated>
  );
}
