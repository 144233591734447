import {
  Autocomplete,
  Box,
  Container,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { Navigate, useMatch } from 'react-router-dom';

import { CheckboxesGroup } from '@/components/form-elements/CheckboxesGroup';
import { ConsultingStatusMutate } from '@/components/form-elements/ConsultingStatusMutate';
import { FilterHeader } from '@/components/form-elements/FilterHeader';
import { HoverActions } from '@/components/form-elements/HoverActions';
import { TextFieldLoading } from '@/components/form-elements/TextFieldLoading';
import { FullHeightLayout } from '@/components/layouts/FullHeightLayout';
import { useAppAbility } from '@/hooks/useAppAbility';
import { QueryKeys } from '@/services/QueryKeys';
import {
  Action,
  AdminLevelsService,
  AdminLevelType,
  ConsultingStatus,
  NetworkCategory,
  NetworksService,
  OrganizationsService,
  Subject,
} from '@/services/api';

const employeeRangeMax = 1000;

type FiltersFormData = {
  search: string;
  status: ConsultingStatus[];
  adminLevelId: string | undefined;
  networkId: string | undefined;
  employeeRange: number[];
};
const filtersDefaultValues: FiltersFormData = {
  search: '',
  status: [],
  adminLevelId: null as unknown as undefined,
  networkId: null as unknown as undefined,
  employeeRange: [0, employeeRangeMax],
};

export function OrganizationsPage() {
  const [filters, setFilters] = useState<FiltersFormData>(filtersDefaultValues);
  const ability = useAppAbility();
  const match = useMatch(`/arbeitgeber`);

  const { data = [], isLoading } = useQuery({
    queryKey: QueryKeys.organizations.allFilters({ ...filters, includeLocations: true }),

    queryFn: () => {
      if (filters.employeeRange[1] === employeeRangeMax)
        return OrganizationsService.findAll({
          ...(filters.adminLevelId && { adminLevelId: filters.adminLevelId }),
          ...(filters.networkId && { networkId: filters.networkId }),
          ...(filters.search && { search: filters.search }),
          ...(filters.status && { status: filters.status }),
          ...(filters.employeeRange[0] !== 0 && { employeeRange: [filters.employeeRange[0], 99999999] }),
          includeLocations: true,
        });

      return OrganizationsService.findAll({ ...filters, includeLocations: true });
    },
    placeholderData: keepPreviousData,
  });

  const adminLevelFilter = {
    type: [AdminLevelType.MUNICIPALITY, AdminLevelType.CITY, AdminLevelType.MUNICIPALITY_FREE_AREA],
    depth: 0,
  };
  const { data: adminLevelData = [] } = useQuery({
    queryKey: QueryKeys.adminLevels.allFilter(adminLevelFilter),

    queryFn: () => AdminLevelsService.findAll(adminLevelFilter),
  });

  const { data: networkData = [] } = useQuery({
    queryKey: QueryKeys.networks.all,

    queryFn: async () => {
      const allNetworks = await NetworksService.findAll();

      return allNetworks.filter((network) => network.category === NetworkCategory.IHK_DISTRICT);
    },
  });

  const employeeRangeText = (value: number[]) => {
    if (value[0] === 0 && value[1] === employeeRangeMax) return `Unbegrenzt`;
    if (value[1] === employeeRangeMax) return `${value[0]} bis unbegrenzt`;

    return `${value[0]} bis ${value[1]}`;
  };

  return match && ability.can(Action.MANAGE, Subject.ORGANIZATION) ? (
    <Navigate to="/beratung/arbeitgeber" replace />
  ) : (
    <FullHeightLayout>
      <Container>
        <FilterHeader<FiltersFormData>
          addButton={
            ability.can(Action.CREATE, Subject.ORGANIZATION) ? { label: 'Erstellen', to: 'erstellen' } : undefined
          }
          defaultValues={filtersDefaultValues}
          onChange={(formData) => setFilters(formData)}
        >
          <CheckboxesGroup
            label="Beratungsprozess Status"
            name="status"
            i18nKey="ConsultingStatus"
            options={Object.values(ConsultingStatus)}
            isLoading={false}
          />
          <Typography sx={{ marginLeft: '16px' }}>
            <b>Räumliche Ebene und Kategorie</b>
          </Typography>
          <TextFieldLoading isLoading={isLoading || !data}>
            <Controller
              name="adminLevelId"
              defaultValue={filtersDefaultValues.adminLevelId}
              render={({ field }) => (
                <Autocomplete
                  sx={{ width: '400px', marginBottom: '10px' }}
                  {...field}
                  options={adminLevelData}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  )}
                  getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                      return adminLevelData.find(({ id }) => id === option)?.name || '';
                    }

                    return option.name;
                  }}
                  isOptionEqualToValue={(option, value) => option.id === value}
                  onChange={(event, value) => {
                    field.onChange(value?.id || null);
                  }}
                  renderInput={(params) => <TextField {...params} label="Stadt/Ort Hauptstandort" />}
                />
              )}
            />
          </TextFieldLoading>
          <TextFieldLoading isLoading={isLoading}>
            <Controller
              name="networkId"
              defaultValue={null as unknown as undefined}
              render={({ field }) => (
                <Autocomplete
                  sx={{ width: '400px', marginBottom: '10px' }}
                  {...field}
                  options={networkData}
                  getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                      return networkData.find(({ id }) => id === option)?.name || '';
                    }

                    return option.name;
                  }}
                  isOptionEqualToValue={(option, value) => option.id === value}
                  onChange={(event, value) => {
                    field.onChange(value?.id || null);
                  }}
                  renderInput={(params) => <TextField {...params} label="IHK Bezirk" />}
                />
              )}
            />
          </TextFieldLoading>
          <Box sx={{ paddingLeft: '15px', paddingRight: '15px' }}>
            <Typography>
              <b>Anzahl der Beschäftigten</b> {employeeRangeText(filters.employeeRange)}
            </Typography>
            <Controller
              name="employeeRange"
              defaultValue={filtersDefaultValues.employeeRange}
              render={({ field }) => (
                <Slider
                  {...field}
                  onChange={(_, value) => {
                    field.onChange(value);
                  }}
                  valueLabelDisplay="auto"
                  min={0}
                  step={50}
                  max={employeeRangeMax}
                />
              )}
            />
          </Box>
        </FilterHeader>

        <TableContainer>
          <Table aria-label="Standorte">
            <TableHead>
              <TableRow>
                <TableCell>Arbeitgeber</TableCell>
                <TableCell>Beratungsprozess Status</TableCell>
                <TableCell>Stadt/Ort Hauptstandort</TableCell>
                <TableCell>IHK-Bezirk Hauptstandort</TableCell>
                <TableCell>Anzahl Beschäftigte</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((organization) => {
                const { id, name, size, locations } = organization;
                const mainLocation = locations?.find((location) => location.mainLocation);

                return (
                  <TableRow key={id}>
                    <TableCell component="th" scope="row">
                      <HoverActions title={name} actions={[{ label: 'Bearbeiten', onClick: `/arbeitgeber/${id}` }]} />
                    </TableCell>
                    <TableCell>
                      <ConsultingStatusMutate organization={organization} />
                    </TableCell>
                    <TableCell>{mainLocation?.adminLevel?.name}</TableCell>
                    <TableCell>
                      {mainLocation?.networks?.find(({ category }) => category === NetworkCategory.IHK_DISTRICT)?.name}
                    </TableCell>
                    <TableCell align="right">{size}</TableCell>
                  </TableRow>
                );
              })}

              {!isLoading && data.length === 0 && (
                <TableRow>
                  <TableCell align="center" colSpan={5}>
                    Keine Arbeitgeber gefunden
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </FullHeightLayout>
  );
}
