import { FormProvider } from 'react-hook-form';

import { FormSection } from '@/components/form-elements/FormSection';
import { RadioButtonsEnum } from '@/components/form-elements/RadioButtonsEnum';
import { TextFieldNumber } from '@/components/form-elements/TextFieldNumber';
import { useInitialFormValues } from '@/hooks/useInitialFormValues';
import { useLocationProfileForm } from '@/hooks/useLocationProfileForm';
import { AgeDistribution } from '@/location/location-profile/fields/AgeDistribution';
import { GenderDistribution } from '@/location/location-profile/fields/GenderDistribution';
import { WorkingModel } from '@/location/location-profile/fields/WorkingModel';
import { BuildingType, UpdateLocationProfileDto } from '@/services/api';

export type FormDataGeneralInformation = Pick<
  UpdateLocationProfileDto,
  'buildingType' | 'genderDistribution' | 'ageDistribution' | 'customersPerWeek' | 'workingModel' | 'possibleHomeOffice'
>;

type GeneralInformationFormProps = { print?: boolean };

export function GeneralInformationForm({ print }: GeneralInformationFormProps) {
  const { locationProfile, isLoading, disabled, handleSubmit, methods } = useLocationProfileForm();

  useInitialFormValues<FormDataGeneralInformation>({
    entity: locationProfile,
    useFormReturn: methods,
    fields: [
      'buildingType',
      'genderDistribution',
      'ageDistribution',
      'customersPerWeek',
      'workingModel',
      'possibleHomeOffice',
    ],
  });

  return (
    <form id="main-form" onSubmit={handleSubmit}>
      <FormProvider {...methods}>
        <FormSection large title="Allgemeine Angabe" print={print} />

        <RadioButtonsEnum<BuildingType, FormDataGeneralInformation>
          label="Gebäudetyp"
          name="buildingType"
          i18nKey="BuildingType"
          options={Object.values(BuildingType)}
          isLoading={isLoading}
          disabled={disabled}
        />

        <GenderDistribution isLoading={isLoading} disabled={disabled} />

        <AgeDistribution isLoading={isLoading} disabled={disabled} />

        <FormSection title="Kunden/Besucher je Woche">
          <TextFieldNumber
            label="Anzahl"
            isLoading={isLoading}
            name="customersPerWeek"
            rules={{ min: 0 }}
            disabled={disabled}
          />
        </FormSection>

        <WorkingModel isLoading={isLoading} disabled={disabled} />

        <FormSection title="Beschäftigte, die (zumindest teilweise) von Zuhause arbeiten können">
          <TextFieldNumber
            label="Anzahl"
            isLoading={isLoading}
            name="possibleHomeOffice"
            rules={{ min: 0 }}
            disabled={disabled}
          />
        </FormSection>
      </FormProvider>
    </form>
  );
}
