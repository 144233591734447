export const getFilterCount = (filter: object, defaultFilter: object) => {
  const filterArray = filter ? Object.entries(filter) : [];
  const defaultArray = defaultFilter ? Object.entries(defaultFilter) : [];
  let count = 0;
  filterArray.forEach(([key, value]) => {
    const defaultValue = defaultArray.find((pair) => pair[0] === key);
    if (defaultValue === undefined) count += 1;
    else if (Array.isArray(value)) {
      const diff = value.filter((x) => !defaultValue[1].includes(x)).length;
      count += diff;
      if (diff === 0 && JSON.stringify(value) !== JSON.stringify(defaultValue[1])) count += 1;
    } else if (JSON.stringify(value) !== JSON.stringify(defaultValue[1])) count += 1;
  });

  return count;
};
