import { useParams } from 'react-router-dom';

import { SecondaryNavigation } from '@/components/navigations/SecondaryNavigation';
import { useQueryLocation } from '@/hooks/queries/useQueryLocation';
import { useAppAbility } from '@/hooks/useAppAbility';
import { Action } from '@/services/api';

export function LocationNavigation() {
  const { organizationId, locationId } = useParams();
  const { data: location } = useQueryLocation();
  const ability = useAppAbility();

  return (
    <SecondaryNavigation
      items={
        locationId && locationId !== 'erstellen'
          ? [
              {
                label: 'Standort-Information',
                to: `/arbeitgeber/${organizationId}/standorte/${locationId}`,
                end: true,
              },
              { label: 'Standort-Steckbrief', to: `/arbeitgeber/${organizationId}/standorte/${locationId}/steckbrief` },
              ...(location && ability.can(Action.UPDATE, location, 'monitoringActive')
                ? [
                    {
                      label: 'BESSER Monitor',
                      to: `/arbeitgeber/${organizationId}/standorte/${locationId}/monitoring-einstellung`,
                    },
                  ]
                : []),
            ]
          : []
      }
    />
  );
}
