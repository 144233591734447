import { useEffect, useState } from 'react';
import { DeepPartial, DefaultValues, FieldPath, FieldValues, Path, UseFormReturn } from 'react-hook-form';

import { DataTransformer } from '@/services/DataTransformer';

type UseInitialFormValuesParams<T extends FieldValues = FieldValues> = {
  entity: (T & { updatedAt: string }) | undefined;
  useFormReturn: UseFormReturn<T>;
  fields: FieldPath<T>[];
  once?: boolean;
};

export const useInitialFormValues = <T extends FieldValues = FieldValues>({
  entity,
  useFormReturn: { getValues, formState, reset },
  fields,
  once = false,
}: UseInitialFormValuesParams<T>) => {
  const [finished, setFinished] = useState(false);
  const [updatedAt, setUpdatedAt] = useState<string>();

  useEffect(() => {
    if (entity && (!once || !finished) && entity.updatedAt !== updatedAt) {
      const transformedEntity = DataTransformer.toForm(entity);
      const defaultValues = fields.reduce(
        (previousValue, field) => ({
          ...previousValue,
          [field]: (transformedEntity as unknown as DeepPartial<T>)[field],
        }),
        {} as DefaultValues<T>,
      );

      if (
        !updatedAt ||
        !formState.isDirty ||
        // Check if dirty fields are the same as the default values
        !Object.entries(defaultValues).some(([key, value]) => {
          const formValue = getValues(key as Path<T>);

          if (typeof formValue === 'object' || typeof value === 'object') {
            return JSON.stringify(formValue) !== JSON.stringify(value);
          }

          return formValue !== value;
        })
      ) {
        reset(defaultValues);
      }

      setFinished(true);
      setUpdatedAt(entity.updatedAt);
    }
  }, [entity, fields, finished, formState.isDirty, getValues, once, reset, updatedAt]);
};
