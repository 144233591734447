import { Chip, css, styled } from '@mui/material';
import { ReactElement } from 'react';

const StyledChip = styled(Chip)(
  ({ theme }) => css`
    span[class=''] {
      display: flex;
    }

    svg {
      margin-left: ${theme.spacing(0.5)};
    }
  `,
);

type StatusChipProps = { icon?: ReactElement; label: string };

export function StatusChip({ icon, label }: StatusChipProps) {
  return <StyledChip icon={icon} label={label} />;
}
