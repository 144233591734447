import { TextField } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { DeleteDialog } from '@/components/form-elements/DeleteDialog';
import { FormSection } from '@/components/form-elements/FormSection';
import { TextFieldLoading } from '@/components/form-elements/TextFieldLoading';
import { useAppAbility } from '@/hooks/useAppAbility';
import { useInitialFormValues } from '@/hooks/useInitialFormValues';
import { QueryKeys } from '@/services/QueryKeys';
import { Action, IndicatorProcess, IndicatorProcessService, UpdateIndicatorProcessDto } from '@/services/api';
import { queryClient } from '@/setup/query-client';

type FormData = {
  name: string;
};

export function CoreDataForm() {
  const { indicatorProcessId } = useParams();
  const navigate = useNavigate();
  const ability = useAppAbility();

  const methods = useForm<FormData>();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = methods;

  const { data: indicatorProcess } = useQuery({
    queryKey: QueryKeys.indicatorProcesses.id(indicatorProcessId as string),
    queryFn: () => IndicatorProcessService.findOne({ id: indicatorProcessId as string }),
    enabled: !!indicatorProcessId,
  });

  useInitialFormValues<Omit<FormData, 'indicatorProcess'>>({
    entity: indicatorProcess && {
      ...indicatorProcess,
    },
    useFormReturn: methods,
    fields: ['name'],
  });

  const { mutate } = useMutation({
    mutationFn: (requestBody: UpdateIndicatorProcessDto) =>
      IndicatorProcessService.update({
        id: indicatorProcessId as string,
        requestBody,
      }),
  });
  const { mutate: mutateRemove } = useMutation({
    mutationFn: () => IndicatorProcessService.remove({ id: indicatorProcessId as string }),

    onSuccess: async () => {
      navigate('/beratung/wsa-berechnung');
      await queryClient.invalidateQueries({ queryKey: QueryKeys.indicatorProcesses.all });
    },
  });

  const isLoading = !indicatorProcess;

  return (
    <form id="main-form" onSubmit={handleSubmit((data) => mutate(data))}>
      <FormSection title="WSA Berechnung">
        <TextFieldLoading isLoading={isLoading}>
          <TextField label="Name" {...register('name', { required: true })} error={!!errors.name} />
        </TextFieldLoading>

        <TextFieldLoading isLoading={isLoading}>
          <TextField label="Arbeitgeber" value={indicatorProcess?.organization?.name} disabled />
        </TextFieldLoading>
      </FormSection>

      <DeleteDialog
        onDelete={mutateRemove}
        entityLabel="WSA Berechnung"
        entityName={indicatorProcess?.name as string}
        can={ability.can(Action.DELETE, indicatorProcess as IndicatorProcess)}
      >
        Durch das Löschen der WSA Berechnung werden auch alle damit verknüpften Daten (z.B. Relationen) gelöscht.
      </DeleteDialog>
    </form>
  );
}
