import { Box, Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CommuteUtils } from '@/monitoring/utils/CommuteUtils';
import { CommuteType } from '@/services/api';

type CommuteBadgeProps = {
  commuteType: CommuteType;
  count: number;
  size: number;
};

export function CommuteBadge({ commuteType, count, size }: CommuteBadgeProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { Icon } = CommuteUtils[commuteType];

  return (
    <Grid item xs={size} key={commuteType}>
      <Box sx={{ backgroundColor: 'white', borderRadius: '10px', padding: 2, height: '100%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box sx={{ paddingRight: 2 }}>
            <Icon width={48} height={48} style={{ color: theme.palette.primary.main }} />
          </Box>
          <Typography
            sx={{
              color: size > 6 ? 'primary.main' : 'black',
              fontSize: 40,
              fontWeight: 'bold',
              paddingLeft: 2,
            }}
          >
            {count}
          </Typography>
        </Box>

        <Typography sx={{ display: 'inline-block', marginTop: 2 }}>
          {t(`CommuteBadgeLabel${count > 1 ? 'Plural' : 'Singular'}.${commuteType}`)}
        </Typography>
      </Box>
    </Grid>
  );
}
