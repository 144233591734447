import { Accordion, AccordionDetails, AccordionSummary, css, Grid, styled, Typography } from '@mui/material';
import { ReactNode, useState } from 'react';

import Close from '@/assets/icons/tree-map/close.svg?react';
import Open from '@/assets/icons/tree-map/open.svg?react';
import { HoverActions, HoverActionsProps } from '@/components/form-elements/HoverActions';

const StyledAccordion = styled(Accordion)(
  ({ theme }) => css`
    &::before {
      content: none;
    }

    &:not(:last-child) {
      margin-bottom: ${theme.spacing(1)};
    }
  `,
);

const StyledAccordionSummary = styled(AccordionSummary)(
  ({ theme }) => css`
    background-color: ${theme.palette.divider};
    height: ${theme.spacing(7)};
    max-width: min(50vw, ${theme.spacing(70)});

    &:not(:last-child) {
      margin-bottom: ${theme.spacing(1)};
    }
  `,
);

const StyledTypography = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.palette.text.disabled};
  `,
);

const StyledAccordionDetails = styled(AccordionDetails)(
  ({ theme }) => css`
    padding: 0 0 0 ${theme.spacing(12)};
  `,
);

type NodeAccordionProps = { children: ReactNode; type: string } & HoverActionsProps;

export function NodeAccordion({ children, type, title, actions }: NodeAccordionProps) {
  const [expanded, setExpanded] = useState(false);

  return (
    <StyledAccordion
      TransitionProps={{ unmountOnExit: true }}
      expanded={expanded}
      onChange={() => setExpanded((prevState) => !prevState)}
      disableGutters
    >
      <StyledAccordionSummary className="hover-actions-trigger">
        <Grid container>
          <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
            {expanded ? <Close /> : <Open />}
          </Grid>
          <Grid item marginLeft={2}>
            {actions.length > 0 ? <HoverActions title={title} actions={actions} /> : <Typography>{title}</Typography>}
          </Grid>

          <Grid item marginLeft="auto">
            <StyledTypography>{type}</StyledTypography>
          </Grid>
        </Grid>
      </StyledAccordionSummary>
      <StyledAccordionDetails>{children}</StyledAccordionDetails>
    </StyledAccordion>
  );
}
