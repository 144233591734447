import { TextField, Tooltip, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { forwardRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormSection } from '@/components/form-elements/FormSection';
import { TextFieldLoading } from '@/components/form-elements/TextFieldLoading';
import { TextFieldNumber } from '@/components/form-elements/TextFieldNumber';
import { TextFieldText } from '@/components/form-elements/TextFieldText';
import { useIsFieldDisabled } from '@/hooks/useIsFieldDisabled';
import { Organization, Sector, UpdateOrganizationDto } from '@/services/api';
import { validateAsOptionalGtOne } from '@/utils/validateAsOptionalGtOne';

export type FormPartOrganizationCoreData = Pick<
  UpdateOrganizationDto,
  'name' | 'participationDate' | 'consultingCaseId' | 'size' | 'sector' | 'fileStorage'
>;

type CoreDataFormProps = {
  organization?: Organization;
  isLoading: boolean;
};

export const CoreDataForm = forwardRef<HTMLElement, CoreDataFormProps>(
  ({ organization, isLoading }: CoreDataFormProps, ref) => {
    const {
      register,
      formState: { errors },
      watch,
    } = useFormContext<FormPartOrganizationCoreData>();
    const participationDateValue = watch('participationDate');

    const { t } = useTranslation();
    const isFieldDisabled = useIsFieldDisabled(organization);

    return (
      <FormSection ref={ref} title="Stammdaten">
        <TextFieldText
          label="Arbeitgebername"
          isLoading={isLoading}
          name="name"
          required
          disabled={isFieldDisabled('name')}
        />

        <TextFieldLoading isLoading={isLoading}>
          <Controller
            name="participationDate"
            render={({ field: { ref: participationDateRef, value, onChange, ...rest } }) => (
              <DatePicker
                label="Teilnahmejahr"
                format="YYYY"
                views={['year']}
                disableFuture
                inputRef={participationDateRef}
                disabled={isFieldDisabled('participationDate')}
                value={value ? dayjs(value) : null}
                onChange={(newValue) => {
                  onChange(newValue ? `${newValue.format('YYYY')}-01-01` : null);
                }}
                {...rest}
              />
            )}
          />
        </TextFieldLoading>

        {!isFieldDisabled('consultingCaseId') && (
          <TextFieldLoading isLoading={isLoading}>
            <Controller<FormPartOrganizationCoreData, 'consultingCaseId'>
              name="consultingCaseId"
              defaultValue=""
              rules={{
                minLength: { value: 3, message: 'Bitte geben sie eine dreistellige Nummer ein' },
                maxLength: { value: 3, message: 'Bitte geben sie eine dreistellige Nummer ein' },
              }}
              render={({ field }) => (
                <>
                  <TextField
                    {...field}
                    onChange={(event) => {
                      field.onChange(event.currentTarget.value.replace(/\D/g, ''));
                    }}
                    label="Beratungsfall-Nummer"
                    inputProps={{ maxLength: 3 }}
                    error={!!errors.consultingCaseId}
                    helperText={errors?.consultingCaseId?.message}
                  />
                  <Tooltip
                    title="Zusammensetzung: Beratungsfallnummer (3) + Teilnahmejahr (2) + Standort-Nummerierung (2)"
                    placement="top-start"
                  >
                    <Typography sx={{ paddingLeft: '16px', paddingBottom: '10px' }}>
                      {field.value?.length === 3 &&
                        `ivm Referenznummer: ${field.value || 'XXX'}${
                          participationDateValue ? dayjs(participationDateValue).format('YY') : 'JJ'
                        }10`}
                    </Typography>
                  </Tooltip>
                </>
              )}
            />
          </TextFieldLoading>
        )}

        <TextFieldNumber
          label="Anzahl der Beschäftigten beim Arbeitgeber"
          isLoading={isLoading}
          name="size"
          rules={{ min: 1, validate: validateAsOptionalGtOne }}
          disabled={isFieldDisabled('size')}
        />

        <TextFieldLoading isLoading={isLoading}>
          <TextField label="Branche" select {...register('sector')} disabled={isFieldDisabled('sector')}>
            {Object.values(Sector).map((sector) => (
              <option key={sector} value={sector}>
                {t(`Sector.${sector}`)}
              </option>
            ))}
          </TextField>
        </TextFieldLoading>

        <TextFieldText
          label="Link zur Dokumentenablage"
          isLoading={isLoading}
          name="fileStorage"
          disabled={isFieldDisabled('fileStorage')}
        />
      </FormSection>
    );
  },
);
