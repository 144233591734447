import { Box, FormControlLabel, Input, Radio } from '@mui/material';
import { FieldPath, FieldValues, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { RadioButtonsGroup } from '@/components/form-elements/RadioButtonsGroup';

type RadioButtonsEnumWithDescProps<T extends string, U extends FieldValues> = {
  label: string;
  name: FieldPath<U>;
  descName?: FieldPath<U>;
  i18nKey: string;
  options: T[];
  isLoading: boolean;
  disabled?: boolean;
};

export function RadioButtonsEnumWithDesc<T extends string, U extends FieldValues>({
  label,
  name,
  descName,
  i18nKey,
  options,
  isLoading,
  disabled,
}: RadioButtonsEnumWithDescProps<T, U>) {
  const { t } = useTranslation();
  const { register } = useFormContext<U>();

  return (
    <RadioButtonsGroup label={label} name={name} isLoading={isLoading} disabled={disabled}>
      {options.map((value) => (
        <FormControlLabel
          disableTypography
          key={value}
          value={value as T}
          control={<Radio />}
          label={<>{t(`${i18nKey}.${value}`)}</>}
        />
      ))}
      {descName && (
        <Box component="label" display="flex" sx={{ alignItems: 'center', flexGrow: 1 }}>
          Und zwar:
          <Box sx={{ display: 'inline-flex', flexGrow: 1 }}>
            <Input sx={{ marginLeft: 1, width: '100%' }} {...register(descName)} />
          </Box>
        </Box>
      )}
    </RadioButtonsGroup>
  );
}
