import bikeRaw from '@/assets/icons/commute/bike.svg?raw';
import Bike from '@/assets/icons/commute/bike.svg?react';
import brRaw from '@/assets/icons/commute/br.svg?raw';
import Br from '@/assets/icons/commute/br.svg?react';
import carRaw from '@/assets/icons/commute/car.svg?raw';
import Car from '@/assets/icons/commute/car.svg?react';
import carpoolRaw from '@/assets/icons/commute/carpool.svg?raw';
import Carpool from '@/assets/icons/commute/carpool.svg?react';
import ecarRaw from '@/assets/icons/commute/ecar.svg?raw';
import Ecar from '@/assets/icons/commute/ecar.svg?react';
import planeRaw from '@/assets/icons/commute/flugverkehr.svg?raw';
import Plane from '@/assets/icons/commute/flugverkehr.svg?react';
import homeofficeRaw from '@/assets/icons/commute/homeoffice.svg?raw';
import Homeoffice from '@/assets/icons/commute/homeoffice.svg?react';
import mopedRaw from '@/assets/icons/commute/moped.svg?raw';
import Moped from '@/assets/icons/commute/moped.svg?react';
import prRaw from '@/assets/icons/commute/pr.svg?raw';
import Pr from '@/assets/icons/commute/pr.svg?react';
import ptRaw from '@/assets/icons/commute/pt.svg?raw';
import Pt from '@/assets/icons/commute/pt.svg?react';
import scooterRaw from '@/assets/icons/commute/tretroller.svg?raw';
import Scooter from '@/assets/icons/commute/tretroller.svg?react';
import walkRaw from '@/assets/icons/commute/walk.svg?raw';
import Walk from '@/assets/icons/commute/walk.svg?react';
import { CommuteType } from '@/services/api';

export const CommuteUtils = {
  [CommuteType.HOME_OFFICE]: {
    color: 'rgba(127,127,127,1.0)',
    raw: homeofficeRaw,
    Icon: Homeoffice,
  },
  [CommuteType.WALK]: {
    color: 'rgba(42,96,65,1.0)',
    raw: walkRaw,
    Icon: Walk,
  },
  [CommuteType.SCOOTER]: {
    color: 'rgba(44,139,93,1.0)',
    raw: scooterRaw,
    Icon: Scooter,
  },
  [CommuteType.BIKE]: {
    color: 'rgba(48,189,104,1.0)',
    raw: bikeRaw,
    Icon: Bike,
  },
  [CommuteType.BIKE_AND_RIDE]: {
    color: 'rgba(118,207,162,1.0)',
    raw: brRaw,
    Icon: Br,
  },
  [CommuteType.PUBLIC_TRANSPORT]: {
    color: 'rgba(119,192,211,1.0)',
    raw: ptRaw,
    Icon: Pt,
  },
  [CommuteType.PARK_AND_RIDE]: {
    color: 'rgba(251,215,112,1.0)',
    raw: prRaw,
    Icon: Pr,
  },
  [CommuteType.CAR_ELECTRIC]: {
    color: 'rgba(219,158,3,1.0)',
    raw: ecarRaw,
    Icon: Ecar,
  },
  [CommuteType.CAR_POOL]: {
    color: 'rgba(190,128,121,1.0)',
    raw: carpoolRaw,
    Icon: Carpool,
  },
  [CommuteType.MOPED]: {
    color: 'rgba(219,124,2,1.0)',
    raw: mopedRaw,
    Icon: Moped,
  },
  [CommuteType.CAR_MOTORCYCLE]: {
    color: 'rgba(192,80,77,1.0)',
    raw: carRaw,
    Icon: Car,
  },
  [CommuteType.LONG_DISTANCE_TRANSPORT]: {
    color: 'rgba(179, 25, 25, 1)',
    raw: planeRaw,
    Icon: Plane,
  },
};
