import { FieldPath, FieldValues } from 'react-hook-form';

import { RadioButtonsEnum } from '@/components/form-elements/RadioButtonsEnum';
import { YesNoPartly } from '@/services/api';

type RadioButtonYesNoPartlyProps<U extends FieldValues> = {
  label: string;
  name: FieldPath<U>;
  isLoading: boolean;
  disabled?: boolean;
};

export function RadioButtonYesNoPartly<U extends FieldValues>({
  label,
  name,
  isLoading,
  disabled,
}: RadioButtonYesNoPartlyProps<U>) {
  return (
    <RadioButtonsEnum<YesNoPartly, U>
      label={label}
      name={name}
      i18nKey="YesNoPartly"
      options={Object.values(YesNoPartly)}
      isLoading={isLoading}
      disabled={disabled}
    />
  );
}
