import { Button, css, Grid, InputAdornment, styled, TextField } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { DefaultValues, FieldValues, FormProvider, Path, PathValue, useForm } from 'react-hook-form';
import { Link, useSearchParams } from 'react-router-dom';
import { useDebounce } from 'react-use';

import Search from '@/assets/icons/form/search.svg?react';
import Plus from '@/assets/icons/plus-circle.svg?react';
import { FilterButton } from '@/components/form-elements/FilterButton';
import { getFilterCount } from '@/utils/getFilterCount';

const StyledGrid = styled(Grid)(
  ({ theme }) => css`
    .MuiButton-root {
      height: ${theme.spacing(8)};
    }
  `,
);

const StyledTextField = styled(TextField)(
  ({ theme }) => css`
    height: ${theme.spacing(8)};
    min-width: ${theme.spacing(40)};

    .MuiFilledInput-root {
      background-color: ${theme.palette.secondary.main};
      height: 100%;
      padding-left: ${theme.spacing(3)};
    }

    .MuiFilledInput-input {
      height: 100%;
      padding-top: 0;
      padding-bottom: 0;

      &::placeholder,
      &::-webkit-input-placeholder,
      &::-moz-placeholder,
      &::-ms-input-placeholder {
        font-weight: bold;
      }
    }

    .MuiInputAdornment-root {
      margin-top: 0 !important;
    }
  `,
);

type FilterHeaderProps<T extends FieldValues & { search: string }> = {
  addButton?: { label: string; to: string };
  onChange: (formData: T) => void;
  defaultValues?: DefaultValues<Omit<T, 'search'>>;
  children?: ReactNode;
  endChildren?: ReactNode;
};

export function FilterHeader<T extends FieldValues & { search: string }>({
  addButton,
  onChange,
  defaultValues,
  children,
  endChildren,
}: FilterHeaderProps<T>) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterCount, setFilterCount] = useState(0);

  const methods = useForm<T>({ defaultValues: { ...defaultValues, search: '' } as DefaultValues<T> });
  const { watch, setValue } = methods;
  const searchValue = methods.watch('search' as Path<T>);
  const employeeRange = methods.watch('employeeRange' as Path<T>);

  const handleSubmit = methods.handleSubmit((formData) => {
    const { search, ...otherFields } = formData;

    setFilterCount(getFilterCount(otherFields as object, defaultValues as object));

    setSearchParams({ ...otherFields, ...(search && { search }) });
    onChange(formData);
  });

  useDebounce(
    () => {
      handleSubmit();
    },
    300,
    [searchValue, employeeRange],
  );

  useEffect(() => {
    const subscription = watch((data, { name }) => {
      if (name !== 'search' && name !== 'employeeRange') {
        handleSubmit();
      }
    });

    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(searchParams);

    Array.from(urlSearchParams.keys())
      .filter((value, index, self) => self.indexOf(value) === index)
      .forEach((name) => {
        if (name === 'networkId' || name === 'adminLevelId') {
          setValue(
            name as Path<T>,
            (urlSearchParams.get(name) === 'null' ? null : urlSearchParams.get(name)) as PathValue<T, Path<T>>,
          );
        } else if (name === 'employeeRange') {
          setValue(
            name as Path<T>,
            urlSearchParams.getAll(name).map((item) => parseInt(item, 10)) as PathValue<T, Path<T>>,
          );
        } else {
          setValue(
            name as Path<T>,
            (name === 'search' ? urlSearchParams.get(name) : urlSearchParams.getAll(name)) as PathValue<T, Path<T>>,
          );
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormProvider {...methods}>
      <StyledGrid container mb={2} columnSpacing={2}>
        {addButton && (
          <Grid item>
            <Button startIcon={<Plus />} variant="contained" size="large" component={Link} to={addButton.to}>
              {addButton.label}
            </Button>
          </Grid>
        )}
        <Grid item>
          <StyledTextField
            variant="filled"
            placeholder="Suche"
            type="search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            {...methods.register('search' as Path<T>)}
          />
        </Grid>
        {children && (
          <Grid item>
            <FilterButton filterCount={filterCount}>{children}</FilterButton>
          </Grid>
        )}
        {endChildren && (
          <Grid item sx={{ marginLeft: 'auto' }}>
            {endChildren}
          </Grid>
        )}
      </StyledGrid>
    </FormProvider>
  );
}
