import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { QueryKeys } from '@/services/QueryKeys';
import { OrganizationsService } from '@/services/api';

export const useQueryOrganizationDefaultFilters = {
  includeLocations: true,
  includeOrganizationUsers: true,
  includeIndicatorProcesses: true,
};

export const useQueryOrganization = () => {
  const { organizationId } = useParams();

  const filter = useQueryOrganizationDefaultFilters;

  return useQuery({
    queryKey: QueryKeys.organizations.idIncludes(organizationId as string, filter),
    queryFn: () => OrganizationsService.findOne({ id: organizationId as string, ...filter }),
    enabled: !!organizationId,
  });
};
