import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { QueryKeys } from '@/services/QueryKeys';
import { MonitoringService } from '@/services/api';

export const useQueryMonitoringLocation = () => {
  const { monitoringId } = useParams();

  return useQuery({
    queryKey: QueryKeys.locations.id(monitoringId as string),
    queryFn: () => MonitoringService.findOne({ monitoringId: monitoringId as string }),
    enabled: !!monitoringId,
  });
};
