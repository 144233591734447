import { Autocomplete, Button, Checkbox, FormControlLabel, TextField } from '@mui/material';
import { keepPreviousData, useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { FormSection } from '@/components/form-elements/FormSection';
import { TextFieldLoading } from '@/components/form-elements/TextFieldLoading';
import { SidebarLayout } from '@/components/layouts/SidebarLayout';
import { QueryKeys } from '@/services/QueryKeys';
import { IndicatorProcess, IndicatorProcessService, Organization, OrganizationsService } from '@/services/api';

type FormData = { organization: Pick<Organization, 'id' | 'name'>; name: string };

export function CreateIndicatorProcessPage() {
  const navigate = useNavigate();
  const [independent, setIndependent] = useState(false);

  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<FormData>();

  const { mutate } = useMutation({
    mutationFn: (formData: FormData) =>
      IndicatorProcessService.create({
        requestBody: { organizationId: formData.organization?.id as string, name: formData.name },
      }),

    onSuccess: (indicatorProcess: IndicatorProcess) => navigate(`/beratung/wsa-berechnung/${indicatorProcess.id}`),
  });

  const filters = { includeLocations: false };
  const { data = [] } = useQuery({
    queryKey: QueryKeys.organizations.allFilters(filters),
    queryFn: () => OrganizationsService.findAll(filters),
    placeholderData: keepPreviousData,
  });

  const handleIndependentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) reset({ organization: { id: undefined, name: '' } });
    setIndependent(event.target.checked);
  };

  return (
    <SidebarLayout>
      <form id="main-form" onSubmit={handleSubmit((submitData) => mutate(submitData))}>
        <FormSection title="WSA Berechnung erstellen">
          <TextFieldLoading isLoading={!data}>
            <TextField label="Name" {...register('name', { required: true })} error={!!errors.name} />
          </TextFieldLoading>
          <FormControlLabel
            label="ohne Arbeitgeberbezug"
            control={<Checkbox checked={independent} onChange={handleIndependentChange} />}
          />
          <TextFieldLoading isLoading={!data}>
            <Controller
              name="organization"
              control={control}
              defaultValue={null as unknown as undefined}
              rules={{ required: false }}
              render={({ field: { ref, onChange, ...rest }, fieldState: { error } }) => (
                <Autocomplete
                  {...rest}
                  disableClearable
                  options={data}
                  disabled={independent}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  onChange={(event, value) => {
                    onChange(value);
                  }}
                  renderInput={(params) => <TextField {...params} inputRef={ref} label="Arbeitgeber" error={!!error} />}
                />
              )}
            />
          </TextFieldLoading>
        </FormSection>

        <Button type="submit" variant="contained" size="large">
          Erstellen
        </Button>
      </form>
    </SidebarLayout>
  );
}
