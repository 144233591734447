import { Button, TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { FormSection } from '@/components/form-elements/FormSection';
import { SidebarLayout } from '@/components/layouts/SidebarLayout';
import { OrganizationsService } from '@/services/api';

type FormData = { name: string };

export function CreateOrganizationPage() {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const { mutate } = useMutation({
    mutationFn: (formData: FormData) => OrganizationsService.create({ requestBody: formData }),
    onSuccess: (organization) => navigate(`/arbeitgeber/${organization.id}/stammdaten`),
  });

  return (
    <SidebarLayout>
      <form
        id="main-form"
        onSubmit={handleSubmit((data) => {
          mutate(data);
        })}
      >
        <FormSection title="Arbeitgeber erstellen">
          <TextField label="Arbeitgebername" {...register('name', { required: true })} error={!!errors.name} />
        </FormSection>

        <Button type="submit" variant="contained" size="large">
          Erstellen
        </Button>
      </form>
    </SidebarLayout>
  );
}
