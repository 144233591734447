import { Skeleton } from '@mui/material';
import { ReactNode } from 'react';

type TextFieldLoadingProps = { isLoading: boolean; children: ReactNode };

export function TextFieldLoading({ isLoading, children }: TextFieldLoadingProps) {
  return isLoading ? (
    <Skeleton className="form-section-item" variant="rectangular" width="100%" height={56} />
  ) : (
    (children as JSX.Element)
  );
}
